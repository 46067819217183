"use strict";
// source: users_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
var diamonds_entity_pb = require('./diamonds_entity_pb.js');
goog.object.extend(proto, diamonds_entity_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var karaoke_entity_pb = require('./karaoke_entity_pb.js');
goog.object.extend(proto, karaoke_entity_pb);
var leagues_entity_pb = require('./leagues_entity_pb.js');
goog.object.extend(proto, leagues_entity_pb);
var lives_entity_pb = require('./lives_entity_pb.js');
goog.object.extend(proto, lives_entity_pb);
var page_entity_pb = require('./page_entity_pb.js');
goog.object.extend(proto, page_entity_pb);
var pearls_entity_pb = require('./pearls_entity_pb.js');
goog.object.extend(proto, pearls_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
var validate_validate_pb = require('./validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.jp.singcolor.AddTwitterAccountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.AddTwitterAccountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.AppleLoginV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.AppleLoginV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CancelWithdrawV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CancelWithdrawV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLoginTokenV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLoginTokenV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateUserV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateUserV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteTwitterAccountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DoBlockByModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DoBlockByModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DoBlockRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DoBlockResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.DoneRegistrationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DoneRegistrationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.FollowRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.FollowResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetCoinBalanceV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetCoinBalanceV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLineLoginStateV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLineLoginStateV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetListenerMembershipInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetListenerMembershipInfoV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLivePermissionReviewDialogV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLivePermissionReviewDialogV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiverMembershipInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiverMembershipInfoV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiverPromotionMeterV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiverPromotionMeterV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLoginBonusV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLoginBonusV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetMonthlyStatisticsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetMonthlyStatisticsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetPearlPrizeItemDreamV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetPearlPrizeItemDreamV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTweetTimelineV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTweetTimelineV1Request.Filter', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTweetTimelineV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTwitterSettingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTwitterSettingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUnreadNotificationInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUnreadNotificationInfoV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserBanV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserBanV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserProfileV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserProfileV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserProfileV2Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserProfileV2Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserSettingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserSettingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetWithdrawV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetWithdrawV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GoogleLoginV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GoogleLoginV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.LineLoginByCodeV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.LineLoginByCodeV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.LineLoginV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.LineLoginV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListAvailableAppFeaturesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListAvailableAppFeaturesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListAvailableAppFeaturesV1Response.Feature', null, global);
goog.exportSymbol('proto.jp.singcolor.ListAvailableWebFeaturesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListAvailableWebFeaturesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListAvailableWebFeaturesV1Response.Feature', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBlockingsRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBlockingsResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFollowersRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFollowersResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFollowingsRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFollowingsResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverRankingTermTypesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverRankingTermTypesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverRankingV2Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverRankingV2Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiverRankingV2Response.EventStatus', null, global);
goog.exportSymbol('proto.jp.singcolor.ListModeratorsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListModeratorsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPearlCupPrizeItemsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPearlCupPrizeItemsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPearlCupRankingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPearlCupRankingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPrefecturesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPrefecturesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRecommendedLSingersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRecommendedLSingersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListTownsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListTownsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListTwitterAccountsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListTwitterAccountsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWebPageV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWebPageV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWebPageV1Response.WebPage', null, global);
goog.exportSymbol('proto.jp.singcolor.LogoutV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.LogoutV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ReadNotificationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ReadNotificationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.RequestWithdrawV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.RequestWithdrawV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveStoreReviewRequestActionV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.ActionType', null, global);
goog.exportSymbol('proto.jp.singcolor.SaveStoreReviewRequestActionV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.SearchUsersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SearchUsersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.TerminateModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.TerminateModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UnblockByModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UnblockByModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UnblockRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UnblockResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UnfollowRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UnfollowResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateDeviceV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateFollowNotificationSettingRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateFollowNotificationSettingResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateGenderV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateGenderV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserSettingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserSettingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserTownV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserTownV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserV1Request.displayName = 'proto.jp.singcolor.GetUserV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserV1Response.displayName = 'proto.jp.singcolor.GetUserV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AppleLoginV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AppleLoginV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AppleLoginV1Request.displayName = 'proto.jp.singcolor.AppleLoginV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AppleLoginV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AppleLoginV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AppleLoginV1Response.displayName = 'proto.jp.singcolor.AppleLoginV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LineLoginV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LineLoginV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LineLoginV1Request.displayName = 'proto.jp.singcolor.LineLoginV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LineLoginV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LineLoginV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LineLoginV1Response.displayName = 'proto.jp.singcolor.LineLoginV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LineLoginByCodeV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LineLoginByCodeV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LineLoginByCodeV1Request.displayName = 'proto.jp.singcolor.LineLoginByCodeV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LineLoginByCodeV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LineLoginByCodeV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LineLoginByCodeV1Response.displayName = 'proto.jp.singcolor.LineLoginByCodeV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLineLoginStateV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLineLoginStateV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLineLoginStateV1Request.displayName = 'proto.jp.singcolor.GetLineLoginStateV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLineLoginStateV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLineLoginStateV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLineLoginStateV1Response.displayName = 'proto.jp.singcolor.GetLineLoginStateV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GoogleLoginV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GoogleLoginV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GoogleLoginV1Request.displayName = 'proto.jp.singcolor.GoogleLoginV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GoogleLoginV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GoogleLoginV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GoogleLoginV1Response.displayName = 'proto.jp.singcolor.GoogleLoginV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLoginTokenV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLoginTokenV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLoginTokenV1Request.displayName = 'proto.jp.singcolor.CreateLoginTokenV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLoginTokenV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLoginTokenV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLoginTokenV1Response.displayName = 'proto.jp.singcolor.CreateLoginTokenV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LogoutV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LogoutV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LogoutV1Request.displayName = 'proto.jp.singcolor.LogoutV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LogoutV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LogoutV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LogoutV1Response.displayName = 'proto.jp.singcolor.LogoutV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddTwitterAccountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddTwitterAccountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddTwitterAccountV1Request.displayName = 'proto.jp.singcolor.AddTwitterAccountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.AddTwitterAccountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.AddTwitterAccountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.AddTwitterAccountV1Response.displayName = 'proto.jp.singcolor.AddTwitterAccountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListTwitterAccountsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListTwitterAccountsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListTwitterAccountsV1Request.displayName = 'proto.jp.singcolor.ListTwitterAccountsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListTwitterAccountsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListTwitterAccountsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListTwitterAccountsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListTwitterAccountsV1Response.displayName = 'proto.jp.singcolor.ListTwitterAccountsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteTwitterAccountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteTwitterAccountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteTwitterAccountV1Request.displayName = 'proto.jp.singcolor.DeleteTwitterAccountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetTwitterSettingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetTwitterSettingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetTwitterSettingV1Request.displayName = 'proto.jp.singcolor.GetTwitterSettingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetTwitterSettingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetTwitterSettingV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetTwitterSettingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetTwitterSettingV1Response.displayName = 'proto.jp.singcolor.GetTwitterSettingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateUserV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateUserV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateUserV1Request.displayName = 'proto.jp.singcolor.CreateUserV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateUserV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateUserV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateUserV1Response.displayName = 'proto.jp.singcolor.CreateUserV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DoneRegistrationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DoneRegistrationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DoneRegistrationV1Request.displayName = 'proto.jp.singcolor.DoneRegistrationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DoneRegistrationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DoneRegistrationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DoneRegistrationV1Response.displayName = 'proto.jp.singcolor.DoneRegistrationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPrefecturesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListPrefecturesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPrefecturesV1Request.displayName = 'proto.jp.singcolor.ListPrefecturesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPrefecturesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListPrefecturesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListPrefecturesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPrefecturesV1Response.displayName = 'proto.jp.singcolor.ListPrefecturesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListTownsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListTownsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListTownsV1Request.displayName = 'proto.jp.singcolor.ListTownsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListTownsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListTownsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListTownsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListTownsV1Response.displayName = 'proto.jp.singcolor.ListTownsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserTownV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserTownV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserTownV1Request.displayName = 'proto.jp.singcolor.UpdateUserTownV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserTownV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserTownV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserTownV1Response.displayName = 'proto.jp.singcolor.UpdateUserTownV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFollowingsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFollowingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFollowingsRequest.displayName = 'proto.jp.singcolor.ListFollowingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFollowingsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListFollowingsResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListFollowingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFollowingsResponse.displayName = 'proto.jp.singcolor.ListFollowingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.FollowRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.FollowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.FollowRequest.displayName = 'proto.jp.singcolor.FollowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.FollowResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.FollowResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.FollowResponse.displayName = 'proto.jp.singcolor.FollowResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnfollowRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnfollowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnfollowRequest.displayName = 'proto.jp.singcolor.UnfollowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnfollowResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnfollowResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnfollowResponse.displayName = 'proto.jp.singcolor.UnfollowResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateFollowNotificationSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateFollowNotificationSettingRequest.displayName = 'proto.jp.singcolor.UpdateFollowNotificationSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateFollowNotificationSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateFollowNotificationSettingResponse.displayName = 'proto.jp.singcolor.UpdateFollowNotificationSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFollowersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFollowersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFollowersRequest.displayName = 'proto.jp.singcolor.ListFollowersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFollowersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListFollowersResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListFollowersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFollowersResponse.displayName = 'proto.jp.singcolor.ListFollowersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBlockingsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListBlockingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBlockingsRequest.displayName = 'proto.jp.singcolor.ListBlockingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBlockingsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListBlockingsResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListBlockingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBlockingsResponse.displayName = 'proto.jp.singcolor.ListBlockingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DoBlockRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DoBlockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DoBlockRequest.displayName = 'proto.jp.singcolor.DoBlockRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DoBlockResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DoBlockResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DoBlockResponse.displayName = 'proto.jp.singcolor.DoBlockResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnblockRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnblockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnblockRequest.displayName = 'proto.jp.singcolor.UnblockRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnblockResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnblockResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnblockResponse.displayName = 'proto.jp.singcolor.UnblockResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserProfileV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserProfileV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserProfileV1Request.displayName = 'proto.jp.singcolor.GetUserProfileV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserProfileV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetUserProfileV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetUserProfileV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserProfileV1Response.displayName = 'proto.jp.singcolor.GetUserProfileV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserProfileV2Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserProfileV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserProfileV2Request.displayName = 'proto.jp.singcolor.GetUserProfileV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserProfileV2Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetUserProfileV2Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetUserProfileV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserProfileV2Response.displayName = 'proto.jp.singcolor.GetUserProfileV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserSettingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserSettingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserSettingV1Request.displayName = 'proto.jp.singcolor.GetUserSettingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserSettingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetUserSettingV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetUserSettingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserSettingV1Response.displayName = 'proto.jp.singcolor.GetUserSettingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserSettingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.UpdateUserSettingV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserSettingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserSettingV1Request.displayName = 'proto.jp.singcolor.UpdateUserSettingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserSettingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.UpdateUserSettingV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserSettingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserSettingV1Response.displayName = 'proto.jp.singcolor.UpdateUserSettingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateDeviceV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateDeviceV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateDeviceV1Request.displayName = 'proto.jp.singcolor.UpdateDeviceV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetCoinBalanceV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetCoinBalanceV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetCoinBalanceV1Request.displayName = 'proto.jp.singcolor.GetCoinBalanceV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetCoinBalanceV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetCoinBalanceV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetCoinBalanceV1Response.displayName = 'proto.jp.singcolor.GetCoinBalanceV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.displayName = 'proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.displayName = 'proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetMonthlyStatisticsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetMonthlyStatisticsV1Request.displayName = 'proto.jp.singcolor.GetMonthlyStatisticsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetMonthlyStatisticsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetMonthlyStatisticsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetMonthlyStatisticsV1Response.displayName = 'proto.jp.singcolor.GetMonthlyStatisticsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiverPromotionMeterV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiverPromotionMeterV1Request.displayName = 'proto.jp.singcolor.GetLiverPromotionMeterV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiverPromotionMeterV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiverPromotionMeterV1Response.displayName = 'proto.jp.singcolor.GetLiverPromotionMeterV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SearchUsersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SearchUsersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SearchUsersV1Request.displayName = 'proto.jp.singcolor.SearchUsersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SearchUsersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.SearchUsersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.SearchUsersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SearchUsersV1Response.displayName = 'proto.jp.singcolor.SearchUsersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.displayName = 'proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.displayName = 'proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLiverRankingTermTypesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiverRankingTermTypesV1Request.displayName = 'proto.jp.singcolor.ListLiverRankingTermTypesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLiverRankingTermTypesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLiverRankingTermTypesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiverRankingTermTypesV1Response.displayName = 'proto.jp.singcolor.ListLiverRankingTermTypesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiverRankingV2Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLiverRankingV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiverRankingV2Request.displayName = 'proto.jp.singcolor.ListLiverRankingV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiverRankingV2Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLiverRankingV2Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLiverRankingV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiverRankingV2Response.displayName = 'proto.jp.singcolor.ListLiverRankingV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLoginBonusV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLoginBonusV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLoginBonusV1Request.displayName = 'proto.jp.singcolor.GetLoginBonusV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLoginBonusV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLoginBonusV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLoginBonusV1Response.displayName = 'proto.jp.singcolor.GetLoginBonusV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RequestWithdrawV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RequestWithdrawV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RequestWithdrawV1Request.displayName = 'proto.jp.singcolor.RequestWithdrawV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RequestWithdrawV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RequestWithdrawV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RequestWithdrawV1Response.displayName = 'proto.jp.singcolor.RequestWithdrawV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CancelWithdrawV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CancelWithdrawV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CancelWithdrawV1Request.displayName = 'proto.jp.singcolor.CancelWithdrawV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CancelWithdrawV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CancelWithdrawV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CancelWithdrawV1Response.displayName = 'proto.jp.singcolor.CancelWithdrawV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetWithdrawV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetWithdrawV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetWithdrawV1Request.displayName = 'proto.jp.singcolor.GetWithdrawV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetWithdrawV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetWithdrawV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetWithdrawV1Response.displayName = 'proto.jp.singcolor.GetWithdrawV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWebPageV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListWebPageV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWebPageV1Request.displayName = 'proto.jp.singcolor.ListWebPageV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWebPageV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListWebPageV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListWebPageV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWebPageV1Response.displayName = 'proto.jp.singcolor.ListWebPageV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListWebPageV1Response.WebPage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWebPageV1Response.WebPage.displayName = 'proto.jp.singcolor.ListWebPageV1Response.WebPage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUnreadNotificationInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUnreadNotificationInfoV1Request.displayName = 'proto.jp.singcolor.GetUnreadNotificationInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUnreadNotificationInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUnreadNotificationInfoV1Response.displayName = 'proto.jp.singcolor.GetUnreadNotificationInfoV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ReadNotificationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ReadNotificationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ReadNotificationV1Request.displayName = 'proto.jp.singcolor.ReadNotificationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ReadNotificationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ReadNotificationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ReadNotificationV1Response.displayName = 'proto.jp.singcolor.ReadNotificationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserBanV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserBanV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserBanV1Request.displayName = 'proto.jp.singcolor.GetUserBanV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserBanV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserBanV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserBanV1Response.displayName = 'proto.jp.singcolor.GetUserBanV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRecommendedLSingersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListRecommendedLSingersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRecommendedLSingersV1Request.displayName = 'proto.jp.singcolor.ListRecommendedLSingersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListRecommendedLSingersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListRecommendedLSingersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRecommendedLSingersV1Response.displayName = 'proto.jp.singcolor.ListRecommendedLSingersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetTweetTimelineV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetTweetTimelineV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetTweetTimelineV1Request.displayName = 'proto.jp.singcolor.GetTweetTimelineV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetTweetTimelineV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetTweetTimelineV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetTweetTimelineV1Response.displayName = 'proto.jp.singcolor.GetTweetTimelineV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.displayName = 'proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.displayName = 'proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SaveStoreReviewRequestActionV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.displayName = 'proto.jp.singcolor.SaveStoreReviewRequestActionV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SaveStoreReviewRequestActionV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.displayName = 'proto.jp.singcolor.SaveStoreReviewRequestActionV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListAvailableWebFeaturesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListAvailableWebFeaturesV1Request.displayName = 'proto.jp.singcolor.ListAvailableWebFeaturesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListAvailableWebFeaturesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListAvailableWebFeaturesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListAvailableWebFeaturesV1Response.displayName = 'proto.jp.singcolor.ListAvailableWebFeaturesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListAvailableAppFeaturesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListAvailableAppFeaturesV1Request.displayName = 'proto.jp.singcolor.ListAvailableAppFeaturesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListAvailableAppFeaturesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListAvailableAppFeaturesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListAvailableAppFeaturesV1Response.displayName = 'proto.jp.singcolor.ListAvailableAppFeaturesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPearlCupRankingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListPearlCupRankingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPearlCupRankingV1Request.displayName = 'proto.jp.singcolor.ListPearlCupRankingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPearlCupRankingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListPearlCupRankingV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListPearlCupRankingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPearlCupRankingV1Response.displayName = 'proto.jp.singcolor.ListPearlCupRankingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetPearlPrizeItemDreamV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.displayName = 'proto.jp.singcolor.GetPearlPrizeItemDreamV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetPearlPrizeItemDreamV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.displayName = 'proto.jp.singcolor.GetPearlPrizeItemDreamV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListPearlCupPrizeItemsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.displayName = 'proto.jp.singcolor.ListPearlCupPrizeItemsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListPearlCupPrizeItemsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.displayName = 'proto.jp.singcolor.ListPearlCupPrizeItemsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.displayName = 'proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.displayName = 'proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetListenerMembershipInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetListenerMembershipInfoV1Request.displayName = 'proto.jp.singcolor.GetListenerMembershipInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetListenerMembershipInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetListenerMembershipInfoV1Response.displayName = 'proto.jp.singcolor.GetListenerMembershipInfoV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiverMembershipInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiverMembershipInfoV1Request.displayName = 'proto.jp.singcolor.GetLiverMembershipInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiverMembershipInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiverMembershipInfoV1Response.displayName = 'proto.jp.singcolor.GetLiverMembershipInfoV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.displayName = 'proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.displayName = 'proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLivePermissionReviewDialogV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.displayName = 'proto.jp.singcolor.GetLivePermissionReviewDialogV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLivePermissionReviewDialogV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.displayName = 'proto.jp.singcolor.GetLivePermissionReviewDialogV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateModeratorV1Request.displayName = 'proto.jp.singcolor.CreateModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateModeratorV1Response.displayName = 'proto.jp.singcolor.CreateModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListModeratorsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListModeratorsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListModeratorsV1Request.displayName = 'proto.jp.singcolor.ListModeratorsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListModeratorsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListModeratorsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListModeratorsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListModeratorsV1Response.displayName = 'proto.jp.singcolor.ListModeratorsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.TerminateModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.TerminateModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.TerminateModeratorV1Request.displayName = 'proto.jp.singcolor.TerminateModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.TerminateModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.TerminateModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.TerminateModeratorV1Response.displayName = 'proto.jp.singcolor.TerminateModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetModeratorV1Request.displayName = 'proto.jp.singcolor.GetModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetModeratorV1Response.displayName = 'proto.jp.singcolor.GetModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DoBlockByModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DoBlockByModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DoBlockByModeratorV1Request.displayName = 'proto.jp.singcolor.DoBlockByModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DoBlockByModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DoBlockByModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DoBlockByModeratorV1Response.displayName = 'proto.jp.singcolor.DoBlockByModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnblockByModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnblockByModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnblockByModeratorV1Request.displayName = 'proto.jp.singcolor.UnblockByModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnblockByModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnblockByModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnblockByModeratorV1Response.displayName = 'proto.jp.singcolor.UnblockByModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateGenderV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateGenderV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateGenderV1Request.displayName = 'proto.jp.singcolor.UpdateGenderV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateGenderV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateGenderV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateGenderV1Response.displayName = 'proto.jp.singcolor.UpdateGenderV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.displayName = 'proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.displayName = 'proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.displayName = 'proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.displayName = 'proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.displayName = 'proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.displayName = 'proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.displayName = 'proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.displayName = 'proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.displayName = 'proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.displayName = 'proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.displayName = 'proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.displayName = 'proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.displayName = 'proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.displayName = 'proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.displayName = 'proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.displayName = 'proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserV1Request}
 */
proto.jp.singcolor.GetUserV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserV1Request;
    return proto.jp.singcolor.GetUserV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserV1Request}
 */
proto.jp.singcolor.GetUserV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserV1Request} returns this
 */
proto.jp.singcolor.GetUserV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            userBlock: (f = msg.getUserBlock()) && users_entity_pb.UserBlock.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserV1Response}
 */
proto.jp.singcolor.GetUserV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserV1Response;
    return proto.jp.singcolor.GetUserV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserV1Response}
 */
proto.jp.singcolor.GetUserV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserFollow(value);
                break;
            case 3:
                var value = new users_entity_pb.UserBlock;
                reader.readMessage(value, users_entity_pb.UserBlock.deserializeBinaryFromReader);
                msg.setUserBlock(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getUserFollow();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getUserBlock();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserBlock.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetUserV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.GetUserV1Response} returns this
*/
proto.jp.singcolor.GetUserV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserV1Response} returns this
 */
proto.jp.singcolor.GetUserV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.GetUserV1Response.prototype.getUserFollow = function () {
    return /** @type{?proto.jp.singcolor.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};
/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.GetUserV1Response} returns this
*/
proto.jp.singcolor.GetUserV1Response.prototype.setUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserV1Response} returns this
 */
proto.jp.singcolor.GetUserV1Response.prototype.clearUserFollow = function () {
    return this.setUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserV1Response.prototype.hasUserFollow = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserBlock user_block = 3;
 * @return {?proto.jp.singcolor.UserBlock}
 */
proto.jp.singcolor.GetUserV1Response.prototype.getUserBlock = function () {
    return /** @type{?proto.jp.singcolor.UserBlock} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBlock, 3));
};
/**
 * @param {?proto.jp.singcolor.UserBlock|undefined} value
 * @return {!proto.jp.singcolor.GetUserV1Response} returns this
*/
proto.jp.singcolor.GetUserV1Response.prototype.setUserBlock = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserV1Response} returns this
 */
proto.jp.singcolor.GetUserV1Response.prototype.clearUserBlock = function () {
    return this.setUserBlock(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserV1Response.prototype.hasUserBlock = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AppleLoginV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AppleLoginV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AppleLoginV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AppleLoginV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            idToken: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AppleLoginV1Request}
 */
proto.jp.singcolor.AppleLoginV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AppleLoginV1Request;
    return proto.jp.singcolor.AppleLoginV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AppleLoginV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AppleLoginV1Request}
 */
proto.jp.singcolor.AppleLoginV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setIdToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AppleLoginV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AppleLoginV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AppleLoginV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AppleLoginV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIdToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string id_token = 2;
 * @return {string}
 */
proto.jp.singcolor.AppleLoginV1Request.prototype.getIdToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AppleLoginV1Request} returns this
 */
proto.jp.singcolor.AppleLoginV1Request.prototype.setIdToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AppleLoginV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AppleLoginV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AppleLoginV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AppleLoginV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            customToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            showReRegistrationNotAllowedScreen: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AppleLoginV1Response}
 */
proto.jp.singcolor.AppleLoginV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AppleLoginV1Response;
    return proto.jp.singcolor.AppleLoginV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AppleLoginV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AppleLoginV1Response}
 */
proto.jp.singcolor.AppleLoginV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCustomToken(value);
                break;
            case 2:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setShowReRegistrationNotAllowedScreen(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AppleLoginV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AppleLoginV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AppleLoginV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AppleLoginV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCustomToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getShowReRegistrationNotAllowedScreen();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string custom_token = 1;
 * @return {string}
 */
proto.jp.singcolor.AppleLoginV1Response.prototype.getCustomToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AppleLoginV1Response} returns this
 */
proto.jp.singcolor.AppleLoginV1Response.prototype.setCustomToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional User user = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.AppleLoginV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.AppleLoginV1Response} returns this
*/
proto.jp.singcolor.AppleLoginV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.AppleLoginV1Response} returns this
 */
proto.jp.singcolor.AppleLoginV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.AppleLoginV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional bool show_re_registration_not_allowed_screen = 3;
 * @return {boolean}
 */
proto.jp.singcolor.AppleLoginV1Response.prototype.getShowReRegistrationNotAllowedScreen = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.AppleLoginV1Response} returns this
 */
proto.jp.singcolor.AppleLoginV1Response.prototype.setShowReRegistrationNotAllowedScreen = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LineLoginV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LineLoginV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LineLoginV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LineLoginV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            idToken: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LineLoginV1Request}
 */
proto.jp.singcolor.LineLoginV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LineLoginV1Request;
    return proto.jp.singcolor.LineLoginV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LineLoginV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LineLoginV1Request}
 */
proto.jp.singcolor.LineLoginV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setIdToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LineLoginV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LineLoginV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LineLoginV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LineLoginV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIdToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string id_token = 1;
 * @return {string}
 */
proto.jp.singcolor.LineLoginV1Request.prototype.getIdToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LineLoginV1Request} returns this
 */
proto.jp.singcolor.LineLoginV1Request.prototype.setIdToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LineLoginV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LineLoginV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LineLoginV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LineLoginV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            customToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            showReRegistrationNotAllowedScreen: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LineLoginV1Response}
 */
proto.jp.singcolor.LineLoginV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LineLoginV1Response;
    return proto.jp.singcolor.LineLoginV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LineLoginV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LineLoginV1Response}
 */
proto.jp.singcolor.LineLoginV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCustomToken(value);
                break;
            case 2:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setShowReRegistrationNotAllowedScreen(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LineLoginV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LineLoginV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LineLoginV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LineLoginV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCustomToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getShowReRegistrationNotAllowedScreen();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string custom_token = 1;
 * @return {string}
 */
proto.jp.singcolor.LineLoginV1Response.prototype.getCustomToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LineLoginV1Response} returns this
 */
proto.jp.singcolor.LineLoginV1Response.prototype.setCustomToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional User user = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LineLoginV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LineLoginV1Response} returns this
*/
proto.jp.singcolor.LineLoginV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LineLoginV1Response} returns this
 */
proto.jp.singcolor.LineLoginV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LineLoginV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional bool show_re_registration_not_allowed_screen = 3;
 * @return {boolean}
 */
proto.jp.singcolor.LineLoginV1Response.prototype.getShowReRegistrationNotAllowedScreen = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LineLoginV1Response} returns this
 */
proto.jp.singcolor.LineLoginV1Response.prototype.setShowReRegistrationNotAllowedScreen = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LineLoginByCodeV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LineLoginByCodeV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LineLoginByCodeV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LineLoginByCodeV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            code: jspb.Message.getFieldWithDefault(msg, 1, ""),
            redirectUri: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Request}
 */
proto.jp.singcolor.LineLoginByCodeV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LineLoginByCodeV1Request;
    return proto.jp.singcolor.LineLoginByCodeV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LineLoginByCodeV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Request}
 */
proto.jp.singcolor.LineLoginByCodeV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCode(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setRedirectUri(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LineLoginByCodeV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LineLoginByCodeV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LineLoginByCodeV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LineLoginByCodeV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCode();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getRedirectUri();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string code = 1;
 * @return {string}
 */
proto.jp.singcolor.LineLoginByCodeV1Request.prototype.getCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Request} returns this
 */
proto.jp.singcolor.LineLoginByCodeV1Request.prototype.setCode = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string redirect_uri = 2;
 * @return {string}
 */
proto.jp.singcolor.LineLoginByCodeV1Request.prototype.getRedirectUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Request} returns this
 */
proto.jp.singcolor.LineLoginByCodeV1Request.prototype.setRedirectUri = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LineLoginByCodeV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LineLoginByCodeV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LineLoginByCodeV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LineLoginByCodeV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            customToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            showReRegistrationNotAllowedScreen: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Response}
 */
proto.jp.singcolor.LineLoginByCodeV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LineLoginByCodeV1Response;
    return proto.jp.singcolor.LineLoginByCodeV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LineLoginByCodeV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Response}
 */
proto.jp.singcolor.LineLoginByCodeV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCustomToken(value);
                break;
            case 2:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setShowReRegistrationNotAllowedScreen(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LineLoginByCodeV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LineLoginByCodeV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LineLoginByCodeV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCustomToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getShowReRegistrationNotAllowedScreen();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string custom_token = 1;
 * @return {string}
 */
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.getCustomToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Response} returns this
 */
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.setCustomToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional User user = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Response} returns this
*/
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Response} returns this
 */
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional bool show_re_registration_not_allowed_screen = 3;
 * @return {boolean}
 */
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.getShowReRegistrationNotAllowedScreen = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LineLoginByCodeV1Response} returns this
 */
proto.jp.singcolor.LineLoginByCodeV1Response.prototype.setShowReRegistrationNotAllowedScreen = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLineLoginStateV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLineLoginStateV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLineLoginStateV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLineLoginStateV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLineLoginStateV1Request}
 */
proto.jp.singcolor.GetLineLoginStateV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLineLoginStateV1Request;
    return proto.jp.singcolor.GetLineLoginStateV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLineLoginStateV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLineLoginStateV1Request}
 */
proto.jp.singcolor.GetLineLoginStateV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLineLoginStateV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLineLoginStateV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLineLoginStateV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLineLoginStateV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLineLoginStateV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLineLoginStateV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLineLoginStateV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLineLoginStateV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            connected: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            rewardCoin: (f = msg.getRewardCoin()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLineLoginStateV1Response}
 */
proto.jp.singcolor.GetLineLoginStateV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLineLoginStateV1Response;
    return proto.jp.singcolor.GetLineLoginStateV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLineLoginStateV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLineLoginStateV1Response}
 */
proto.jp.singcolor.GetLineLoginStateV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setConnected(value);
                break;
            case 2:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setRewardCoin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLineLoginStateV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLineLoginStateV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLineLoginStateV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLineLoginStateV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getConnected();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getRewardCoin();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
};
/**
 * optional bool connected = 1;
 * @return {boolean}
 */
proto.jp.singcolor.GetLineLoginStateV1Response.prototype.getConnected = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetLineLoginStateV1Response} returns this
 */
proto.jp.singcolor.GetLineLoginStateV1Response.prototype.setConnected = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional CoinAmount reward_coin = 2;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.GetLineLoginStateV1Response.prototype.getRewardCoin = function () {
    return /** @type{?proto.jp.singcolor.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.GetLineLoginStateV1Response} returns this
*/
proto.jp.singcolor.GetLineLoginStateV1Response.prototype.setRewardCoin = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLineLoginStateV1Response} returns this
 */
proto.jp.singcolor.GetLineLoginStateV1Response.prototype.clearRewardCoin = function () {
    return this.setRewardCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLineLoginStateV1Response.prototype.hasRewardCoin = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GoogleLoginV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GoogleLoginV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GoogleLoginV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GoogleLoginV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            idToken: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GoogleLoginV1Request}
 */
proto.jp.singcolor.GoogleLoginV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GoogleLoginV1Request;
    return proto.jp.singcolor.GoogleLoginV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GoogleLoginV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GoogleLoginV1Request}
 */
proto.jp.singcolor.GoogleLoginV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setIdToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GoogleLoginV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GoogleLoginV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GoogleLoginV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GoogleLoginV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIdToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string id_token = 1;
 * @return {string}
 */
proto.jp.singcolor.GoogleLoginV1Request.prototype.getIdToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GoogleLoginV1Request} returns this
 */
proto.jp.singcolor.GoogleLoginV1Request.prototype.setIdToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GoogleLoginV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GoogleLoginV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GoogleLoginV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GoogleLoginV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            customToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            showReRegistrationNotAllowedScreen: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GoogleLoginV1Response}
 */
proto.jp.singcolor.GoogleLoginV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GoogleLoginV1Response;
    return proto.jp.singcolor.GoogleLoginV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GoogleLoginV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GoogleLoginV1Response}
 */
proto.jp.singcolor.GoogleLoginV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCustomToken(value);
                break;
            case 2:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setShowReRegistrationNotAllowedScreen(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GoogleLoginV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GoogleLoginV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GoogleLoginV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GoogleLoginV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCustomToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getShowReRegistrationNotAllowedScreen();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string custom_token = 1;
 * @return {string}
 */
proto.jp.singcolor.GoogleLoginV1Response.prototype.getCustomToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GoogleLoginV1Response} returns this
 */
proto.jp.singcolor.GoogleLoginV1Response.prototype.setCustomToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional User user = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.GoogleLoginV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.GoogleLoginV1Response} returns this
*/
proto.jp.singcolor.GoogleLoginV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GoogleLoginV1Response} returns this
 */
proto.jp.singcolor.GoogleLoginV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GoogleLoginV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional bool show_re_registration_not_allowed_screen = 3;
 * @return {boolean}
 */
proto.jp.singcolor.GoogleLoginV1Response.prototype.getShowReRegistrationNotAllowedScreen = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GoogleLoginV1Response} returns this
 */
proto.jp.singcolor.GoogleLoginV1Response.prototype.setShowReRegistrationNotAllowedScreen = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLoginTokenV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLoginTokenV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLoginTokenV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLoginTokenV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLoginTokenV1Request}
 */
proto.jp.singcolor.CreateLoginTokenV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLoginTokenV1Request;
    return proto.jp.singcolor.CreateLoginTokenV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLoginTokenV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLoginTokenV1Request}
 */
proto.jp.singcolor.CreateLoginTokenV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLoginTokenV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLoginTokenV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLoginTokenV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLoginTokenV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLoginTokenV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLoginTokenV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLoginTokenV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLoginTokenV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            customToken: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLoginTokenV1Response}
 */
proto.jp.singcolor.CreateLoginTokenV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLoginTokenV1Response;
    return proto.jp.singcolor.CreateLoginTokenV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLoginTokenV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLoginTokenV1Response}
 */
proto.jp.singcolor.CreateLoginTokenV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCustomToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLoginTokenV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLoginTokenV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLoginTokenV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLoginTokenV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCustomToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string custom_token = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateLoginTokenV1Response.prototype.getCustomToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateLoginTokenV1Response} returns this
 */
proto.jp.singcolor.CreateLoginTokenV1Response.prototype.setCustomToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LogoutV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LogoutV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LogoutV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LogoutV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LogoutV1Request}
 */
proto.jp.singcolor.LogoutV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LogoutV1Request;
    return proto.jp.singcolor.LogoutV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LogoutV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LogoutV1Request}
 */
proto.jp.singcolor.LogoutV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LogoutV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LogoutV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LogoutV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LogoutV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LogoutV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LogoutV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LogoutV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LogoutV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LogoutV1Response}
 */
proto.jp.singcolor.LogoutV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LogoutV1Response;
    return proto.jp.singcolor.LogoutV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LogoutV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LogoutV1Response}
 */
proto.jp.singcolor.LogoutV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LogoutV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LogoutV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LogoutV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LogoutV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddTwitterAccountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddTwitterAccountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddTwitterAccountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddTwitterAccountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            oauthToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            oauthSecretToken: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddTwitterAccountV1Request}
 */
proto.jp.singcolor.AddTwitterAccountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddTwitterAccountV1Request;
    return proto.jp.singcolor.AddTwitterAccountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddTwitterAccountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddTwitterAccountV1Request}
 */
proto.jp.singcolor.AddTwitterAccountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOauthToken(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOauthSecretToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddTwitterAccountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddTwitterAccountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddTwitterAccountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddTwitterAccountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOauthToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOauthSecretToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string oauth_token = 1;
 * @return {string}
 */
proto.jp.singcolor.AddTwitterAccountV1Request.prototype.getOauthToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddTwitterAccountV1Request} returns this
 */
proto.jp.singcolor.AddTwitterAccountV1Request.prototype.setOauthToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string oauth_secret_token = 2;
 * @return {string}
 */
proto.jp.singcolor.AddTwitterAccountV1Request.prototype.getOauthSecretToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.AddTwitterAccountV1Request} returns this
 */
proto.jp.singcolor.AddTwitterAccountV1Request.prototype.setOauthSecretToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.AddTwitterAccountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.AddTwitterAccountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.AddTwitterAccountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.AddTwitterAccountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            twitterAccount: (f = msg.getTwitterAccount()) && users_entity_pb.TwitterAccount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.AddTwitterAccountV1Response}
 */
proto.jp.singcolor.AddTwitterAccountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.AddTwitterAccountV1Response;
    return proto.jp.singcolor.AddTwitterAccountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.AddTwitterAccountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.AddTwitterAccountV1Response}
 */
proto.jp.singcolor.AddTwitterAccountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.TwitterAccount;
                reader.readMessage(value, users_entity_pb.TwitterAccount.deserializeBinaryFromReader);
                msg.setTwitterAccount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.AddTwitterAccountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.AddTwitterAccountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.AddTwitterAccountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.AddTwitterAccountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTwitterAccount();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.TwitterAccount.serializeBinaryToWriter);
    }
};
/**
 * optional TwitterAccount twitter_account = 1;
 * @return {?proto.jp.singcolor.TwitterAccount}
 */
proto.jp.singcolor.AddTwitterAccountV1Response.prototype.getTwitterAccount = function () {
    return /** @type{?proto.jp.singcolor.TwitterAccount} */ (jspb.Message.getWrapperField(this, users_entity_pb.TwitterAccount, 1));
};
/**
 * @param {?proto.jp.singcolor.TwitterAccount|undefined} value
 * @return {!proto.jp.singcolor.AddTwitterAccountV1Response} returns this
*/
proto.jp.singcolor.AddTwitterAccountV1Response.prototype.setTwitterAccount = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.AddTwitterAccountV1Response} returns this
 */
proto.jp.singcolor.AddTwitterAccountV1Response.prototype.clearTwitterAccount = function () {
    return this.setTwitterAccount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.AddTwitterAccountV1Response.prototype.hasTwitterAccount = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListTwitterAccountsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListTwitterAccountsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListTwitterAccountsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListTwitterAccountsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: jspb.Message.getFieldWithDefault(msg, 1, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Request}
 */
proto.jp.singcolor.ListTwitterAccountsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListTwitterAccountsV1Request;
    return proto.jp.singcolor.ListTwitterAccountsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListTwitterAccountsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Request}
 */
proto.jp.singcolor.ListTwitterAccountsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListTwitterAccountsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListTwitterAccountsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListTwitterAccountsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListTwitterAccountsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.jp.singcolor.ListTwitterAccountsV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Request} returns this
 */
proto.jp.singcolor.ListTwitterAccountsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListTwitterAccountsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Request} returns this
 */
proto.jp.singcolor.ListTwitterAccountsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListTwitterAccountsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListTwitterAccountsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListTwitterAccountsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            twitterAccountsList: jspb.Message.toObjectList(msg.getTwitterAccountsList(), users_entity_pb.TwitterAccount.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListTwitterAccountsV1Response;
    return proto.jp.singcolor.ListTwitterAccountsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListTwitterAccountsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.TwitterAccount;
                reader.readMessage(value, users_entity_pb.TwitterAccount.deserializeBinaryFromReader);
                msg.addTwitterAccounts(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListTwitterAccountsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListTwitterAccountsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTwitterAccountsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.TwitterAccount.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated TwitterAccount twitter_accounts = 1;
 * @return {!Array<!proto.jp.singcolor.TwitterAccount>}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.getTwitterAccountsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.TwitterAccount>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.TwitterAccount, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.TwitterAccount>} value
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response} returns this
*/
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.setTwitterAccountsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.TwitterAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.TwitterAccount}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.addTwitterAccounts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.TwitterAccount, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response} returns this
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.clearTwitterAccountsList = function () {
    return this.setTwitterAccountsList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response} returns this
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response} returns this
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response} returns this
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response} returns this
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListTwitterAccountsV1Response} returns this
 */
proto.jp.singcolor.ListTwitterAccountsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteTwitterAccountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteTwitterAccountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteTwitterAccountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteTwitterAccountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            twitterUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteTwitterAccountV1Request}
 */
proto.jp.singcolor.DeleteTwitterAccountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteTwitterAccountV1Request;
    return proto.jp.singcolor.DeleteTwitterAccountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteTwitterAccountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteTwitterAccountV1Request}
 */
proto.jp.singcolor.DeleteTwitterAccountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTwitterUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteTwitterAccountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteTwitterAccountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteTwitterAccountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteTwitterAccountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTwitterUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string twitter_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteTwitterAccountV1Request.prototype.getTwitterUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteTwitterAccountV1Request} returns this
 */
proto.jp.singcolor.DeleteTwitterAccountV1Request.prototype.setTwitterUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetTwitterSettingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetTwitterSettingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetTwitterSettingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetTwitterSettingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetTwitterSettingV1Request}
 */
proto.jp.singcolor.GetTwitterSettingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetTwitterSettingV1Request;
    return proto.jp.singcolor.GetTwitterSettingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetTwitterSettingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetTwitterSettingV1Request}
 */
proto.jp.singcolor.GetTwitterSettingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetTwitterSettingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetTwitterSettingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetTwitterSettingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetTwitterSettingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetTwitterSettingV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetTwitterSettingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetTwitterSettingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetTwitterSettingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetTwitterSettingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            twitterAccountsList: jspb.Message.toObjectList(msg.getTwitterAccountsList(), users_entity_pb.TwitterAccount.toObject, includeInstance),
            shareTwitterAccount: (f = msg.getShareTwitterAccount()) && users_entity_pb.TwitterAccount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetTwitterSettingV1Response}
 */
proto.jp.singcolor.GetTwitterSettingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetTwitterSettingV1Response;
    return proto.jp.singcolor.GetTwitterSettingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetTwitterSettingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetTwitterSettingV1Response}
 */
proto.jp.singcolor.GetTwitterSettingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.TwitterAccount;
                reader.readMessage(value, users_entity_pb.TwitterAccount.deserializeBinaryFromReader);
                msg.addTwitterAccounts(value);
                break;
            case 2:
                var value = new users_entity_pb.TwitterAccount;
                reader.readMessage(value, users_entity_pb.TwitterAccount.deserializeBinaryFromReader);
                msg.setShareTwitterAccount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetTwitterSettingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetTwitterSettingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetTwitterSettingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTwitterAccountsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.TwitterAccount.serializeBinaryToWriter);
    }
    f = message.getShareTwitterAccount();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.TwitterAccount.serializeBinaryToWriter);
    }
};
/**
 * repeated TwitterAccount twitter_accounts = 1;
 * @return {!Array<!proto.jp.singcolor.TwitterAccount>}
 */
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.getTwitterAccountsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.TwitterAccount>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.TwitterAccount, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.TwitterAccount>} value
 * @return {!proto.jp.singcolor.GetTwitterSettingV1Response} returns this
*/
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.setTwitterAccountsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.TwitterAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.TwitterAccount}
 */
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.addTwitterAccounts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.TwitterAccount, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetTwitterSettingV1Response} returns this
 */
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.clearTwitterAccountsList = function () {
    return this.setTwitterAccountsList([]);
};
/**
 * optional TwitterAccount share_twitter_account = 2;
 * @return {?proto.jp.singcolor.TwitterAccount}
 */
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.getShareTwitterAccount = function () {
    return /** @type{?proto.jp.singcolor.TwitterAccount} */ (jspb.Message.getWrapperField(this, users_entity_pb.TwitterAccount, 2));
};
/**
 * @param {?proto.jp.singcolor.TwitterAccount|undefined} value
 * @return {!proto.jp.singcolor.GetTwitterSettingV1Response} returns this
*/
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.setShareTwitterAccount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetTwitterSettingV1Response} returns this
 */
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.clearShareTwitterAccount = function () {
    return this.setShareTwitterAccount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetTwitterSettingV1Response.prototype.hasShareTwitterAccount = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateUserV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateUserV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateUserV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateUserV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            name: jspb.Message.getFieldWithDefault(msg, 1, ""),
            profileImage: msg.getProfileImage_asB64(),
            ageId: jspb.Message.getFieldWithDefault(msg, 3, 0),
            birthday: jspb.Message.getFieldWithDefault(msg, 4, ""),
            gender: jspb.Message.getFieldWithDefault(msg, 5, 0),
            isGenderHidden: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateUserV1Request}
 */
proto.jp.singcolor.CreateUserV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateUserV1Request;
    return proto.jp.singcolor.CreateUserV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateUserV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateUserV1Request}
 */
proto.jp.singcolor.CreateUserV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setProfileImage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAgeId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setBirthday(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setGender(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsGenderHidden(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateUserV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateUserV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateUserV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getProfileImage_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getAgeId();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getBirthday();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getGender();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getIsGenderHidden();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * optional string name = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateUserV1Request} returns this
 */
proto.jp.singcolor.CreateUserV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bytes profile_image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.getProfileImage = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * optional bytes profile_image = 2;
 * This is a type-conversion wrapper around `getProfileImage()`
 * @return {string}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.getProfileImage_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getProfileImage()));
};
/**
 * optional bytes profile_image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getProfileImage()`
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.getProfileImage_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getProfileImage()));
};
/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.jp.singcolor.CreateUserV1Request} returns this
 */
proto.jp.singcolor.CreateUserV1Request.prototype.setProfileImage = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};
/**
 * optional int64 age_id = 3;
 * @return {number}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.getAgeId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateUserV1Request} returns this
 */
proto.jp.singcolor.CreateUserV1Request.prototype.setAgeId = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string birthday = 4;
 * @return {string}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.getBirthday = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateUserV1Request} returns this
 */
proto.jp.singcolor.CreateUserV1Request.prototype.setBirthday = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional Gender gender = 5;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.getGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.CreateUserV1Request} returns this
 */
proto.jp.singcolor.CreateUserV1Request.prototype.setGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional bool is_gender_hidden = 6;
 * @return {boolean}
 */
proto.jp.singcolor.CreateUserV1Request.prototype.getIsGenderHidden = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.CreateUserV1Request} returns this
 */
proto.jp.singcolor.CreateUserV1Request.prototype.setIsGenderHidden = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateUserV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateUserV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateUserV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateUserV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateUserV1Response}
 */
proto.jp.singcolor.CreateUserV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateUserV1Response;
    return proto.jp.singcolor.CreateUserV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateUserV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateUserV1Response}
 */
proto.jp.singcolor.CreateUserV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateUserV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateUserV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateUserV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateUserV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.CreateUserV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.CreateUserV1Response} returns this
*/
proto.jp.singcolor.CreateUserV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateUserV1Response} returns this
 */
proto.jp.singcolor.CreateUserV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateUserV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DoneRegistrationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DoneRegistrationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DoneRegistrationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DoneRegistrationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DoneRegistrationV1Request}
 */
proto.jp.singcolor.DoneRegistrationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DoneRegistrationV1Request;
    return proto.jp.singcolor.DoneRegistrationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DoneRegistrationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DoneRegistrationV1Request}
 */
proto.jp.singcolor.DoneRegistrationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DoneRegistrationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DoneRegistrationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DoneRegistrationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DoneRegistrationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DoneRegistrationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DoneRegistrationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DoneRegistrationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DoneRegistrationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DoneRegistrationV1Response}
 */
proto.jp.singcolor.DoneRegistrationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DoneRegistrationV1Response;
    return proto.jp.singcolor.DoneRegistrationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DoneRegistrationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DoneRegistrationV1Response}
 */
proto.jp.singcolor.DoneRegistrationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DoneRegistrationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DoneRegistrationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DoneRegistrationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DoneRegistrationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.DoneRegistrationV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.DoneRegistrationV1Response} returns this
*/
proto.jp.singcolor.DoneRegistrationV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DoneRegistrationV1Response} returns this
 */
proto.jp.singcolor.DoneRegistrationV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DoneRegistrationV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional CoinBalance coin_balance = 2;
 * @return {?proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.DoneRegistrationV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.jp.singcolor.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.DoneRegistrationV1Response} returns this
*/
proto.jp.singcolor.DoneRegistrationV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DoneRegistrationV1Response} returns this
 */
proto.jp.singcolor.DoneRegistrationV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DoneRegistrationV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPrefecturesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPrefecturesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPrefecturesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPrefecturesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPrefecturesV1Request}
 */
proto.jp.singcolor.ListPrefecturesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPrefecturesV1Request;
    return proto.jp.singcolor.ListPrefecturesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPrefecturesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPrefecturesV1Request}
 */
proto.jp.singcolor.ListPrefecturesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPrefecturesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPrefecturesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPrefecturesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPrefecturesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListPrefecturesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPrefecturesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPrefecturesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPrefecturesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPrefecturesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            prefecturesList: jspb.Message.toObjectList(msg.getPrefecturesList(), users_entity_pb.Prefecture.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPrefecturesV1Response}
 */
proto.jp.singcolor.ListPrefecturesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPrefecturesV1Response;
    return proto.jp.singcolor.ListPrefecturesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPrefecturesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPrefecturesV1Response}
 */
proto.jp.singcolor.ListPrefecturesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Prefecture;
                reader.readMessage(value, users_entity_pb.Prefecture.deserializeBinaryFromReader);
                msg.addPrefectures(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPrefecturesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPrefecturesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPrefecturesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPrefecturesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPrefecturesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.Prefecture.serializeBinaryToWriter);
    }
};
/**
 * repeated Prefecture prefectures = 1;
 * @return {!Array<!proto.jp.singcolor.Prefecture>}
 */
proto.jp.singcolor.ListPrefecturesV1Response.prototype.getPrefecturesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Prefecture>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.Prefecture, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Prefecture>} value
 * @return {!proto.jp.singcolor.ListPrefecturesV1Response} returns this
*/
proto.jp.singcolor.ListPrefecturesV1Response.prototype.setPrefecturesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Prefecture=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Prefecture}
 */
proto.jp.singcolor.ListPrefecturesV1Response.prototype.addPrefectures = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Prefecture, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPrefecturesV1Response} returns this
 */
proto.jp.singcolor.ListPrefecturesV1Response.prototype.clearPrefecturesList = function () {
    return this.setPrefecturesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListTownsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListTownsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListTownsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListTownsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            prefectureId: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListTownsV1Request}
 */
proto.jp.singcolor.ListTownsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListTownsV1Request;
    return proto.jp.singcolor.ListTownsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListTownsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListTownsV1Request}
 */
proto.jp.singcolor.ListTownsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPrefectureId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListTownsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListTownsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListTownsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListTownsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPrefectureId();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 prefecture_id = 1;
 * @return {number}
 */
proto.jp.singcolor.ListTownsV1Request.prototype.getPrefectureId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListTownsV1Request} returns this
 */
proto.jp.singcolor.ListTownsV1Request.prototype.setPrefectureId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListTownsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListTownsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListTownsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListTownsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListTownsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            townsList: jspb.Message.toObjectList(msg.getTownsList(), users_entity_pb.Town.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListTownsV1Response}
 */
proto.jp.singcolor.ListTownsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListTownsV1Response;
    return proto.jp.singcolor.ListTownsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListTownsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListTownsV1Response}
 */
proto.jp.singcolor.ListTownsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Town;
                reader.readMessage(value, users_entity_pb.Town.deserializeBinaryFromReader);
                msg.addTowns(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListTownsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListTownsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListTownsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListTownsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTownsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.Town.serializeBinaryToWriter);
    }
};
/**
 * repeated Town towns = 1;
 * @return {!Array<!proto.jp.singcolor.Town>}
 */
proto.jp.singcolor.ListTownsV1Response.prototype.getTownsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Town>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.Town, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Town>} value
 * @return {!proto.jp.singcolor.ListTownsV1Response} returns this
*/
proto.jp.singcolor.ListTownsV1Response.prototype.setTownsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Town=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Town}
 */
proto.jp.singcolor.ListTownsV1Response.prototype.addTowns = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Town, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListTownsV1Response} returns this
 */
proto.jp.singcolor.ListTownsV1Response.prototype.clearTownsList = function () {
    return this.setTownsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserTownV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserTownV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserTownV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserTownV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            prefectureId: jspb.Message.getFieldWithDefault(msg, 1, 0),
            townId: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserTownV1Request}
 */
proto.jp.singcolor.UpdateUserTownV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserTownV1Request;
    return proto.jp.singcolor.UpdateUserTownV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserTownV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserTownV1Request}
 */
proto.jp.singcolor.UpdateUserTownV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPrefectureId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTownId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserTownV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserTownV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserTownV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserTownV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPrefectureId();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getTownId();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional int64 prefecture_id = 1;
 * @return {number}
 */
proto.jp.singcolor.UpdateUserTownV1Request.prototype.getPrefectureId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUserTownV1Request} returns this
 */
proto.jp.singcolor.UpdateUserTownV1Request.prototype.setPrefectureId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 town_id = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateUserTownV1Request.prototype.getTownId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUserTownV1Request} returns this
 */
proto.jp.singcolor.UpdateUserTownV1Request.prototype.setTownId = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserTownV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserTownV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserTownV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserTownV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userProfile: (f = msg.getUserProfile()) && users_entity_pb.UserProfile.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserTownV1Response}
 */
proto.jp.singcolor.UpdateUserTownV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserTownV1Response;
    return proto.jp.singcolor.UpdateUserTownV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserTownV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserTownV1Response}
 */
proto.jp.singcolor.UpdateUserTownV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserProfile;
                reader.readMessage(value, users_entity_pb.UserProfile.deserializeBinaryFromReader);
                msg.setUserProfile(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserTownV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserTownV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserTownV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserTownV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserProfile();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserProfile.serializeBinaryToWriter);
    }
};
/**
 * optional UserProfile user_profile = 1;
 * @return {?proto.jp.singcolor.UserProfile}
 */
proto.jp.singcolor.UpdateUserTownV1Response.prototype.getUserProfile = function () {
    return /** @type{?proto.jp.singcolor.UserProfile} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserProfile, 1));
};
/**
 * @param {?proto.jp.singcolor.UserProfile|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserTownV1Response} returns this
*/
proto.jp.singcolor.UpdateUserTownV1Response.prototype.setUserProfile = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserTownV1Response} returns this
 */
proto.jp.singcolor.UpdateUserTownV1Response.prototype.clearUserProfile = function () {
    return this.setUserProfile(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserTownV1Response.prototype.hasUserProfile = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFollowingsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFollowingsRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFollowingsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFollowingsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: jspb.Message.getFieldWithDefault(msg, 2, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFollowingsRequest}
 */
proto.jp.singcolor.ListFollowingsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFollowingsRequest;
    return proto.jp.singcolor.ListFollowingsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFollowingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFollowingsRequest}
 */
proto.jp.singcolor.ListFollowingsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFollowingsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFollowingsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFollowingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFollowingsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListFollowingsRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFollowingsRequest} returns this
 */
proto.jp.singcolor.ListFollowingsRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFollowingsRequest.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowingsRequest} returns this
 */
proto.jp.singcolor.ListFollowingsRequest.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 limit = 3;
 * @return {number}
 */
proto.jp.singcolor.ListFollowingsRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowingsRequest} returns this
 */
proto.jp.singcolor.ListFollowingsRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListFollowingsResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFollowingsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFollowingsResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFollowingsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFollowingsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            followingsList: jspb.Message.toObjectList(msg.getFollowingsList(), users_entity_pb.Following.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFollowingsResponse}
 */
proto.jp.singcolor.ListFollowingsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFollowingsResponse;
    return proto.jp.singcolor.ListFollowingsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFollowingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFollowingsResponse}
 */
proto.jp.singcolor.ListFollowingsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Following;
                reader.readMessage(value, users_entity_pb.Following.deserializeBinaryFromReader);
                msg.addFollowings(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFollowingsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFollowingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFollowingsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFollowingsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.Following.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated Following followings = 1;
 * @return {!Array<!proto.jp.singcolor.Following>}
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.getFollowingsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Following>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.Following, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Following>} value
 * @return {!proto.jp.singcolor.ListFollowingsResponse} returns this
*/
proto.jp.singcolor.ListFollowingsResponse.prototype.setFollowingsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Following=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Following}
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.addFollowings = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Following, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFollowingsResponse} returns this
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.clearFollowingsList = function () {
    return this.setFollowingsList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowingsResponse} returns this
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowingsResponse} returns this
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowingsResponse} returns this
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowingsResponse} returns this
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListFollowingsResponse} returns this
 */
proto.jp.singcolor.ListFollowingsResponse.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.FollowRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.FollowRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.FollowRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.FollowRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.FollowRequest}
 */
proto.jp.singcolor.FollowRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.FollowRequest;
    return proto.jp.singcolor.FollowRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.FollowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.FollowRequest}
 */
proto.jp.singcolor.FollowRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.FollowRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.FollowRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.FollowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.FollowRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.FollowRequest.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.FollowRequest} returns this
 */
proto.jp.singcolor.FollowRequest.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.FollowResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.FollowResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.FollowResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.FollowResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserFollow: (f = msg.getTargetUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            targetUserFollowCount: (f = msg.getTargetUserFollowCount()) && users_entity_pb.UserFollowCount.toObject(includeInstance, f),
            userFollowCount: (f = msg.getUserFollowCount()) && users_entity_pb.UserFollowCount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.FollowResponse}
 */
proto.jp.singcolor.FollowResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.FollowResponse;
    return proto.jp.singcolor.FollowResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.FollowResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.FollowResponse}
 */
proto.jp.singcolor.FollowResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setTargetUserFollow(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollowCount;
                reader.readMessage(value, users_entity_pb.UserFollowCount.deserializeBinaryFromReader);
                msg.setTargetUserFollowCount(value);
                break;
            case 3:
                var value = new users_entity_pb.UserFollowCount;
                reader.readMessage(value, users_entity_pb.UserFollowCount.deserializeBinaryFromReader);
                msg.setUserFollowCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.FollowResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.FollowResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.FollowResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.FollowResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserFollow();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getTargetUserFollowCount();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollowCount.serializeBinaryToWriter);
    }
    f = message.getUserFollowCount();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserFollowCount.serializeBinaryToWriter);
    }
};
/**
 * optional UserFollow target_user_follow = 1;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.FollowResponse.prototype.getTargetUserFollow = function () {
    return /** @type{?proto.jp.singcolor.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 1));
};
/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.FollowResponse} returns this
*/
proto.jp.singcolor.FollowResponse.prototype.setTargetUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.FollowResponse} returns this
 */
proto.jp.singcolor.FollowResponse.prototype.clearTargetUserFollow = function () {
    return this.setTargetUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.FollowResponse.prototype.hasTargetUserFollow = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollowCount target_user_follow_count = 2;
 * @return {?proto.jp.singcolor.UserFollowCount}
 */
proto.jp.singcolor.FollowResponse.prototype.getTargetUserFollowCount = function () {
    return /** @type{?proto.jp.singcolor.UserFollowCount} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollowCount, 2));
};
/**
 * @param {?proto.jp.singcolor.UserFollowCount|undefined} value
 * @return {!proto.jp.singcolor.FollowResponse} returns this
*/
proto.jp.singcolor.FollowResponse.prototype.setTargetUserFollowCount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.FollowResponse} returns this
 */
proto.jp.singcolor.FollowResponse.prototype.clearTargetUserFollowCount = function () {
    return this.setTargetUserFollowCount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.FollowResponse.prototype.hasTargetUserFollowCount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserFollowCount user_follow_count = 3;
 * @return {?proto.jp.singcolor.UserFollowCount}
 */
proto.jp.singcolor.FollowResponse.prototype.getUserFollowCount = function () {
    return /** @type{?proto.jp.singcolor.UserFollowCount} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollowCount, 3));
};
/**
 * @param {?proto.jp.singcolor.UserFollowCount|undefined} value
 * @return {!proto.jp.singcolor.FollowResponse} returns this
*/
proto.jp.singcolor.FollowResponse.prototype.setUserFollowCount = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.FollowResponse} returns this
 */
proto.jp.singcolor.FollowResponse.prototype.clearUserFollowCount = function () {
    return this.setUserFollowCount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.FollowResponse.prototype.hasUserFollowCount = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnfollowRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnfollowRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnfollowRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnfollowRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnfollowRequest}
 */
proto.jp.singcolor.UnfollowRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnfollowRequest;
    return proto.jp.singcolor.UnfollowRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnfollowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnfollowRequest}
 */
proto.jp.singcolor.UnfollowRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnfollowRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnfollowRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnfollowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnfollowRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UnfollowRequest.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnfollowRequest} returns this
 */
proto.jp.singcolor.UnfollowRequest.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnfollowResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnfollowResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnfollowResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnfollowResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserFollow: (f = msg.getTargetUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            targetUserFollowCount: (f = msg.getTargetUserFollowCount()) && users_entity_pb.UserFollowCount.toObject(includeInstance, f),
            userFollowCount: (f = msg.getUserFollowCount()) && users_entity_pb.UserFollowCount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnfollowResponse}
 */
proto.jp.singcolor.UnfollowResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnfollowResponse;
    return proto.jp.singcolor.UnfollowResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnfollowResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnfollowResponse}
 */
proto.jp.singcolor.UnfollowResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setTargetUserFollow(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollowCount;
                reader.readMessage(value, users_entity_pb.UserFollowCount.deserializeBinaryFromReader);
                msg.setTargetUserFollowCount(value);
                break;
            case 3:
                var value = new users_entity_pb.UserFollowCount;
                reader.readMessage(value, users_entity_pb.UserFollowCount.deserializeBinaryFromReader);
                msg.setUserFollowCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnfollowResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnfollowResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnfollowResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnfollowResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserFollow();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getTargetUserFollowCount();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollowCount.serializeBinaryToWriter);
    }
    f = message.getUserFollowCount();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserFollowCount.serializeBinaryToWriter);
    }
};
/**
 * optional UserFollow target_user_follow = 1;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.UnfollowResponse.prototype.getTargetUserFollow = function () {
    return /** @type{?proto.jp.singcolor.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 1));
};
/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.UnfollowResponse} returns this
*/
proto.jp.singcolor.UnfollowResponse.prototype.setTargetUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UnfollowResponse} returns this
 */
proto.jp.singcolor.UnfollowResponse.prototype.clearTargetUserFollow = function () {
    return this.setTargetUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UnfollowResponse.prototype.hasTargetUserFollow = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollowCount target_user_follow_count = 2;
 * @return {?proto.jp.singcolor.UserFollowCount}
 */
proto.jp.singcolor.UnfollowResponse.prototype.getTargetUserFollowCount = function () {
    return /** @type{?proto.jp.singcolor.UserFollowCount} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollowCount, 2));
};
/**
 * @param {?proto.jp.singcolor.UserFollowCount|undefined} value
 * @return {!proto.jp.singcolor.UnfollowResponse} returns this
*/
proto.jp.singcolor.UnfollowResponse.prototype.setTargetUserFollowCount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UnfollowResponse} returns this
 */
proto.jp.singcolor.UnfollowResponse.prototype.clearTargetUserFollowCount = function () {
    return this.setTargetUserFollowCount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UnfollowResponse.prototype.hasTargetUserFollowCount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserFollowCount user_follow_count = 3;
 * @return {?proto.jp.singcolor.UserFollowCount}
 */
proto.jp.singcolor.UnfollowResponse.prototype.getUserFollowCount = function () {
    return /** @type{?proto.jp.singcolor.UserFollowCount} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollowCount, 3));
};
/**
 * @param {?proto.jp.singcolor.UserFollowCount|undefined} value
 * @return {!proto.jp.singcolor.UnfollowResponse} returns this
*/
proto.jp.singcolor.UnfollowResponse.prototype.setUserFollowCount = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UnfollowResponse} returns this
 */
proto.jp.singcolor.UnfollowResponse.prototype.clearUserFollowCount = function () {
    return this.setUserFollowCount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UnfollowResponse.prototype.hasUserFollowCount = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateFollowNotificationSettingRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateFollowNotificationSettingRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateFollowNotificationSettingRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateFollowNotificationSettingRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            enableNotification: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingRequest}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateFollowNotificationSettingRequest;
    return proto.jp.singcolor.UpdateFollowNotificationSettingRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateFollowNotificationSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingRequest}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setEnableNotification(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateFollowNotificationSettingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateFollowNotificationSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getEnableNotification();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingRequest} returns this
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool enable_notification = 2;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest.prototype.getEnableNotification = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingRequest} returns this
 */
proto.jp.singcolor.UpdateFollowNotificationSettingRequest.prototype.setEnableNotification = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateFollowNotificationSettingResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateFollowNotificationSettingResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserFollow: (f = msg.getTargetUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            targetUserFollowCount: (f = msg.getTargetUserFollowCount()) && users_entity_pb.UserFollowCount.toObject(includeInstance, f),
            userFollowCount: (f = msg.getUserFollowCount()) && users_entity_pb.UserFollowCount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateFollowNotificationSettingResponse;
    return proto.jp.singcolor.UpdateFollowNotificationSettingResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setTargetUserFollow(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollowCount;
                reader.readMessage(value, users_entity_pb.UserFollowCount.deserializeBinaryFromReader);
                msg.setTargetUserFollowCount(value);
                break;
            case 3:
                var value = new users_entity_pb.UserFollowCount;
                reader.readMessage(value, users_entity_pb.UserFollowCount.deserializeBinaryFromReader);
                msg.setUserFollowCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateFollowNotificationSettingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserFollow();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getTargetUserFollowCount();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollowCount.serializeBinaryToWriter);
    }
    f = message.getUserFollowCount();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserFollowCount.serializeBinaryToWriter);
    }
};
/**
 * optional UserFollow target_user_follow = 1;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.getTargetUserFollow = function () {
    return /** @type{?proto.jp.singcolor.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 1));
};
/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} returns this
*/
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.setTargetUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} returns this
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.clearTargetUserFollow = function () {
    return this.setTargetUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.hasTargetUserFollow = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollowCount target_user_follow_count = 2;
 * @return {?proto.jp.singcolor.UserFollowCount}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.getTargetUserFollowCount = function () {
    return /** @type{?proto.jp.singcolor.UserFollowCount} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollowCount, 2));
};
/**
 * @param {?proto.jp.singcolor.UserFollowCount|undefined} value
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} returns this
*/
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.setTargetUserFollowCount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} returns this
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.clearTargetUserFollowCount = function () {
    return this.setTargetUserFollowCount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.hasTargetUserFollowCount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserFollowCount user_follow_count = 3;
 * @return {?proto.jp.singcolor.UserFollowCount}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.getUserFollowCount = function () {
    return /** @type{?proto.jp.singcolor.UserFollowCount} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollowCount, 3));
};
/**
 * @param {?proto.jp.singcolor.UserFollowCount|undefined} value
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} returns this
*/
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.setUserFollowCount = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateFollowNotificationSettingResponse} returns this
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.clearUserFollowCount = function () {
    return this.setUserFollowCount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateFollowNotificationSettingResponse.prototype.hasUserFollowCount = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFollowersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFollowersRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFollowersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFollowersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: jspb.Message.getFieldWithDefault(msg, 2, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFollowersRequest}
 */
proto.jp.singcolor.ListFollowersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFollowersRequest;
    return proto.jp.singcolor.ListFollowersRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFollowersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFollowersRequest}
 */
proto.jp.singcolor.ListFollowersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFollowersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFollowersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFollowersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFollowersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListFollowersRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFollowersRequest} returns this
 */
proto.jp.singcolor.ListFollowersRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFollowersRequest.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowersRequest} returns this
 */
proto.jp.singcolor.ListFollowersRequest.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 limit = 3;
 * @return {number}
 */
proto.jp.singcolor.ListFollowersRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowersRequest} returns this
 */
proto.jp.singcolor.ListFollowersRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListFollowersResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFollowersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFollowersResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFollowersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFollowersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            followersList: jspb.Message.toObjectList(msg.getFollowersList(), users_entity_pb.Follower.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFollowersResponse}
 */
proto.jp.singcolor.ListFollowersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFollowersResponse;
    return proto.jp.singcolor.ListFollowersResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFollowersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFollowersResponse}
 */
proto.jp.singcolor.ListFollowersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Follower;
                reader.readMessage(value, users_entity_pb.Follower.deserializeBinaryFromReader);
                msg.addFollowers(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFollowersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFollowersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFollowersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFollowersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFollowersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.Follower.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated Follower followers = 1;
 * @return {!Array<!proto.jp.singcolor.Follower>}
 */
proto.jp.singcolor.ListFollowersResponse.prototype.getFollowersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Follower>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.Follower, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Follower>} value
 * @return {!proto.jp.singcolor.ListFollowersResponse} returns this
*/
proto.jp.singcolor.ListFollowersResponse.prototype.setFollowersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Follower=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Follower}
 */
proto.jp.singcolor.ListFollowersResponse.prototype.addFollowers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Follower, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFollowersResponse} returns this
 */
proto.jp.singcolor.ListFollowersResponse.prototype.clearFollowersList = function () {
    return this.setFollowersList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFollowersResponse.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowersResponse} returns this
 */
proto.jp.singcolor.ListFollowersResponse.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListFollowersResponse.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowersResponse} returns this
 */
proto.jp.singcolor.ListFollowersResponse.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListFollowersResponse.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowersResponse} returns this
 */
proto.jp.singcolor.ListFollowersResponse.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListFollowersResponse.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFollowersResponse} returns this
 */
proto.jp.singcolor.ListFollowersResponse.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListFollowersResponse.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListFollowersResponse} returns this
 */
proto.jp.singcolor.ListFollowersResponse.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBlockingsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBlockingsRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBlockingsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBlockingsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: jspb.Message.getFieldWithDefault(msg, 1, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBlockingsRequest}
 */
proto.jp.singcolor.ListBlockingsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBlockingsRequest;
    return proto.jp.singcolor.ListBlockingsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBlockingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBlockingsRequest}
 */
proto.jp.singcolor.ListBlockingsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBlockingsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBlockingsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBlockingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBlockingsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.jp.singcolor.ListBlockingsRequest.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListBlockingsRequest} returns this
 */
proto.jp.singcolor.ListBlockingsRequest.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListBlockingsRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListBlockingsRequest} returns this
 */
proto.jp.singcolor.ListBlockingsRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListBlockingsResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBlockingsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBlockingsResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBlockingsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBlockingsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            blockingsList: jspb.Message.toObjectList(msg.getBlockingsList(), users_entity_pb.Blocking.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBlockingsResponse}
 */
proto.jp.singcolor.ListBlockingsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBlockingsResponse;
    return proto.jp.singcolor.ListBlockingsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBlockingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBlockingsResponse}
 */
proto.jp.singcolor.ListBlockingsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Blocking;
                reader.readMessage(value, users_entity_pb.Blocking.deserializeBinaryFromReader);
                msg.addBlockings(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBlockingsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBlockingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBlockingsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBlockingsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.Blocking.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated Blocking blockings = 1;
 * @return {!Array<!proto.jp.singcolor.Blocking>}
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.getBlockingsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Blocking>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.Blocking, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Blocking>} value
 * @return {!proto.jp.singcolor.ListBlockingsResponse} returns this
*/
proto.jp.singcolor.ListBlockingsResponse.prototype.setBlockingsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Blocking=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Blocking}
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.addBlockings = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Blocking, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListBlockingsResponse} returns this
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.clearBlockingsList = function () {
    return this.setBlockingsList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListBlockingsResponse} returns this
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListBlockingsResponse} returns this
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListBlockingsResponse} returns this
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListBlockingsResponse} returns this
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListBlockingsResponse} returns this
 */
proto.jp.singcolor.ListBlockingsResponse.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DoBlockRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DoBlockRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DoBlockRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DoBlockRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DoBlockRequest}
 */
proto.jp.singcolor.DoBlockRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DoBlockRequest;
    return proto.jp.singcolor.DoBlockRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DoBlockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DoBlockRequest}
 */
proto.jp.singcolor.DoBlockRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DoBlockRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DoBlockRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DoBlockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DoBlockRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DoBlockRequest.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DoBlockRequest} returns this
 */
proto.jp.singcolor.DoBlockRequest.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DoBlockResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DoBlockResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DoBlockResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DoBlockResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBlock: (f = msg.getUserBlock()) && users_entity_pb.UserBlock.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DoBlockResponse}
 */
proto.jp.singcolor.DoBlockResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DoBlockResponse;
    return proto.jp.singcolor.DoBlockResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DoBlockResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DoBlockResponse}
 */
proto.jp.singcolor.DoBlockResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserBlock;
                reader.readMessage(value, users_entity_pb.UserBlock.deserializeBinaryFromReader);
                msg.setUserBlock(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DoBlockResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DoBlockResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DoBlockResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DoBlockResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBlock();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserBlock.serializeBinaryToWriter);
    }
};
/**
 * optional UserBlock user_block = 1;
 * @return {?proto.jp.singcolor.UserBlock}
 */
proto.jp.singcolor.DoBlockResponse.prototype.getUserBlock = function () {
    return /** @type{?proto.jp.singcolor.UserBlock} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBlock, 1));
};
/**
 * @param {?proto.jp.singcolor.UserBlock|undefined} value
 * @return {!proto.jp.singcolor.DoBlockResponse} returns this
*/
proto.jp.singcolor.DoBlockResponse.prototype.setUserBlock = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DoBlockResponse} returns this
 */
proto.jp.singcolor.DoBlockResponse.prototype.clearUserBlock = function () {
    return this.setUserBlock(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DoBlockResponse.prototype.hasUserBlock = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnblockRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnblockRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnblockRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnblockRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnblockRequest}
 */
proto.jp.singcolor.UnblockRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnblockRequest;
    return proto.jp.singcolor.UnblockRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnblockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnblockRequest}
 */
proto.jp.singcolor.UnblockRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnblockRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnblockRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnblockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnblockRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UnblockRequest.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnblockRequest} returns this
 */
proto.jp.singcolor.UnblockRequest.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnblockResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnblockResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnblockResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnblockResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBlock: (f = msg.getUserBlock()) && users_entity_pb.UserBlock.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnblockResponse}
 */
proto.jp.singcolor.UnblockResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnblockResponse;
    return proto.jp.singcolor.UnblockResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnblockResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnblockResponse}
 */
proto.jp.singcolor.UnblockResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserBlock;
                reader.readMessage(value, users_entity_pb.UserBlock.deserializeBinaryFromReader);
                msg.setUserBlock(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnblockResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnblockResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnblockResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnblockResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBlock();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserBlock.serializeBinaryToWriter);
    }
};
/**
 * optional UserBlock user_block = 1;
 * @return {?proto.jp.singcolor.UserBlock}
 */
proto.jp.singcolor.UnblockResponse.prototype.getUserBlock = function () {
    return /** @type{?proto.jp.singcolor.UserBlock} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBlock, 1));
};
/**
 * @param {?proto.jp.singcolor.UserBlock|undefined} value
 * @return {!proto.jp.singcolor.UnblockResponse} returns this
*/
proto.jp.singcolor.UnblockResponse.prototype.setUserBlock = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UnblockResponse} returns this
 */
proto.jp.singcolor.UnblockResponse.prototype.clearUserBlock = function () {
    return this.setUserBlock(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UnblockResponse.prototype.hasUserBlock = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserProfileV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserProfileV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserProfileV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserProfileV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserProfileV1Request}
 */
proto.jp.singcolor.GetUserProfileV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserProfileV1Request;
    return proto.jp.singcolor.GetUserProfileV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserProfileV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserProfileV1Request}
 */
proto.jp.singcolor.GetUserProfileV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserProfileV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserProfileV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserProfileV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserProfileV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserProfileV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserProfileV1Request} returns this
 */
proto.jp.singcolor.GetUserProfileV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetUserProfileV1Response.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserProfileV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserProfileV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserProfileV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserProfileV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            userProfile: (f = msg.getUserProfile()) && users_entity_pb.UserProfile.toObject(includeInstance, f),
            singersList: jspb.Message.toObjectList(msg.getSingersList(), karaoke_entity_pb.Singer.toObject, includeInstance),
            userfollow: (f = msg.getUserfollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            userfollowcount: (f = msg.getUserfollowcount()) && users_entity_pb.UserFollowCount.toObject(includeInstance, f),
            userblock: (f = msg.getUserblock()) && users_entity_pb.UserBlock.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserProfileV1Response}
 */
proto.jp.singcolor.GetUserProfileV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserProfileV1Response;
    return proto.jp.singcolor.GetUserProfileV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserProfileV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserProfileV1Response}
 */
proto.jp.singcolor.GetUserProfileV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = new users_entity_pb.UserProfile;
                reader.readMessage(value, users_entity_pb.UserProfile.deserializeBinaryFromReader);
                msg.setUserProfile(value);
                break;
            case 3:
                var value = new karaoke_entity_pb.Singer;
                reader.readMessage(value, karaoke_entity_pb.Singer.deserializeBinaryFromReader);
                msg.addSingers(value);
                break;
            case 4:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserfollow(value);
                break;
            case 5:
                var value = new users_entity_pb.UserFollowCount;
                reader.readMessage(value, users_entity_pb.UserFollowCount.deserializeBinaryFromReader);
                msg.setUserfollowcount(value);
                break;
            case 6:
                var value = new users_entity_pb.UserBlock;
                reader.readMessage(value, users_entity_pb.UserBlock.deserializeBinaryFromReader);
                msg.setUserblock(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserProfileV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserProfileV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserProfileV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getUserProfile();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserProfile.serializeBinaryToWriter);
    }
    f = message.getSingersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, karaoke_entity_pb.Singer.serializeBinaryToWriter);
    }
    f = message.getUserfollow();
    if (f != null) {
        writer.writeMessage(4, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getUserfollowcount();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.UserFollowCount.serializeBinaryToWriter);
    }
    f = message.getUserblock();
    if (f != null) {
        writer.writeMessage(6, f, users_entity_pb.UserBlock.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
*/
proto.jp.singcolor.GetUserProfileV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserProfile user_profile = 2;
 * @return {?proto.jp.singcolor.UserProfile}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.getUserProfile = function () {
    return /** @type{?proto.jp.singcolor.UserProfile} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserProfile, 2));
};
/**
 * @param {?proto.jp.singcolor.UserProfile|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
*/
proto.jp.singcolor.GetUserProfileV1Response.prototype.setUserProfile = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.clearUserProfile = function () {
    return this.setUserProfile(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.hasUserProfile = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * repeated Singer singers = 3;
 * @return {!Array<!proto.jp.singcolor.Singer>}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.getSingersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Singer>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Singer, 3));
};
/**
 * @param {!Array<!proto.jp.singcolor.Singer>} value
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
*/
proto.jp.singcolor.GetUserProfileV1Response.prototype.setSingersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.jp.singcolor.Singer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.addSingers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.Singer, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.clearSingersList = function () {
    return this.setSingersList([]);
};
/**
 * optional UserFollow userFollow = 4;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.getUserfollow = function () {
    return /** @type{?proto.jp.singcolor.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 4));
};
/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
*/
proto.jp.singcolor.GetUserProfileV1Response.prototype.setUserfollow = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.clearUserfollow = function () {
    return this.setUserfollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.hasUserfollow = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional UserFollowCount userFollowCount = 5;
 * @return {?proto.jp.singcolor.UserFollowCount}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.getUserfollowcount = function () {
    return /** @type{?proto.jp.singcolor.UserFollowCount} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollowCount, 5));
};
/**
 * @param {?proto.jp.singcolor.UserFollowCount|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
*/
proto.jp.singcolor.GetUserProfileV1Response.prototype.setUserfollowcount = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.clearUserfollowcount = function () {
    return this.setUserfollowcount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.hasUserfollowcount = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional UserBlock userBlock = 6;
 * @return {?proto.jp.singcolor.UserBlock}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.getUserblock = function () {
    return /** @type{?proto.jp.singcolor.UserBlock} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBlock, 6));
};
/**
 * @param {?proto.jp.singcolor.UserBlock|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
*/
proto.jp.singcolor.GetUserProfileV1Response.prototype.setUserblock = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV1Response} returns this
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.clearUserblock = function () {
    return this.setUserblock(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV1Response.prototype.hasUserblock = function () {
    return jspb.Message.getField(this, 6) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserProfileV2Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserProfileV2Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserProfileV2Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserProfileV2Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserProfileV2Request}
 */
proto.jp.singcolor.GetUserProfileV2Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserProfileV2Request;
    return proto.jp.singcolor.GetUserProfileV2Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserProfileV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserProfileV2Request}
 */
proto.jp.singcolor.GetUserProfileV2Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserProfileV2Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserProfileV2Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserProfileV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserProfileV2Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserProfileV2Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Request} returns this
 */
proto.jp.singcolor.GetUserProfileV2Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetUserProfileV2Response.repeatedFields_ = [4];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserProfileV2Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserProfileV2Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserProfileV2Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserProfileV2Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            userFollowCount: (f = msg.getUserFollowCount()) && users_entity_pb.UserFollowCount.toObject(includeInstance, f),
            singersList: jspb.Message.toObjectList(msg.getSingersList(), karaoke_entity_pb.Singer.toObject, includeInstance),
            userBlock: (f = msg.getUserBlock()) && users_entity_pb.UserBlock.toObject(includeInstance, f),
            userWithdraw: (f = msg.getUserWithdraw()) && users_entity_pb.UserWithdraw.toObject(includeInstance, f),
            isLSinger: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            onAirLiveId: jspb.Message.getFieldWithDefault(msg, 8, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserProfileV2Response}
 */
proto.jp.singcolor.GetUserProfileV2Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserProfileV2Response;
    return proto.jp.singcolor.GetUserProfileV2Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserProfileV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserProfileV2Response}
 */
proto.jp.singcolor.GetUserProfileV2Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserFollow(value);
                break;
            case 3:
                var value = new users_entity_pb.UserFollowCount;
                reader.readMessage(value, users_entity_pb.UserFollowCount.deserializeBinaryFromReader);
                msg.setUserFollowCount(value);
                break;
            case 4:
                var value = new karaoke_entity_pb.Singer;
                reader.readMessage(value, karaoke_entity_pb.Singer.deserializeBinaryFromReader);
                msg.addSingers(value);
                break;
            case 5:
                var value = new users_entity_pb.UserBlock;
                reader.readMessage(value, users_entity_pb.UserBlock.deserializeBinaryFromReader);
                msg.setUserBlock(value);
                break;
            case 6:
                var value = new users_entity_pb.UserWithdraw;
                reader.readMessage(value, users_entity_pb.UserWithdraw.deserializeBinaryFromReader);
                msg.setUserWithdraw(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsLSinger(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setOnAirLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserProfileV2Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserProfileV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserProfileV2Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getUserFollow();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getUserFollowCount();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserFollowCount.serializeBinaryToWriter);
    }
    f = message.getSingersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, karaoke_entity_pb.Singer.serializeBinaryToWriter);
    }
    f = message.getUserBlock();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.UserBlock.serializeBinaryToWriter);
    }
    f = message.getUserWithdraw();
    if (f != null) {
        writer.writeMessage(6, f, users_entity_pb.UserWithdraw.serializeBinaryToWriter);
    }
    f = message.getIsLSinger();
    if (f) {
        writer.writeBool(7, f);
    }
    f = message.getOnAirLiveId();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
};
/**
 * optional UserItem user_item = 1;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.getUserItem = function () {
    return /** @type{?proto.jp.singcolor.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
*/
proto.jp.singcolor.GetUserProfileV2Response.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.getUserFollow = function () {
    return /** @type{?proto.jp.singcolor.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};
/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
*/
proto.jp.singcolor.GetUserProfileV2Response.prototype.setUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.clearUserFollow = function () {
    return this.setUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.hasUserFollow = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserFollowCount user_follow_count = 3;
 * @return {?proto.jp.singcolor.UserFollowCount}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.getUserFollowCount = function () {
    return /** @type{?proto.jp.singcolor.UserFollowCount} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollowCount, 3));
};
/**
 * @param {?proto.jp.singcolor.UserFollowCount|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
*/
proto.jp.singcolor.GetUserProfileV2Response.prototype.setUserFollowCount = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.clearUserFollowCount = function () {
    return this.setUserFollowCount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.hasUserFollowCount = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * repeated Singer singers = 4;
 * @return {!Array<!proto.jp.singcolor.Singer>}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.getSingersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Singer>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Singer, 4));
};
/**
 * @param {!Array<!proto.jp.singcolor.Singer>} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
*/
proto.jp.singcolor.GetUserProfileV2Response.prototype.setSingersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.jp.singcolor.Singer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.addSingers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jp.singcolor.Singer, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.clearSingersList = function () {
    return this.setSingersList([]);
};
/**
 * optional UserBlock user_block = 5;
 * @return {?proto.jp.singcolor.UserBlock}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.getUserBlock = function () {
    return /** @type{?proto.jp.singcolor.UserBlock} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBlock, 5));
};
/**
 * @param {?proto.jp.singcolor.UserBlock|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
*/
proto.jp.singcolor.GetUserProfileV2Response.prototype.setUserBlock = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.clearUserBlock = function () {
    return this.setUserBlock(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.hasUserBlock = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional UserWithdraw user_withdraw = 6;
 * @return {?proto.jp.singcolor.UserWithdraw}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.getUserWithdraw = function () {
    return /** @type{?proto.jp.singcolor.UserWithdraw} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserWithdraw, 6));
};
/**
 * @param {?proto.jp.singcolor.UserWithdraw|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
*/
proto.jp.singcolor.GetUserProfileV2Response.prototype.setUserWithdraw = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.clearUserWithdraw = function () {
    return this.setUserWithdraw(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.hasUserWithdraw = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional bool is_l_singer = 7;
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.getIsLSinger = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.setIsLSinger = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};
/**
 * optional string on_air_live_id = 8;
 * @return {string}
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.getOnAirLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserProfileV2Response} returns this
 */
proto.jp.singcolor.GetUserProfileV2Response.prototype.setOnAirLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserSettingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserSettingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserSettingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserSettingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserSettingV1Request}
 */
proto.jp.singcolor.GetUserSettingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserSettingV1Request;
    return proto.jp.singcolor.GetUserSettingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserSettingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserSettingV1Request}
 */
proto.jp.singcolor.GetUserSettingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserSettingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserSettingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserSettingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserSettingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetUserSettingV1Response.repeatedFields_ = [5];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserSettingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserSettingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserSettingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserSettingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            userBirthday: (f = msg.getUserBirthday()) && users_entity_pb.UserBirthday.toObject(includeInstance, f),
            userProfile: (f = msg.getUserProfile()) && users_entity_pb.UserProfile.toObject(includeInstance, f),
            userLiveSetting: (f = msg.getUserLiveSetting()) && lives_entity_pb.UserLiveSetting.toObject(includeInstance, f),
            singersList: jspb.Message.toObjectList(msg.getSingersList(), karaoke_entity_pb.Singer.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserSettingV1Response}
 */
proto.jp.singcolor.GetUserSettingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserSettingV1Response;
    return proto.jp.singcolor.GetUserSettingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserSettingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserSettingV1Response}
 */
proto.jp.singcolor.GetUserSettingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = new users_entity_pb.UserBirthday;
                reader.readMessage(value, users_entity_pb.UserBirthday.deserializeBinaryFromReader);
                msg.setUserBirthday(value);
                break;
            case 3:
                var value = new users_entity_pb.UserProfile;
                reader.readMessage(value, users_entity_pb.UserProfile.deserializeBinaryFromReader);
                msg.setUserProfile(value);
                break;
            case 4:
                var value = new lives_entity_pb.UserLiveSetting;
                reader.readMessage(value, lives_entity_pb.UserLiveSetting.deserializeBinaryFromReader);
                msg.setUserLiveSetting(value);
                break;
            case 5:
                var value = new karaoke_entity_pb.Singer;
                reader.readMessage(value, karaoke_entity_pb.Singer.deserializeBinaryFromReader);
                msg.addSingers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserSettingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserSettingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserSettingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getUserBirthday();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserBirthday.serializeBinaryToWriter);
    }
    f = message.getUserProfile();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserProfile.serializeBinaryToWriter);
    }
    f = message.getUserLiveSetting();
    if (f != null) {
        writer.writeMessage(4, f, lives_entity_pb.UserLiveSetting.serializeBinaryToWriter);
    }
    f = message.getSingersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(5, f, karaoke_entity_pb.Singer.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
*/
proto.jp.singcolor.GetUserSettingV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserBirthday user_birthday = 2;
 * @return {?proto.jp.singcolor.UserBirthday}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.getUserBirthday = function () {
    return /** @type{?proto.jp.singcolor.UserBirthday} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBirthday, 2));
};
/**
 * @param {?proto.jp.singcolor.UserBirthday|undefined} value
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
*/
proto.jp.singcolor.GetUserSettingV1Response.prototype.setUserBirthday = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.clearUserBirthday = function () {
    return this.setUserBirthday(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.hasUserBirthday = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserProfile user_profile = 3;
 * @return {?proto.jp.singcolor.UserProfile}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.getUserProfile = function () {
    return /** @type{?proto.jp.singcolor.UserProfile} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserProfile, 3));
};
/**
 * @param {?proto.jp.singcolor.UserProfile|undefined} value
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
*/
proto.jp.singcolor.GetUserSettingV1Response.prototype.setUserProfile = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.clearUserProfile = function () {
    return this.setUserProfile(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.hasUserProfile = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional UserLiveSetting user_live_setting = 4;
 * @return {?proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.getUserLiveSetting = function () {
    return /** @type{?proto.jp.singcolor.UserLiveSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.UserLiveSetting, 4));
};
/**
 * @param {?proto.jp.singcolor.UserLiveSetting|undefined} value
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
*/
proto.jp.singcolor.GetUserSettingV1Response.prototype.setUserLiveSetting = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.clearUserLiveSetting = function () {
    return this.setUserLiveSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.hasUserLiveSetting = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * repeated Singer singers = 5;
 * @return {!Array<!proto.jp.singcolor.Singer>}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.getSingersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Singer>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Singer, 5));
};
/**
 * @param {!Array<!proto.jp.singcolor.Singer>} value
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
*/
proto.jp.singcolor.GetUserSettingV1Response.prototype.setSingersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 5, value);
};
/**
 * @param {!proto.jp.singcolor.Singer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.addSingers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.jp.singcolor.Singer, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetUserSettingV1Response} returns this
 */
proto.jp.singcolor.GetUserSettingV1Response.prototype.clearSingersList = function () {
    return this.setSingersList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.UpdateUserSettingV1Request.repeatedFields_ = [18];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserSettingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserSettingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserSettingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserSettingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            name: jspb.Message.getFieldWithDefault(msg, 1, ""),
            profileImage: msg.getProfileImage_asB64(),
            gender: jspb.Message.getFieldWithDefault(msg, 3, 0),
            bio: jspb.Message.getFieldWithDefault(msg, 4, ""),
            url: jspb.Message.getFieldWithDefault(msg, 5, ""),
            twitterId: jspb.Message.getFieldWithDefault(msg, 6, ""),
            tiktokId: jspb.Message.getFieldWithDefault(msg, 7, ""),
            youtubeHandle: jspb.Message.getFieldWithDefault(msg, 8, ""),
            instagramId: jspb.Message.getFieldWithDefault(msg, 9, ""),
            tag1: jspb.Message.getFieldWithDefault(msg, 10, ""),
            tag2: jspb.Message.getFieldWithDefault(msg, 11, ""),
            tag3: jspb.Message.getFieldWithDefault(msg, 12, ""),
            prefectureId: jspb.Message.getFieldWithDefault(msg, 13, 0),
            townId: jspb.Message.getFieldWithDefault(msg, 14, 0),
            radioMode: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
            closingTimeJst: jspb.Message.getFieldWithDefault(msg, 16, 0),
            switchClosingTimeDay: jspb.Message.getFieldWithDefault(msg, 17, ""),
            singerIdsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
            isGenderHidden: jspb.Message.getBooleanFieldWithDefault(msg, 19, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserSettingV1Request;
    return proto.jp.singcolor.UpdateUserSettingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserSettingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setProfileImage(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setGender(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setBio(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setUrl(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setTwitterId(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setTiktokId(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setYoutubeHandle(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setInstagramId(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setTag1(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.setTag2(value);
                break;
            case 12:
                var value = /** @type {string} */ (reader.readString());
                msg.setTag3(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPrefectureId(value);
                break;
            case 14:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTownId(value);
                break;
            case 15:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setRadioMode(value);
                break;
            case 16:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setClosingTimeJst(value);
                break;
            case 17:
                var value = /** @type {string} */ (reader.readString());
                msg.setSwitchClosingTimeDay(value);
                break;
            case 18:
                var value = /** @type {string} */ (reader.readString());
                msg.addSingerIds(value);
                break;
            case 19:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsGenderHidden(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserSettingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserSettingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserSettingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getProfileImage_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getGender();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getBio();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getTwitterId();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getTiktokId();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getYoutubeHandle();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getInstagramId();
    if (f.length > 0) {
        writer.writeString(9, f);
    }
    f = message.getTag1();
    if (f.length > 0) {
        writer.writeString(10, f);
    }
    f = message.getTag2();
    if (f.length > 0) {
        writer.writeString(11, f);
    }
    f = message.getTag3();
    if (f.length > 0) {
        writer.writeString(12, f);
    }
    f = message.getPrefectureId();
    if (f !== 0) {
        writer.writeInt64(13, f);
    }
    f = message.getTownId();
    if (f !== 0) {
        writer.writeInt64(14, f);
    }
    f = message.getRadioMode();
    if (f) {
        writer.writeBool(15, f);
    }
    f = message.getClosingTimeJst();
    if (f !== 0) {
        writer.writeInt64(16, f);
    }
    f = message.getSwitchClosingTimeDay();
    if (f.length > 0) {
        writer.writeString(17, f);
    }
    f = message.getSingerIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(18, f);
    }
    f = message.getIsGenderHidden();
    if (f) {
        writer.writeBool(19, f);
    }
};
/**
 * optional string name = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bytes profile_image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getProfileImage = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * optional bytes profile_image = 2;
 * This is a type-conversion wrapper around `getProfileImage()`
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getProfileImage_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getProfileImage()));
};
/**
 * optional bytes profile_image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getProfileImage()`
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getProfileImage_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getProfileImage()));
};
/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setProfileImage = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};
/**
 * optional Gender gender = 3;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string bio = 4;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getBio = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setBio = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string url = 5;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string twitter_id = 6;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getTwitterId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setTwitterId = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string tiktok_id = 7;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getTiktokId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setTiktokId = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string youtube_handle = 8;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getYoutubeHandle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setYoutubeHandle = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional string instagram_id = 9;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getInstagramId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setInstagramId = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional string tag1 = 10;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getTag1 = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setTag1 = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * optional string tag2 = 11;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getTag2 = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setTag2 = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
};
/**
 * optional string tag3 = 12;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getTag3 = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setTag3 = function (value) {
    return jspb.Message.setProto3StringField(this, 12, value);
};
/**
 * optional int64 prefecture_id = 13;
 * @return {number}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getPrefectureId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setPrefectureId = function (value) {
    return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * optional int64 town_id = 14;
 * @return {number}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getTownId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setTownId = function (value) {
    return jspb.Message.setProto3IntField(this, 14, value);
};
/**
 * optional bool radio_mode = 15;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getRadioMode = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setRadioMode = function (value) {
    return jspb.Message.setProto3BooleanField(this, 15, value);
};
/**
 * optional int64 closing_time_jst = 16;
 * @return {number}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getClosingTimeJst = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setClosingTimeJst = function (value) {
    return jspb.Message.setProto3IntField(this, 16, value);
};
/**
 * optional string switch_closing_time_day = 17;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getSwitchClosingTimeDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setSwitchClosingTimeDay = function (value) {
    return jspb.Message.setProto3StringField(this, 17, value);
};
/**
 * repeated string singer_ids = 18;
 * @return {!Array<string>}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getSingerIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setSingerIdsList = function (value) {
    return jspb.Message.setField(this, 18, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.addSingerIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.clearSingerIdsList = function () {
    return this.setSingerIdsList([]);
};
/**
 * optional bool is_gender_hidden = 19;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.getIsGenderHidden = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Request} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Request.prototype.setIsGenderHidden = function (value) {
    return jspb.Message.setProto3BooleanField(this, 19, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.UpdateUserSettingV1Response.repeatedFields_ = [5];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserSettingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserSettingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserSettingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserSettingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            userProfile: (f = msg.getUserProfile()) && users_entity_pb.UserProfile.toObject(includeInstance, f),
            userLiveClosingTime: (f = msg.getUserLiveClosingTime()) && users_entity_pb.UserLiveClosingTime.toObject(includeInstance, f),
            userLiveSetting: (f = msg.getUserLiveSetting()) && lives_entity_pb.UserLiveSetting.toObject(includeInstance, f),
            singersList: jspb.Message.toObjectList(msg.getSingersList(), karaoke_entity_pb.Singer.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserSettingV1Response;
    return proto.jp.singcolor.UpdateUserSettingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserSettingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = new users_entity_pb.UserProfile;
                reader.readMessage(value, users_entity_pb.UserProfile.deserializeBinaryFromReader);
                msg.setUserProfile(value);
                break;
            case 3:
                var value = new users_entity_pb.UserLiveClosingTime;
                reader.readMessage(value, users_entity_pb.UserLiveClosingTime.deserializeBinaryFromReader);
                msg.setUserLiveClosingTime(value);
                break;
            case 4:
                var value = new lives_entity_pb.UserLiveSetting;
                reader.readMessage(value, lives_entity_pb.UserLiveSetting.deserializeBinaryFromReader);
                msg.setUserLiveSetting(value);
                break;
            case 5:
                var value = new karaoke_entity_pb.Singer;
                reader.readMessage(value, karaoke_entity_pb.Singer.deserializeBinaryFromReader);
                msg.addSingers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserSettingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserSettingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserSettingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getUserProfile();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserProfile.serializeBinaryToWriter);
    }
    f = message.getUserLiveClosingTime();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserLiveClosingTime.serializeBinaryToWriter);
    }
    f = message.getUserLiveSetting();
    if (f != null) {
        writer.writeMessage(4, f, lives_entity_pb.UserLiveSetting.serializeBinaryToWriter);
    }
    f = message.getSingersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(5, f, karaoke_entity_pb.Singer.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
*/
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserProfile user_profile = 2;
 * @return {?proto.jp.singcolor.UserProfile}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.getUserProfile = function () {
    return /** @type{?proto.jp.singcolor.UserProfile} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserProfile, 2));
};
/**
 * @param {?proto.jp.singcolor.UserProfile|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
*/
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.setUserProfile = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.clearUserProfile = function () {
    return this.setUserProfile(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.hasUserProfile = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserLiveClosingTime user_live_closing_time = 3;
 * @return {?proto.jp.singcolor.UserLiveClosingTime}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.getUserLiveClosingTime = function () {
    return /** @type{?proto.jp.singcolor.UserLiveClosingTime} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserLiveClosingTime, 3));
};
/**
 * @param {?proto.jp.singcolor.UserLiveClosingTime|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
*/
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.setUserLiveClosingTime = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.clearUserLiveClosingTime = function () {
    return this.setUserLiveClosingTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.hasUserLiveClosingTime = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional UserLiveSetting user_live_setting = 4;
 * @return {?proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.getUserLiveSetting = function () {
    return /** @type{?proto.jp.singcolor.UserLiveSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.UserLiveSetting, 4));
};
/**
 * @param {?proto.jp.singcolor.UserLiveSetting|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
*/
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.setUserLiveSetting = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.clearUserLiveSetting = function () {
    return this.setUserLiveSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.hasUserLiveSetting = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * repeated Singer singers = 5;
 * @return {!Array<!proto.jp.singcolor.Singer>}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.getSingersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Singer>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Singer, 5));
};
/**
 * @param {!Array<!proto.jp.singcolor.Singer>} value
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
*/
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.setSingersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 5, value);
};
/**
 * @param {!proto.jp.singcolor.Singer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.addSingers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.jp.singcolor.Singer, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.UpdateUserSettingV1Response} returns this
 */
proto.jp.singcolor.UpdateUserSettingV1Response.prototype.clearSingersList = function () {
    return this.setSingersList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateDeviceV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateDeviceV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateDeviceV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateDeviceV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            registrationToken: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateDeviceV1Request}
 */
proto.jp.singcolor.UpdateDeviceV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateDeviceV1Request;
    return proto.jp.singcolor.UpdateDeviceV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateDeviceV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateDeviceV1Request}
 */
proto.jp.singcolor.UpdateDeviceV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setRegistrationToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateDeviceV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateDeviceV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateDeviceV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateDeviceV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRegistrationToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string registration_token = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateDeviceV1Request.prototype.getRegistrationToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateDeviceV1Request} returns this
 */
proto.jp.singcolor.UpdateDeviceV1Request.prototype.setRegistrationToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetCoinBalanceV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetCoinBalanceV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetCoinBalanceV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetCoinBalanceV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Request}
 */
proto.jp.singcolor.GetCoinBalanceV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetCoinBalanceV1Request;
    return proto.jp.singcolor.GetCoinBalanceV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetCoinBalanceV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Request}
 */
proto.jp.singcolor.GetCoinBalanceV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetCoinBalanceV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetCoinBalanceV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetCoinBalanceV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetCoinBalanceV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetCoinBalanceV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Request} returns this
 */
proto.jp.singcolor.GetCoinBalanceV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetCoinBalanceV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetCoinBalanceV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetCoinBalanceV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetCoinBalanceV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            specificLiverCoin: (f = msg.getSpecificLiverCoin()) && coins_entity_pb.SpecificLiverCoin.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Response}
 */
proto.jp.singcolor.GetCoinBalanceV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetCoinBalanceV1Response;
    return proto.jp.singcolor.GetCoinBalanceV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetCoinBalanceV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Response}
 */
proto.jp.singcolor.GetCoinBalanceV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 2:
                var value = new coins_entity_pb.SpecificLiverCoin;
                reader.readMessage(value, coins_entity_pb.SpecificLiverCoin.deserializeBinaryFromReader);
                msg.setSpecificLiverCoin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetCoinBalanceV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetCoinBalanceV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetCoinBalanceV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getSpecificLiverCoin();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.SpecificLiverCoin.serializeBinaryToWriter);
    }
};
/**
 * optional CoinBalance coin_balance = 1;
 * @return {?proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.jp.singcolor.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 1));
};
/**
 * @param {?proto.jp.singcolor.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Response} returns this
*/
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Response} returns this
 */
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional SpecificLiverCoin specific_liver_coin = 2;
 * @return {?proto.jp.singcolor.SpecificLiverCoin}
 */
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.getSpecificLiverCoin = function () {
    return /** @type{?proto.jp.singcolor.SpecificLiverCoin} */ (jspb.Message.getWrapperField(this, coins_entity_pb.SpecificLiverCoin, 2));
};
/**
 * @param {?proto.jp.singcolor.SpecificLiverCoin|undefined} value
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Response} returns this
*/
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.setSpecificLiverCoin = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetCoinBalanceV1Response} returns this
 */
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.clearSpecificLiverCoin = function () {
    return this.setSpecificLiverCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetCoinBalanceV1Response.prototype.hasSpecificLiverCoin = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request;
    return proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string organization_liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.prototype.getOrganizationLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.prototype.setOrganizationLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f),
            totalRemainAccumulationDiamondFundAmount: (f = msg.getTotalRemainAccumulationDiamondFundAmount()) && diamonds_entity_pb.DiamondAmount.toObject(includeInstance, f),
            singDiamondAcquisitionRight: jspb.Message.getFieldWithDefault(msg, 3, ""),
            chatDiamondAcquisitionRight: jspb.Message.getFieldWithDefault(msg, 4, ""),
            nextAccumulationDiamondFundDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
            acquireAccumulationDiamondEndOfTermIfSatisfiedConditions: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
            acquirableAccumulationDiamondFundAmountIfSatisfiedConditions: (f = msg.getAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions()) && diamonds_entity_pb.DiamondAmount.toObject(includeInstance, f),
            condition1: (f = msg.getCondition1()) && users_entity_pb.AcquireDiamondCondition.toObject(includeInstance, f),
            condition2: (f = msg.getCondition2()) && users_entity_pb.AcquireDiamondCondition.toObject(includeInstance, f),
            condition3: (f = msg.getCondition3()) && users_entity_pb.AcquireDiamondCondition.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response;
    return proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondAmount;
                reader.readMessage(value, diamonds_entity_pb.DiamondAmount.deserializeBinaryFromReader);
                msg.setTotalRemainAccumulationDiamondFundAmount(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setSingDiamondAcquisitionRight(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setChatDiamondAcquisitionRight(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextAccumulationDiamondFundDate(value);
                break;
            case 10:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setAcquireAccumulationDiamondEndOfTermIfSatisfiedConditions(value);
                break;
            case 6:
                var value = new diamonds_entity_pb.DiamondAmount;
                reader.readMessage(value, diamonds_entity_pb.DiamondAmount.deserializeBinaryFromReader);
                msg.setAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions(value);
                break;
            case 7:
                var value = new users_entity_pb.AcquireDiamondCondition;
                reader.readMessage(value, users_entity_pb.AcquireDiamondCondition.deserializeBinaryFromReader);
                msg.setCondition1(value);
                break;
            case 8:
                var value = new users_entity_pb.AcquireDiamondCondition;
                reader.readMessage(value, users_entity_pb.AcquireDiamondCondition.deserializeBinaryFromReader);
                msg.setCondition2(value);
                break;
            case 9:
                var value = new users_entity_pb.AcquireDiamondCondition;
                reader.readMessage(value, users_entity_pb.AcquireDiamondCondition.deserializeBinaryFromReader);
                msg.setCondition3(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(1, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
    f = message.getTotalRemainAccumulationDiamondFundAmount();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondAmount.serializeBinaryToWriter);
    }
    f = message.getSingDiamondAcquisitionRight();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getChatDiamondAcquisitionRight();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getNextAccumulationDiamondFundDate();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getAcquireAccumulationDiamondEndOfTermIfSatisfiedConditions();
    if (f) {
        writer.writeBool(10, f);
    }
    f = message.getAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions();
    if (f != null) {
        writer.writeMessage(6, f, diamonds_entity_pb.DiamondAmount.serializeBinaryToWriter);
    }
    f = message.getCondition1();
    if (f != null) {
        writer.writeMessage(7, f, users_entity_pb.AcquireDiamondCondition.serializeBinaryToWriter);
    }
    f = message.getCondition2();
    if (f != null) {
        writer.writeMessage(8, f, users_entity_pb.AcquireDiamondCondition.serializeBinaryToWriter);
    }
    f = message.getCondition3();
    if (f != null) {
        writer.writeMessage(9, f, users_entity_pb.AcquireDiamondCondition.serializeBinaryToWriter);
    }
};
/**
 * optional DiamondBalance diamond_balance = 1;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 1));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional DiamondAmount total_remain_accumulation_diamond_fund_amount = 2;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getTotalRemainAccumulationDiamondFundAmount = function () {
    return /** @type{?proto.jp.singcolor.DiamondAmount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setTotalRemainAccumulationDiamondFundAmount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.clearTotalRemainAccumulationDiamondFundAmount = function () {
    return this.setTotalRemainAccumulationDiamondFundAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.hasTotalRemainAccumulationDiamondFundAmount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string sing_diamond_acquisition_right = 3;
 * @return {string}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getSingDiamondAcquisitionRight = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setSingDiamondAcquisitionRight = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string chat_diamond_acquisition_right = 4;
 * @return {string}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getChatDiamondAcquisitionRight = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setChatDiamondAcquisitionRight = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string next_accumulation_diamond_fund_date = 5;
 * @return {string}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getNextAccumulationDiamondFundDate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setNextAccumulationDiamondFundDate = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional bool acquire_accumulation_diamond_end_of_term_if_satisfied_conditions = 10;
 * @return {boolean}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getAcquireAccumulationDiamondEndOfTermIfSatisfiedConditions = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setAcquireAccumulationDiamondEndOfTermIfSatisfiedConditions = function (value) {
    return jspb.Message.setProto3BooleanField(this, 10, value);
};
/**
 * optional DiamondAmount acquirable_accumulation_diamond_fund_amount_if_satisfied_conditions = 6;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions = function () {
    return /** @type{?proto.jp.singcolor.DiamondAmount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondAmount, 6));
};
/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.clearAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions = function () {
    return this.setAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.hasAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional AcquireDiamondCondition condition1 = 7;
 * @return {?proto.jp.singcolor.AcquireDiamondCondition}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getCondition1 = function () {
    return /** @type{?proto.jp.singcolor.AcquireDiamondCondition} */ (jspb.Message.getWrapperField(this, users_entity_pb.AcquireDiamondCondition, 7));
};
/**
 * @param {?proto.jp.singcolor.AcquireDiamondCondition|undefined} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setCondition1 = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.clearCondition1 = function () {
    return this.setCondition1(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.hasCondition1 = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional AcquireDiamondCondition condition2 = 8;
 * @return {?proto.jp.singcolor.AcquireDiamondCondition}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getCondition2 = function () {
    return /** @type{?proto.jp.singcolor.AcquireDiamondCondition} */ (jspb.Message.getWrapperField(this, users_entity_pb.AcquireDiamondCondition, 8));
};
/**
 * @param {?proto.jp.singcolor.AcquireDiamondCondition|undefined} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setCondition2 = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.clearCondition2 = function () {
    return this.setCondition2(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.hasCondition2 = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional AcquireDiamondCondition condition3 = 9;
 * @return {?proto.jp.singcolor.AcquireDiamondCondition}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.getCondition3 = function () {
    return /** @type{?proto.jp.singcolor.AcquireDiamondCondition} */ (jspb.Message.getWrapperField(this, users_entity_pb.AcquireDiamondCondition, 9));
};
/**
 * @param {?proto.jp.singcolor.AcquireDiamondCondition|undefined} value
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.setCondition3 = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.clearCondition3 = function () {
    return this.setCondition3(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetStatisticsCurrentDiamondV1Response.prototype.hasCondition3 = function () {
    return jspb.Message.getField(this, 9) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetMonthlyStatisticsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetMonthlyStatisticsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetMonthlyStatisticsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageKey: (f = msg.getPageKey()) && users_entity_pb.MonthlyStatisticsPageKey.toObject(includeInstance, f),
            organizationLiverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Request}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetMonthlyStatisticsV1Request;
    return proto.jp.singcolor.GetMonthlyStatisticsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetMonthlyStatisticsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Request}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.MonthlyStatisticsPageKey;
                reader.readMessage(value, users_entity_pb.MonthlyStatisticsPageKey.deserializeBinaryFromReader);
                msg.setPageKey(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetMonthlyStatisticsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetMonthlyStatisticsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageKey();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.MonthlyStatisticsPageKey.serializeBinaryToWriter);
    }
    f = message.getOrganizationLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional MonthlyStatisticsPageKey page_key = 1;
 * @return {?proto.jp.singcolor.MonthlyStatisticsPageKey}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.getPageKey = function () {
    return /** @type{?proto.jp.singcolor.MonthlyStatisticsPageKey} */ (jspb.Message.getWrapperField(this, users_entity_pb.MonthlyStatisticsPageKey, 1));
};
/**
 * @param {?proto.jp.singcolor.MonthlyStatisticsPageKey|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Request} returns this
*/
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.setPageKey = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Request} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.clearPageKey = function () {
    return this.setPageKey(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.hasPageKey = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string organization_liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.getOrganizationLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Request} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.setOrganizationLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string organization_id = 3;
 * @return {string}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Request} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.repeatedFields_ = [7];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetMonthlyStatisticsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetMonthlyStatisticsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            prevPageKey: (f = msg.getPrevPageKey()) && users_entity_pb.MonthlyStatisticsPageKey.toObject(includeInstance, f),
            nextPageKey: (f = msg.getNextPageKey()) && users_entity_pb.MonthlyStatisticsPageKey.toObject(includeInstance, f),
            monthlyStatistics: (f = msg.getMonthlyStatistics()) && users_entity_pb.StatisticsRecord.toObject(includeInstance, f),
            firstTermStatistics: (f = msg.getFirstTermStatistics()) && users_entity_pb.StatisticsRecord.toObject(includeInstance, f),
            secondTermStatistics: (f = msg.getSecondTermStatistics()) && users_entity_pb.StatisticsRecord.toObject(includeInstance, f),
            thirdTermStatistics: (f = msg.getThirdTermStatistics()) && users_entity_pb.StatisticsRecord.toObject(includeInstance, f),
            dailyStatisticsList: jspb.Message.toObjectList(msg.getDailyStatisticsList(), users_entity_pb.StatisticsRecord.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetMonthlyStatisticsV1Response;
    return proto.jp.singcolor.GetMonthlyStatisticsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.MonthlyStatisticsPageKey;
                reader.readMessage(value, users_entity_pb.MonthlyStatisticsPageKey.deserializeBinaryFromReader);
                msg.setPrevPageKey(value);
                break;
            case 2:
                var value = new users_entity_pb.MonthlyStatisticsPageKey;
                reader.readMessage(value, users_entity_pb.MonthlyStatisticsPageKey.deserializeBinaryFromReader);
                msg.setNextPageKey(value);
                break;
            case 3:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.setMonthlyStatistics(value);
                break;
            case 4:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.setFirstTermStatistics(value);
                break;
            case 5:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.setSecondTermStatistics(value);
                break;
            case 6:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.setThirdTermStatistics(value);
                break;
            case 7:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.addDailyStatistics(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetMonthlyStatisticsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPrevPageKey();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.MonthlyStatisticsPageKey.serializeBinaryToWriter);
    }
    f = message.getNextPageKey();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.MonthlyStatisticsPageKey.serializeBinaryToWriter);
    }
    f = message.getMonthlyStatistics();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
    f = message.getFirstTermStatistics();
    if (f != null) {
        writer.writeMessage(4, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
    f = message.getSecondTermStatistics();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
    f = message.getThirdTermStatistics();
    if (f != null) {
        writer.writeMessage(6, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
    f = message.getDailyStatisticsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(7, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
};
/**
 * optional MonthlyStatisticsPageKey prev_page_key = 1;
 * @return {?proto.jp.singcolor.MonthlyStatisticsPageKey}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.getPrevPageKey = function () {
    return /** @type{?proto.jp.singcolor.MonthlyStatisticsPageKey} */ (jspb.Message.getWrapperField(this, users_entity_pb.MonthlyStatisticsPageKey, 1));
};
/**
 * @param {?proto.jp.singcolor.MonthlyStatisticsPageKey|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.setPrevPageKey = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.clearPrevPageKey = function () {
    return this.setPrevPageKey(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.hasPrevPageKey = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional MonthlyStatisticsPageKey next_page_key = 2;
 * @return {?proto.jp.singcolor.MonthlyStatisticsPageKey}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.getNextPageKey = function () {
    return /** @type{?proto.jp.singcolor.MonthlyStatisticsPageKey} */ (jspb.Message.getWrapperField(this, users_entity_pb.MonthlyStatisticsPageKey, 2));
};
/**
 * @param {?proto.jp.singcolor.MonthlyStatisticsPageKey|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.setNextPageKey = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.clearNextPageKey = function () {
    return this.setNextPageKey(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.hasNextPageKey = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional StatisticsRecord monthly_statistics = 3;
 * @return {?proto.jp.singcolor.StatisticsRecord}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.getMonthlyStatistics = function () {
    return /** @type{?proto.jp.singcolor.StatisticsRecord} */ (jspb.Message.getWrapperField(this, users_entity_pb.StatisticsRecord, 3));
};
/**
 * @param {?proto.jp.singcolor.StatisticsRecord|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.setMonthlyStatistics = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.clearMonthlyStatistics = function () {
    return this.setMonthlyStatistics(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.hasMonthlyStatistics = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional StatisticsRecord first_term_statistics = 4;
 * @return {?proto.jp.singcolor.StatisticsRecord}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.getFirstTermStatistics = function () {
    return /** @type{?proto.jp.singcolor.StatisticsRecord} */ (jspb.Message.getWrapperField(this, users_entity_pb.StatisticsRecord, 4));
};
/**
 * @param {?proto.jp.singcolor.StatisticsRecord|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.setFirstTermStatistics = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.clearFirstTermStatistics = function () {
    return this.setFirstTermStatistics(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.hasFirstTermStatistics = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional StatisticsRecord second_term_statistics = 5;
 * @return {?proto.jp.singcolor.StatisticsRecord}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.getSecondTermStatistics = function () {
    return /** @type{?proto.jp.singcolor.StatisticsRecord} */ (jspb.Message.getWrapperField(this, users_entity_pb.StatisticsRecord, 5));
};
/**
 * @param {?proto.jp.singcolor.StatisticsRecord|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.setSecondTermStatistics = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.clearSecondTermStatistics = function () {
    return this.setSecondTermStatistics(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.hasSecondTermStatistics = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional StatisticsRecord third_term_statistics = 6;
 * @return {?proto.jp.singcolor.StatisticsRecord}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.getThirdTermStatistics = function () {
    return /** @type{?proto.jp.singcolor.StatisticsRecord} */ (jspb.Message.getWrapperField(this, users_entity_pb.StatisticsRecord, 6));
};
/**
 * @param {?proto.jp.singcolor.StatisticsRecord|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.setThirdTermStatistics = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.clearThirdTermStatistics = function () {
    return this.setThirdTermStatistics(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.hasThirdTermStatistics = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * repeated StatisticsRecord daily_statistics = 7;
 * @return {!Array<!proto.jp.singcolor.StatisticsRecord>}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.getDailyStatisticsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.StatisticsRecord>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.StatisticsRecord, 7));
};
/**
 * @param {!Array<!proto.jp.singcolor.StatisticsRecord>} value
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.setDailyStatisticsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 7, value);
};
/**
 * @param {!proto.jp.singcolor.StatisticsRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.StatisticsRecord}
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.addDailyStatistics = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.jp.singcolor.StatisticsRecord, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyStatisticsV1Response.prototype.clearDailyStatisticsList = function () {
    return this.setDailyStatisticsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiverPromotionMeterV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiverPromotionMeterV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiverPromotionMeterV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiverPromotionMeterV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiverPromotionMeterV1Request}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiverPromotionMeterV1Request;
    return proto.jp.singcolor.GetLiverPromotionMeterV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiverPromotionMeterV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiverPromotionMeterV1Request}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiverPromotionMeterV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiverPromotionMeterV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiverPromotionMeterV1Request} returns this
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiverPromotionMeterV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiverPromotionMeterV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiverPromotionMeterV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiverPromotionMeterV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverPromotionMeter: (f = msg.getLiverPromotionMeter()) && leagues_entity_pb.LiverPromotionMeter.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiverPromotionMeterV1Response}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiverPromotionMeterV1Response;
    return proto.jp.singcolor.GetLiverPromotionMeterV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiverPromotionMeterV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiverPromotionMeterV1Response}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new leagues_entity_pb.LiverPromotionMeter;
                reader.readMessage(value, leagues_entity_pb.LiverPromotionMeter.deserializeBinaryFromReader);
                msg.setLiverPromotionMeter(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiverPromotionMeterV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiverPromotionMeterV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverPromotionMeter();
    if (f != null) {
        writer.writeMessage(1, f, leagues_entity_pb.LiverPromotionMeter.serializeBinaryToWriter);
    }
};
/**
 * optional LiverPromotionMeter liver_promotion_meter = 1;
 * @return {?proto.jp.singcolor.LiverPromotionMeter}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Response.prototype.getLiverPromotionMeter = function () {
    return /** @type{?proto.jp.singcolor.LiverPromotionMeter} */ (jspb.Message.getWrapperField(this, leagues_entity_pb.LiverPromotionMeter, 1));
};
/**
 * @param {?proto.jp.singcolor.LiverPromotionMeter|undefined} value
 * @return {!proto.jp.singcolor.GetLiverPromotionMeterV1Response} returns this
*/
proto.jp.singcolor.GetLiverPromotionMeterV1Response.prototype.setLiverPromotionMeter = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLiverPromotionMeterV1Response} returns this
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Response.prototype.clearLiverPromotionMeter = function () {
    return this.setLiverPromotionMeter(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLiverPromotionMeterV1Response.prototype.hasLiverPromotionMeter = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SearchUsersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SearchUsersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SearchUsersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SearchUsersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            keyword: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SearchUsersV1Request}
 */
proto.jp.singcolor.SearchUsersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SearchUsersV1Request;
    return proto.jp.singcolor.SearchUsersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SearchUsersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SearchUsersV1Request}
 */
proto.jp.singcolor.SearchUsersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setKeyword(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SearchUsersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SearchUsersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SearchUsersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SearchUsersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getKeyword();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.SearchUsersV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SearchUsersV1Request} returns this
 */
proto.jp.singcolor.SearchUsersV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.SearchUsersV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchUsersV1Request} returns this
 */
proto.jp.singcolor.SearchUsersV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string keyword = 3;
 * @return {string}
 */
proto.jp.singcolor.SearchUsersV1Request.prototype.getKeyword = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SearchUsersV1Request} returns this
 */
proto.jp.singcolor.SearchUsersV1Request.prototype.setKeyword = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.SearchUsersV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SearchUsersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SearchUsersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SearchUsersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SearchUsersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            searchUsersList: jspb.Message.toObjectList(msg.getSearchUsersList(), users_entity_pb.SearchUser.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SearchUsersV1Response}
 */
proto.jp.singcolor.SearchUsersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SearchUsersV1Response;
    return proto.jp.singcolor.SearchUsersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SearchUsersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SearchUsersV1Response}
 */
proto.jp.singcolor.SearchUsersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.SearchUser;
                reader.readMessage(value, users_entity_pb.SearchUser.deserializeBinaryFromReader);
                msg.addSearchUsers(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SearchUsersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SearchUsersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SearchUsersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SearchUsersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSearchUsersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.SearchUser.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated SearchUser search_users = 1;
 * @return {!Array<!proto.jp.singcolor.SearchUser>}
 */
proto.jp.singcolor.SearchUsersV1Response.prototype.getSearchUsersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.SearchUser>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.SearchUser, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.SearchUser>} value
 * @return {!proto.jp.singcolor.SearchUsersV1Response} returns this
*/
proto.jp.singcolor.SearchUsersV1Response.prototype.setSearchUsersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.SearchUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.SearchUser}
 */
proto.jp.singcolor.SearchUsersV1Response.prototype.addSearchUsers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.SearchUser, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.SearchUsersV1Response} returns this
 */
proto.jp.singcolor.SearchUsersV1Response.prototype.clearSearchUsersList = function () {
    return this.setSearchUsersList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.SearchUsersV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SearchUsersV1Response} returns this
 */
proto.jp.singcolor.SearchUsersV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.SearchUsersV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.SearchUsersV1Response} returns this
 */
proto.jp.singcolor.SearchUsersV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request}
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request;
    return proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request}
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            defaultRankingTermType: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response}
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response;
    return proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response}
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.RankingTermType} */ (reader.readEnum());
                msg.setDefaultRankingTermType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDefaultRankingTermType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * optional RankingTermType default_ranking_term_type = 1;
 * @return {!proto.jp.singcolor.RankingTermType}
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.prototype.getDefaultRankingTermType = function () {
    return /** @type {!proto.jp.singcolor.RankingTermType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.RankingTermType} value
 * @return {!proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response} returns this
 */
proto.jp.singcolor.GetDefaultLiverRankingTermTypeV1Response.prototype.setDefaultRankingTermType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiverRankingTermTypesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiverRankingTermTypesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiverRankingTermTypesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiverRankingTermTypesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Request}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiverRankingTermTypesV1Request;
    return proto.jp.singcolor.ListLiverRankingTermTypesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiverRankingTermTypesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Request}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiverRankingTermTypesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiverRankingTermTypesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Request} returns this
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.repeatedFields_ = [1, 2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiverRankingTermTypesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiverRankingTermTypesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            rankingTermsList: jspb.Message.toObjectList(msg.getRankingTermsList(), users_entity_pb.RankingTerm.toObject, includeInstance),
            liveTimelineInfosList: jspb.Message.toObjectList(msg.getLiveTimelineInfosList(), lives_entity_pb.LiveTimelineInfo.toObject, includeInstance),
            defaultRankingTermId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            defaultFilterTimelineId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            defaultFilterGender: jspb.Message.getFieldWithDefault(msg, 5, 0),
            defaultFilterWorldFilter: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiverRankingTermTypesV1Response;
    return proto.jp.singcolor.ListLiverRankingTermTypesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.RankingTerm;
                reader.readMessage(value, users_entity_pb.RankingTerm.deserializeBinaryFromReader);
                msg.addRankingTerms(value);
                break;
            case 2:
                var value = new lives_entity_pb.LiveTimelineInfo;
                reader.readMessage(value, lives_entity_pb.LiveTimelineInfo.deserializeBinaryFromReader);
                msg.addLiveTimelineInfos(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDefaultRankingTermId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setDefaultFilterTimelineId(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setDefaultFilterGender(value);
                break;
            case 6:
                var value = /** @type {!proto.jp.singcolor.WorldFilter} */ (reader.readEnum());
                msg.setDefaultFilterWorldFilter(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiverRankingTermTypesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRankingTermsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.RankingTerm.serializeBinaryToWriter);
    }
    f = message.getLiveTimelineInfosList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, lives_entity_pb.LiveTimelineInfo.serializeBinaryToWriter);
    }
    f = message.getDefaultRankingTermId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getDefaultFilterTimelineId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getDefaultFilterGender();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getDefaultFilterWorldFilter();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
};
/**
 * repeated RankingTerm ranking_terms = 1;
 * @return {!Array<!proto.jp.singcolor.RankingTerm>}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.getRankingTermsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.RankingTerm>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.RankingTerm, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.RankingTerm>} value
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} returns this
*/
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.setRankingTermsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.RankingTerm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RankingTerm}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.addRankingTerms = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.RankingTerm, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} returns this
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.clearRankingTermsList = function () {
    return this.setRankingTermsList([]);
};
/**
 * repeated LiveTimelineInfo live_timeline_infos = 2;
 * @return {!Array<!proto.jp.singcolor.LiveTimelineInfo>}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.getLiveTimelineInfosList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveTimelineInfo>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveTimelineInfo, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveTimelineInfo>} value
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} returns this
*/
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.setLiveTimelineInfosList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.LiveTimelineInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveTimelineInfo}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.addLiveTimelineInfos = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.LiveTimelineInfo, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} returns this
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.clearLiveTimelineInfosList = function () {
    return this.setLiveTimelineInfosList([]);
};
/**
 * optional string default_ranking_term_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.getDefaultRankingTermId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} returns this
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.setDefaultRankingTermId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string default_filter_timeline_id = 4;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.getDefaultFilterTimelineId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} returns this
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.setDefaultFilterTimelineId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional Gender default_filter_gender = 5;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.getDefaultFilterGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} returns this
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.setDefaultFilterGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional WorldFilter default_filter_world_filter = 6;
 * @return {!proto.jp.singcolor.WorldFilter}
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.getDefaultFilterWorldFilter = function () {
    return /** @type {!proto.jp.singcolor.WorldFilter} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {!proto.jp.singcolor.WorldFilter} value
 * @return {!proto.jp.singcolor.ListLiverRankingTermTypesV1Response} returns this
 */
proto.jp.singcolor.ListLiverRankingTermTypesV1Response.prototype.setDefaultFilterWorldFilter = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiverRankingV2Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiverRankingV2Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiverRankingV2Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiverRankingV2Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            worldFilter: jspb.Message.getFieldWithDefault(msg, 1, 0),
            gender: jspb.Message.getFieldWithDefault(msg, 2, 0),
            timelineId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            rankingTermId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            targetTermKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request}
 */
proto.jp.singcolor.ListLiverRankingV2Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiverRankingV2Request;
    return proto.jp.singcolor.ListLiverRankingV2Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiverRankingV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request}
 */
proto.jp.singcolor.ListLiverRankingV2Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.WorldFilter} */ (reader.readEnum());
                msg.setWorldFilter(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setGender(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimelineId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setRankingTermId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetTermKey(value);
                break;
            case 6:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiverRankingV2Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiverRankingV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiverRankingV2Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWorldFilter();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getGender();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getTimelineId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getRankingTermId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getTargetTermKey();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(6, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional WorldFilter world_filter = 1;
 * @return {!proto.jp.singcolor.WorldFilter}
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.getWorldFilter = function () {
    return /** @type {!proto.jp.singcolor.WorldFilter} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.WorldFilter} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.setWorldFilter = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional Gender gender = 2;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.getGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.setGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string timeline_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.getTimelineId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.setTimelineId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string ranking_term_id = 4;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.getRankingTermId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.setRankingTermId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string target_term_key = 5;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.getTargetTermKey = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.setTargetTermKey = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional SimplePaginationRequest page = 6;
 * @return {?proto.jp.singcolor.SimplePaginationRequest}
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 6));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request} returns this
*/
proto.jp.singcolor.ListLiverRankingV2Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListLiverRankingV2Request} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListLiverRankingV2Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLiverRankingV2Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiverRankingV2Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiverRankingV2Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiverRankingV2Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiverRankingV2Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            rankingLiversList: jspb.Message.toObjectList(msg.getRankingLiversList(), users_entity_pb.RankingLiver.toObject, includeInstance),
            worldFilter: jspb.Message.getFieldWithDefault(msg, 2, 0),
            gender: jspb.Message.getFieldWithDefault(msg, 3, 0),
            timelineId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            rankingTermId: jspb.Message.getFieldWithDefault(msg, 5, ""),
            targetTermLabel: jspb.Message.getFieldWithDefault(msg, 6, ""),
            targetTermKey: jspb.Message.getFieldWithDefault(msg, 7, ""),
            prevTermKey: jspb.Message.getFieldWithDefault(msg, 8, ""),
            nextTermKey: jspb.Message.getFieldWithDefault(msg, 9, ""),
            emptyMessage: jspb.Message.getFieldWithDefault(msg, 10, ""),
            eventStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response}
 */
proto.jp.singcolor.ListLiverRankingV2Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiverRankingV2Response;
    return proto.jp.singcolor.ListLiverRankingV2Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiverRankingV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response}
 */
proto.jp.singcolor.ListLiverRankingV2Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.RankingLiver;
                reader.readMessage(value, users_entity_pb.RankingLiver.deserializeBinaryFromReader);
                msg.addRankingLivers(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.WorldFilter} */ (reader.readEnum());
                msg.setWorldFilter(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setGender(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimelineId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setRankingTermId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetTermLabel(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetTermKey(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setPrevTermKey(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextTermKey(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmptyMessage(value);
                break;
            case 11:
                var value = /** @type {!proto.jp.singcolor.ListLiverRankingV2Response.EventStatus} */ (reader.readEnum());
                msg.setEventStatus(value);
                break;
            case 12:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiverRankingV2Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiverRankingV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiverRankingV2Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRankingLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.RankingLiver.serializeBinaryToWriter);
    }
    f = message.getWorldFilter();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getGender();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getTimelineId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getRankingTermId();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getTargetTermLabel();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getTargetTermKey();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getPrevTermKey();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getNextTermKey();
    if (f.length > 0) {
        writer.writeString(9, f);
    }
    f = message.getEmptyMessage();
    if (f.length > 0) {
        writer.writeString(10, f);
    }
    f = message.getEventStatus();
    if (f !== 0.0) {
        writer.writeEnum(11, f);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(12, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.ListLiverRankingV2Response.EventStatus = {
    EVENT_STATUS_NONE: 0,
    EVENT_STATUS_UPCOMING: 1,
    EVENT_STATUS_ONGOING: 2,
    EVENT_STATUS_FINISHED: 3
};
/**
 * repeated RankingLiver ranking_livers = 1;
 * @return {!Array<!proto.jp.singcolor.RankingLiver>}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getRankingLiversList = function () {
    return /** @type{!Array<!proto.jp.singcolor.RankingLiver>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.RankingLiver, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.RankingLiver>} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
*/
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setRankingLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.RankingLiver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RankingLiver}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.addRankingLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.RankingLiver, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.clearRankingLiversList = function () {
    return this.setRankingLiversList([]);
};
/**
 * optional WorldFilter world_filter = 2;
 * @return {!proto.jp.singcolor.WorldFilter}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getWorldFilter = function () {
    return /** @type {!proto.jp.singcolor.WorldFilter} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.WorldFilter} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setWorldFilter = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional Gender gender = 3;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string timeline_id = 4;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getTimelineId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setTimelineId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string ranking_term_id = 5;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getRankingTermId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setRankingTermId = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string target_term_label = 6;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getTargetTermLabel = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setTargetTermLabel = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string target_term_key = 7;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getTargetTermKey = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setTargetTermKey = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string prev_term_key = 8;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getPrevTermKey = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setPrevTermKey = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional string next_term_key = 9;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getNextTermKey = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setNextTermKey = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional string empty_message = 10;
 * @return {string}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getEmptyMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setEmptyMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * optional EventStatus event_status = 11;
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response.EventStatus}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getEventStatus = function () {
    return /** @type {!proto.jp.singcolor.ListLiverRankingV2Response.EventStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};
/**
 * @param {!proto.jp.singcolor.ListLiverRankingV2Response.EventStatus} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setEventStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 11, value);
};
/**
 * optional SimplePaginationResult page = 12;
 * @return {?proto.jp.singcolor.SimplePaginationResult}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 12));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
*/
proto.jp.singcolor.ListLiverRankingV2Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 12, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListLiverRankingV2Response} returns this
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListLiverRankingV2Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 12) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLoginBonusV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLoginBonusV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLoginBonusV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLoginBonusV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLoginBonusV1Request}
 */
proto.jp.singcolor.GetLoginBonusV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLoginBonusV1Request;
    return proto.jp.singcolor.GetLoginBonusV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLoginBonusV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLoginBonusV1Request}
 */
proto.jp.singcolor.GetLoginBonusV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLoginBonusV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLoginBonusV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLoginBonusV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLoginBonusV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLoginBonusV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLoginBonusV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLoginBonusV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLoginBonusV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            acquiredCoinAmount: (f = msg.getAcquiredCoinAmount()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            message: jspb.Message.getFieldWithDefault(msg, 3, ""),
            nextShouldCallAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
            oneWord: jspb.Message.getFieldWithDefault(msg, 5, ""),
            oneWordImageUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
            isSubUserSanctioned: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response}
 */
proto.jp.singcolor.GetLoginBonusV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLoginBonusV1Response;
    return proto.jp.singcolor.GetLoginBonusV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLoginBonusV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response}
 */
proto.jp.singcolor.GetLoginBonusV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 2:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setAcquiredCoinAmount(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextShouldCallAt(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setOneWord(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setOneWordImageUrl(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsSubUserSanctioned(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLoginBonusV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLoginBonusV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLoginBonusV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getAcquiredCoinAmount();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getMessage();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getNextShouldCallAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getOneWord();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getOneWordImageUrl();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getIsSubUserSanctioned();
    if (f) {
        writer.writeBool(7, f);
    }
};
/**
 * optional CoinBalance coin_balance = 1;
 * @return {?proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.jp.singcolor.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 1));
};
/**
 * @param {?proto.jp.singcolor.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
*/
proto.jp.singcolor.GetLoginBonusV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional CoinAmount acquired_coin_amount = 2;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.getAcquiredCoinAmount = function () {
    return /** @type{?proto.jp.singcolor.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
*/
proto.jp.singcolor.GetLoginBonusV1Response.prototype.setAcquiredCoinAmount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.clearAcquiredCoinAmount = function () {
    return this.setAcquiredCoinAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.hasAcquiredCoinAmount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string message = 3;
 * @return {string}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.getMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.setMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 next_should_call_at = 4;
 * @return {number}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.getNextShouldCallAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.setNextShouldCallAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string one_word = 5;
 * @return {string}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.getOneWord = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.setOneWord = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string one_word_image_url = 6;
 * @return {string}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.getOneWordImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.setOneWordImageUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional bool is_sub_user_sanctioned = 7;
 * @return {boolean}
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.getIsSubUserSanctioned = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetLoginBonusV1Response} returns this
 */
proto.jp.singcolor.GetLoginBonusV1Response.prototype.setIsSubUserSanctioned = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RequestWithdrawV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RequestWithdrawV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RequestWithdrawV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RequestWithdrawV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RequestWithdrawV1Request}
 */
proto.jp.singcolor.RequestWithdrawV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RequestWithdrawV1Request;
    return proto.jp.singcolor.RequestWithdrawV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RequestWithdrawV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RequestWithdrawV1Request}
 */
proto.jp.singcolor.RequestWithdrawV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RequestWithdrawV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RequestWithdrawV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RequestWithdrawV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RequestWithdrawV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RequestWithdrawV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RequestWithdrawV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RequestWithdrawV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RequestWithdrawV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RequestWithdrawV1Response}
 */
proto.jp.singcolor.RequestWithdrawV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RequestWithdrawV1Response;
    return proto.jp.singcolor.RequestWithdrawV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RequestWithdrawV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RequestWithdrawV1Response}
 */
proto.jp.singcolor.RequestWithdrawV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RequestWithdrawV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RequestWithdrawV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RequestWithdrawV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RequestWithdrawV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CancelWithdrawV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CancelWithdrawV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CancelWithdrawV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CancelWithdrawV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CancelWithdrawV1Request}
 */
proto.jp.singcolor.CancelWithdrawV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CancelWithdrawV1Request;
    return proto.jp.singcolor.CancelWithdrawV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CancelWithdrawV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CancelWithdrawV1Request}
 */
proto.jp.singcolor.CancelWithdrawV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CancelWithdrawV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CancelWithdrawV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CancelWithdrawV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CancelWithdrawV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CancelWithdrawV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CancelWithdrawV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CancelWithdrawV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CancelWithdrawV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CancelWithdrawV1Response}
 */
proto.jp.singcolor.CancelWithdrawV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CancelWithdrawV1Response;
    return proto.jp.singcolor.CancelWithdrawV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CancelWithdrawV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CancelWithdrawV1Response}
 */
proto.jp.singcolor.CancelWithdrawV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CancelWithdrawV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CancelWithdrawV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CancelWithdrawV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CancelWithdrawV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetWithdrawV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetWithdrawV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetWithdrawV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetWithdrawV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetWithdrawV1Request}
 */
proto.jp.singcolor.GetWithdrawV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetWithdrawV1Request;
    return proto.jp.singcolor.GetWithdrawV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetWithdrawV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetWithdrawV1Request}
 */
proto.jp.singcolor.GetWithdrawV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetWithdrawV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetWithdrawV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetWithdrawV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetWithdrawV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetWithdrawV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetWithdrawV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetWithdrawV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetWithdrawV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            isWithdrawRequested: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            withdrawRequestedAt: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetWithdrawV1Response}
 */
proto.jp.singcolor.GetWithdrawV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetWithdrawV1Response;
    return proto.jp.singcolor.GetWithdrawV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetWithdrawV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetWithdrawV1Response}
 */
proto.jp.singcolor.GetWithdrawV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsWithdrawRequested(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setWithdrawRequestedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetWithdrawV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetWithdrawV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetWithdrawV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetWithdrawV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIsWithdrawRequested();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getWithdrawRequestedAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional bool is_withdraw_requested = 1;
 * @return {boolean}
 */
proto.jp.singcolor.GetWithdrawV1Response.prototype.getIsWithdrawRequested = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetWithdrawV1Response} returns this
 */
proto.jp.singcolor.GetWithdrawV1Response.prototype.setIsWithdrawRequested = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional int64 withdraw_requested_at = 2;
 * @return {number}
 */
proto.jp.singcolor.GetWithdrawV1Response.prototype.getWithdrawRequestedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetWithdrawV1Response} returns this
 */
proto.jp.singcolor.GetWithdrawV1Response.prototype.setWithdrawRequestedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWebPageV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWebPageV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWebPageV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWebPageV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWebPageV1Request}
 */
proto.jp.singcolor.ListWebPageV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWebPageV1Request;
    return proto.jp.singcolor.ListWebPageV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWebPageV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWebPageV1Request}
 */
proto.jp.singcolor.ListWebPageV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWebPageV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWebPageV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWebPageV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWebPageV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListWebPageV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWebPageV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWebPageV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWebPageV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWebPageV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            webPagesList: jspb.Message.toObjectList(msg.getWebPagesList(), proto.jp.singcolor.ListWebPageV1Response.WebPage.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWebPageV1Response}
 */
proto.jp.singcolor.ListWebPageV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWebPageV1Response;
    return proto.jp.singcolor.ListWebPageV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWebPageV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWebPageV1Response}
 */
proto.jp.singcolor.ListWebPageV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.ListWebPageV1Response.WebPage;
                reader.readMessage(value, proto.jp.singcolor.ListWebPageV1Response.WebPage.deserializeBinaryFromReader);
                msg.addWebPages(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWebPageV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWebPageV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWebPageV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWebPageV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWebPagesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.ListWebPageV1Response.WebPage.serializeBinaryToWriter);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWebPageV1Response.WebPage.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWebPageV1Response.WebPage.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWebPageV1Response.WebPage} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWebPageV1Response.WebPage.toObject = function (includeInstance, msg) {
        var f, obj = {
            keyname: jspb.Message.getFieldWithDefault(msg, 1, ""),
            url: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWebPageV1Response.WebPage}
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWebPageV1Response.WebPage;
    return proto.jp.singcolor.ListWebPageV1Response.WebPage.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWebPageV1Response.WebPage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWebPageV1Response.WebPage}
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setKeyname(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWebPageV1Response.WebPage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWebPageV1Response.WebPage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getKeyname();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string keyName = 1;
 * @return {string}
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage.prototype.getKeyname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListWebPageV1Response.WebPage} returns this
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage.prototype.setKeyname = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string url = 2;
 * @return {string}
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListWebPageV1Response.WebPage} returns this
 */
proto.jp.singcolor.ListWebPageV1Response.WebPage.prototype.setUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated WebPage web_pages = 1;
 * @return {!Array<!proto.jp.singcolor.ListWebPageV1Response.WebPage>}
 */
proto.jp.singcolor.ListWebPageV1Response.prototype.getWebPagesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListWebPageV1Response.WebPage>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListWebPageV1Response.WebPage, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListWebPageV1Response.WebPage>} value
 * @return {!proto.jp.singcolor.ListWebPageV1Response} returns this
*/
proto.jp.singcolor.ListWebPageV1Response.prototype.setWebPagesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListWebPageV1Response.WebPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListWebPageV1Response.WebPage}
 */
proto.jp.singcolor.ListWebPageV1Response.prototype.addWebPages = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListWebPageV1Response.WebPage, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListWebPageV1Response} returns this
 */
proto.jp.singcolor.ListWebPageV1Response.prototype.clearWebPagesList = function () {
    return this.setWebPagesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUnreadNotificationInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUnreadNotificationInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUnreadNotificationInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUnreadNotificationInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUnreadNotificationInfoV1Request}
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUnreadNotificationInfoV1Request;
    return proto.jp.singcolor.GetUnreadNotificationInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUnreadNotificationInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUnreadNotificationInfoV1Request}
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUnreadNotificationInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUnreadNotificationInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUnreadNotificationInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUnreadNotificationInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUnreadNotificationInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUnreadNotificationInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            unreadNotificationInfo: (f = msg.getUnreadNotificationInfo()) && users_entity_pb.UnreadNotificationInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUnreadNotificationInfoV1Response}
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUnreadNotificationInfoV1Response;
    return proto.jp.singcolor.GetUnreadNotificationInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUnreadNotificationInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUnreadNotificationInfoV1Response}
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UnreadNotificationInfo;
                reader.readMessage(value, users_entity_pb.UnreadNotificationInfo.deserializeBinaryFromReader);
                msg.setUnreadNotificationInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUnreadNotificationInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUnreadNotificationInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUnreadNotificationInfo();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UnreadNotificationInfo.serializeBinaryToWriter);
    }
};
/**
 * optional UnreadNotificationInfo unread_notification_info = 1;
 * @return {?proto.jp.singcolor.UnreadNotificationInfo}
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Response.prototype.getUnreadNotificationInfo = function () {
    return /** @type{?proto.jp.singcolor.UnreadNotificationInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.UnreadNotificationInfo, 1));
};
/**
 * @param {?proto.jp.singcolor.UnreadNotificationInfo|undefined} value
 * @return {!proto.jp.singcolor.GetUnreadNotificationInfoV1Response} returns this
*/
proto.jp.singcolor.GetUnreadNotificationInfoV1Response.prototype.setUnreadNotificationInfo = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUnreadNotificationInfoV1Response} returns this
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Response.prototype.clearUnreadNotificationInfo = function () {
    return this.setUnreadNotificationInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUnreadNotificationInfoV1Response.prototype.hasUnreadNotificationInfo = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ReadNotificationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ReadNotificationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ReadNotificationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ReadNotificationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            notificationType: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ReadNotificationV1Request}
 */
proto.jp.singcolor.ReadNotificationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ReadNotificationV1Request;
    return proto.jp.singcolor.ReadNotificationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ReadNotificationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ReadNotificationV1Request}
 */
proto.jp.singcolor.ReadNotificationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.NotificationType} */ (reader.readEnum());
                msg.setNotificationType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ReadNotificationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ReadNotificationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ReadNotificationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ReadNotificationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getNotificationType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * optional NotificationType notification_type = 1;
 * @return {!proto.jp.singcolor.NotificationType}
 */
proto.jp.singcolor.ReadNotificationV1Request.prototype.getNotificationType = function () {
    return /** @type {!proto.jp.singcolor.NotificationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.NotificationType} value
 * @return {!proto.jp.singcolor.ReadNotificationV1Request} returns this
 */
proto.jp.singcolor.ReadNotificationV1Request.prototype.setNotificationType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ReadNotificationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ReadNotificationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ReadNotificationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ReadNotificationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            unreadNotificationInfo: (f = msg.getUnreadNotificationInfo()) && users_entity_pb.UnreadNotificationInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ReadNotificationV1Response}
 */
proto.jp.singcolor.ReadNotificationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ReadNotificationV1Response;
    return proto.jp.singcolor.ReadNotificationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ReadNotificationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ReadNotificationV1Response}
 */
proto.jp.singcolor.ReadNotificationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UnreadNotificationInfo;
                reader.readMessage(value, users_entity_pb.UnreadNotificationInfo.deserializeBinaryFromReader);
                msg.setUnreadNotificationInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ReadNotificationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ReadNotificationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ReadNotificationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ReadNotificationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUnreadNotificationInfo();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UnreadNotificationInfo.serializeBinaryToWriter);
    }
};
/**
 * optional UnreadNotificationInfo unread_notification_info = 1;
 * @return {?proto.jp.singcolor.UnreadNotificationInfo}
 */
proto.jp.singcolor.ReadNotificationV1Response.prototype.getUnreadNotificationInfo = function () {
    return /** @type{?proto.jp.singcolor.UnreadNotificationInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.UnreadNotificationInfo, 1));
};
/**
 * @param {?proto.jp.singcolor.UnreadNotificationInfo|undefined} value
 * @return {!proto.jp.singcolor.ReadNotificationV1Response} returns this
*/
proto.jp.singcolor.ReadNotificationV1Response.prototype.setUnreadNotificationInfo = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ReadNotificationV1Response} returns this
 */
proto.jp.singcolor.ReadNotificationV1Response.prototype.clearUnreadNotificationInfo = function () {
    return this.setUnreadNotificationInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ReadNotificationV1Response.prototype.hasUnreadNotificationInfo = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserBanV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserBanV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserBanV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserBanV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserBanV1Request}
 */
proto.jp.singcolor.GetUserBanV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserBanV1Request;
    return proto.jp.singcolor.GetUserBanV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserBanV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserBanV1Request}
 */
proto.jp.singcolor.GetUserBanV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserBanV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserBanV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserBanV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserBanV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserBanV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserBanV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserBanV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserBanV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            ban: (f = msg.getBan()) && users_entity_pb.Ban.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserBanV1Response}
 */
proto.jp.singcolor.GetUserBanV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserBanV1Response;
    return proto.jp.singcolor.GetUserBanV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserBanV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserBanV1Response}
 */
proto.jp.singcolor.GetUserBanV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Ban;
                reader.readMessage(value, users_entity_pb.Ban.deserializeBinaryFromReader);
                msg.setBan(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserBanV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserBanV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserBanV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserBanV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBan();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.Ban.serializeBinaryToWriter);
    }
};
/**
 * optional Ban ban = 1;
 * @return {?proto.jp.singcolor.Ban}
 */
proto.jp.singcolor.GetUserBanV1Response.prototype.getBan = function () {
    return /** @type{?proto.jp.singcolor.Ban} */ (jspb.Message.getWrapperField(this, users_entity_pb.Ban, 1));
};
/**
 * @param {?proto.jp.singcolor.Ban|undefined} value
 * @return {!proto.jp.singcolor.GetUserBanV1Response} returns this
*/
proto.jp.singcolor.GetUserBanV1Response.prototype.setBan = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserBanV1Response} returns this
 */
proto.jp.singcolor.GetUserBanV1Response.prototype.clearBan = function () {
    return this.setBan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserBanV1Response.prototype.hasBan = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRecommendedLSingersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRecommendedLSingersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRecommendedLSingersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRecommendedLSingersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRecommendedLSingersV1Request}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRecommendedLSingersV1Request;
    return proto.jp.singcolor.ListRecommendedLSingersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRecommendedLSingersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRecommendedLSingersV1Request}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRecommendedLSingersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRecommendedLSingersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRecommendedLSingersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRecommendedLSingersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRecommendedLSingersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRecommendedLSingersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userItemWithFollowsList: jspb.Message.toObjectList(msg.getUserItemWithFollowsList(), users_entity_pb.UserItemWithFollow.toObject, includeInstance),
            month: jspb.Message.getFieldWithDefault(msg, 2, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRecommendedLSingersV1Response}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRecommendedLSingersV1Response;
    return proto.jp.singcolor.ListRecommendedLSingersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRecommendedLSingersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRecommendedLSingersV1Response}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItemWithFollow;
                reader.readMessage(value, users_entity_pb.UserItemWithFollow.deserializeBinaryFromReader);
                msg.addUserItemWithFollows(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRecommendedLSingersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRecommendedLSingersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserItemWithFollowsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.UserItemWithFollow.serializeBinaryToWriter);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * repeated UserItemWithFollow user_item_with_follows = 1;
 * @return {!Array<!proto.jp.singcolor.UserItemWithFollow>}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.getUserItemWithFollowsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.UserItemWithFollow>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserItemWithFollow, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.UserItemWithFollow>} value
 * @return {!proto.jp.singcolor.ListRecommendedLSingersV1Response} returns this
*/
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.setUserItemWithFollowsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.UserItemWithFollow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserItemWithFollow}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.addUserItemWithFollows = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.UserItemWithFollow, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListRecommendedLSingersV1Response} returns this
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.clearUserItemWithFollowsList = function () {
    return this.setUserItemWithFollowsList([]);
};
/**
 * optional int64 month = 2;
 * @return {number}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListRecommendedLSingersV1Response} returns this
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional TermOfMonth term_of_month = 3;
 * @return {!proto.jp.singcolor.TermOfMonth}
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.getTermOfMonth = function () {
    return /** @type {!proto.jp.singcolor.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.TermOfMonth} value
 * @return {!proto.jp.singcolor.ListRecommendedLSingersV1Response} returns this
 */
proto.jp.singcolor.ListRecommendedLSingersV1Response.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetTweetTimelineV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetTweetTimelineV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetTweetTimelineV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetTweetTimelineV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            filter: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetTweetTimelineV1Request}
 */
proto.jp.singcolor.GetTweetTimelineV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetTweetTimelineV1Request;
    return proto.jp.singcolor.GetTweetTimelineV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetTweetTimelineV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetTweetTimelineV1Request}
 */
proto.jp.singcolor.GetTweetTimelineV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.GetTweetTimelineV1Request.Filter} */ (reader.readEnum());
                msg.setFilter(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetTweetTimelineV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetTweetTimelineV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetTweetTimelineV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetTweetTimelineV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFilter();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.GetTweetTimelineV1Request.Filter = {
    DEFAULT: 0,
    FOLLOWING: 1
};
/**
 * optional Filter filter = 1;
 * @return {!proto.jp.singcolor.GetTweetTimelineV1Request.Filter}
 */
proto.jp.singcolor.GetTweetTimelineV1Request.prototype.getFilter = function () {
    return /** @type {!proto.jp.singcolor.GetTweetTimelineV1Request.Filter} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.GetTweetTimelineV1Request.Filter} value
 * @return {!proto.jp.singcolor.GetTweetTimelineV1Request} returns this
 */
proto.jp.singcolor.GetTweetTimelineV1Request.prototype.setFilter = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetTweetTimelineV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetTweetTimelineV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetTweetTimelineV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetTweetTimelineV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            url: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetTweetTimelineV1Response}
 */
proto.jp.singcolor.GetTweetTimelineV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetTweetTimelineV1Response;
    return proto.jp.singcolor.GetTweetTimelineV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetTweetTimelineV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetTweetTimelineV1Response}
 */
proto.jp.singcolor.GetTweetTimelineV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetTweetTimelineV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetTweetTimelineV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetTweetTimelineV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetTweetTimelineV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string url = 1;
 * @return {string}
 */
proto.jp.singcolor.GetTweetTimelineV1Response.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetTweetTimelineV1Response} returns this
 */
proto.jp.singcolor.GetTweetTimelineV1Response.prototype.setUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request}
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request;
    return proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request}
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request} returns this
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            requestStoreReviewDisplayState: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response}
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response;
    return proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response}
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.RequestStoreReviewDisplayState} */ (reader.readEnum());
                msg.setRequestStoreReviewDisplayState(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRequestStoreReviewDisplayState();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * optional RequestStoreReviewDisplayState request_store_review_display_state = 1;
 * @return {!proto.jp.singcolor.RequestStoreReviewDisplayState}
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.prototype.getRequestStoreReviewDisplayState = function () {
    return /** @type {!proto.jp.singcolor.RequestStoreReviewDisplayState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.RequestStoreReviewDisplayState} value
 * @return {!proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response} returns this
 */
proto.jp.singcolor.GetRequestStoreReviewDisplayStateV1Response.prototype.setRequestStoreReviewDisplayState = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            actionType: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request}
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SaveStoreReviewRequestActionV1Request;
    return proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request}
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.ActionType} */ (reader.readEnum());
                msg.setActionType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getActionType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.ActionType = {
    REVIEW: 0,
    NOT_NOW: 1,
    NEVER_SHOW_AGAIN: 2
};
/**
 * optional ActionType action_type = 1;
 * @return {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.ActionType}
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.prototype.getActionType = function () {
    return /** @type {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.ActionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.ActionType} value
 * @return {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Request} returns this
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Request.prototype.setActionType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Response}
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SaveStoreReviewRequestActionV1Response;
    return proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Response}
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SaveStoreReviewRequestActionV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SaveStoreReviewRequestActionV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListAvailableWebFeaturesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListAvailableWebFeaturesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListAvailableWebFeaturesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListAvailableWebFeaturesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListAvailableWebFeaturesV1Request}
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListAvailableWebFeaturesV1Request;
    return proto.jp.singcolor.ListAvailableWebFeaturesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListAvailableWebFeaturesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListAvailableWebFeaturesV1Request}
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListAvailableWebFeaturesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListAvailableWebFeaturesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListAvailableWebFeaturesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListAvailableWebFeaturesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListAvailableWebFeaturesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            availableFeaturesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response}
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListAvailableWebFeaturesV1Response;
    return proto.jp.singcolor.ListAvailableWebFeaturesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response}
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Array<!proto.jp.singcolor.ListAvailableWebFeaturesV1Response.Feature>} */ (reader.readPackedEnum());
                msg.setAvailableFeaturesList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListAvailableWebFeaturesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAvailableFeaturesList();
    if (f.length > 0) {
        writer.writePackedEnum(1, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.Feature = {
    WITHDRAW_DIAMOND: 0,
    DIAMOND_STATEMENT: 1,
    WEB_COIN_PURCHASE: 2,
    STATISTICS: 3,
    MEMBERSHIP: 4
};
/**
 * repeated Feature available_features = 1;
 * @return {!Array<!proto.jp.singcolor.ListAvailableWebFeaturesV1Response.Feature>}
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.prototype.getAvailableFeaturesList = function () {
    return /** @type {!Array<!proto.jp.singcolor.ListAvailableWebFeaturesV1Response.Feature>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListAvailableWebFeaturesV1Response.Feature>} value
 * @return {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response} returns this
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.prototype.setAvailableFeaturesList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response.Feature} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response} returns this
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.prototype.addAvailableFeatures = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListAvailableWebFeaturesV1Response} returns this
 */
proto.jp.singcolor.ListAvailableWebFeaturesV1Response.prototype.clearAvailableFeaturesList = function () {
    return this.setAvailableFeaturesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListAvailableAppFeaturesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListAvailableAppFeaturesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListAvailableAppFeaturesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListAvailableAppFeaturesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListAvailableAppFeaturesV1Request}
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListAvailableAppFeaturesV1Request;
    return proto.jp.singcolor.ListAvailableAppFeaturesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListAvailableAppFeaturesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListAvailableAppFeaturesV1Request}
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListAvailableAppFeaturesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListAvailableAppFeaturesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListAvailableAppFeaturesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListAvailableAppFeaturesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListAvailableAppFeaturesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            availableFeaturesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response}
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListAvailableAppFeaturesV1Response;
    return proto.jp.singcolor.ListAvailableAppFeaturesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response}
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Array<!proto.jp.singcolor.ListAvailableAppFeaturesV1Response.Feature>} */ (reader.readPackedEnum());
                msg.setAvailableFeaturesList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListAvailableAppFeaturesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAvailableFeaturesList();
    if (f.length > 0) {
        writer.writePackedEnum(1, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.Feature = {
    WEB_COIN_PURCHASE: 0,
    MEMBERSHIP_LISTENERS_LIST: 1,
    MEMBERSHIP_ACTIVE_PLANS_LIST: 2
};
/**
 * repeated Feature available_features = 1;
 * @return {!Array<!proto.jp.singcolor.ListAvailableAppFeaturesV1Response.Feature>}
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.prototype.getAvailableFeaturesList = function () {
    return /** @type {!Array<!proto.jp.singcolor.ListAvailableAppFeaturesV1Response.Feature>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListAvailableAppFeaturesV1Response.Feature>} value
 * @return {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response} returns this
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.prototype.setAvailableFeaturesList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response.Feature} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response} returns this
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.prototype.addAvailableFeatures = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListAvailableAppFeaturesV1Response} returns this
 */
proto.jp.singcolor.ListAvailableAppFeaturesV1Response.prototype.clearAvailableFeaturesList = function () {
    return this.setAvailableFeaturesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPearlCupRankingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPearlCupRankingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPearlCupRankingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPearlCupRankingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pearlCupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            divisionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            liveId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Request}
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPearlCupRankingV1Request;
    return proto.jp.singcolor.ListPearlCupRankingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPearlCupRankingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Request}
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPearlCupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setDivisionId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPearlCupRankingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPearlCupRankingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPearlCupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDivisionId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string pearl_cup_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.prototype.getPearlCupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Request} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.prototype.setPearlCupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string division_id = 2;
 * @return {string}
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.prototype.getDivisionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Request} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.prototype.setDivisionId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string live_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Request} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.repeatedFields_ = [4, 6, 7, 8, 9, 10, 11];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPearlCupRankingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPearlCupRankingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPearlCupRankingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            nextPearlCupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            prePearlCupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            pearlCup: (f = msg.getPearlCup()) && users_entity_pb.PearlCup.toObject(includeInstance, f),
            divisionsList: jspb.Message.toObjectList(msg.getDivisionsList(), users_entity_pb.PearlCupDivision.toObject, includeInstance),
            targetDivision: (f = msg.getTargetDivision()) && users_entity_pb.PearlCupDivisionWithPrize.toObject(includeInstance, f),
            platinumLiversList: jspb.Message.toObjectList(msg.getPlatinumLiversList(), users_entity_pb.RankingLiver.toObject, includeInstance),
            goldLiversList: jspb.Message.toObjectList(msg.getGoldLiversList(), users_entity_pb.RankingLiver.toObject, includeInstance),
            silverLiversList: jspb.Message.toObjectList(msg.getSilverLiversList(), users_entity_pb.RankingLiver.toObject, includeInstance),
            bronzeLiversList: jspb.Message.toObjectList(msg.getBronzeLiversList(), users_entity_pb.RankingLiver.toObject, includeInstance),
            spiritLiversList: jspb.Message.toObjectList(msg.getSpiritLiversList(), users_entity_pb.RankingLiver.toObject, includeInstance),
            otherLiversList: jspb.Message.toObjectList(msg.getOtherLiversList(), users_entity_pb.RankingLiver.toObject, includeInstance),
            message: jspb.Message.getFieldWithDefault(msg, 12, ""),
            minLiveScorePlatinum: jspb.Message.getFieldWithDefault(msg, 13, 0),
            minLiveScoreGold: jspb.Message.getFieldWithDefault(msg, 14, 0),
            minLiveScoreSilver: jspb.Message.getFieldWithDefault(msg, 15, 0),
            minLiveScoreBronze: jspb.Message.getFieldWithDefault(msg, 16, 0),
            minLiveScoreSpirit: jspb.Message.getFieldWithDefault(msg, 17, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPearlCupRankingV1Response;
    return proto.jp.singcolor.ListPearlCupRankingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPearlCupRankingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPearlCupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPrePearlCupId(value);
                break;
            case 3:
                var value = new users_entity_pb.PearlCup;
                reader.readMessage(value, users_entity_pb.PearlCup.deserializeBinaryFromReader);
                msg.setPearlCup(value);
                break;
            case 4:
                var value = new users_entity_pb.PearlCupDivision;
                reader.readMessage(value, users_entity_pb.PearlCupDivision.deserializeBinaryFromReader);
                msg.addDivisions(value);
                break;
            case 5:
                var value = new users_entity_pb.PearlCupDivisionWithPrize;
                reader.readMessage(value, users_entity_pb.PearlCupDivisionWithPrize.deserializeBinaryFromReader);
                msg.setTargetDivision(value);
                break;
            case 6:
                var value = new users_entity_pb.RankingLiver;
                reader.readMessage(value, users_entity_pb.RankingLiver.deserializeBinaryFromReader);
                msg.addPlatinumLivers(value);
                break;
            case 7:
                var value = new users_entity_pb.RankingLiver;
                reader.readMessage(value, users_entity_pb.RankingLiver.deserializeBinaryFromReader);
                msg.addGoldLivers(value);
                break;
            case 8:
                var value = new users_entity_pb.RankingLiver;
                reader.readMessage(value, users_entity_pb.RankingLiver.deserializeBinaryFromReader);
                msg.addSilverLivers(value);
                break;
            case 9:
                var value = new users_entity_pb.RankingLiver;
                reader.readMessage(value, users_entity_pb.RankingLiver.deserializeBinaryFromReader);
                msg.addBronzeLivers(value);
                break;
            case 10:
                var value = new users_entity_pb.RankingLiver;
                reader.readMessage(value, users_entity_pb.RankingLiver.deserializeBinaryFromReader);
                msg.addSpiritLivers(value);
                break;
            case 11:
                var value = new users_entity_pb.RankingLiver;
                reader.readMessage(value, users_entity_pb.RankingLiver.deserializeBinaryFromReader);
                msg.addOtherLivers(value);
                break;
            case 12:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessage(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMinLiveScorePlatinum(value);
                break;
            case 14:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMinLiveScoreGold(value);
                break;
            case 15:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMinLiveScoreSilver(value);
                break;
            case 16:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMinLiveScoreBronze(value);
                break;
            case 17:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMinLiveScoreSpirit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPearlCupRankingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPearlCupRankingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getNextPearlCupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPrePearlCupId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getPearlCup();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.PearlCup.serializeBinaryToWriter);
    }
    f = message.getDivisionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, users_entity_pb.PearlCupDivision.serializeBinaryToWriter);
    }
    f = message.getTargetDivision();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.PearlCupDivisionWithPrize.serializeBinaryToWriter);
    }
    f = message.getPlatinumLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(6, f, users_entity_pb.RankingLiver.serializeBinaryToWriter);
    }
    f = message.getGoldLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(7, f, users_entity_pb.RankingLiver.serializeBinaryToWriter);
    }
    f = message.getSilverLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(8, f, users_entity_pb.RankingLiver.serializeBinaryToWriter);
    }
    f = message.getBronzeLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(9, f, users_entity_pb.RankingLiver.serializeBinaryToWriter);
    }
    f = message.getSpiritLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(10, f, users_entity_pb.RankingLiver.serializeBinaryToWriter);
    }
    f = message.getOtherLiversList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(11, f, users_entity_pb.RankingLiver.serializeBinaryToWriter);
    }
    f = message.getMessage();
    if (f.length > 0) {
        writer.writeString(12, f);
    }
    f = message.getMinLiveScorePlatinum();
    if (f !== 0) {
        writer.writeInt64(13, f);
    }
    f = message.getMinLiveScoreGold();
    if (f !== 0) {
        writer.writeInt64(14, f);
    }
    f = message.getMinLiveScoreSilver();
    if (f !== 0) {
        writer.writeInt64(15, f);
    }
    f = message.getMinLiveScoreBronze();
    if (f !== 0) {
        writer.writeInt64(16, f);
    }
    f = message.getMinLiveScoreSpirit();
    if (f !== 0) {
        writer.writeInt64(17, f);
    }
};
/**
 * optional string next_pearl_cup_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getNextPearlCupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setNextPearlCupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string pre_pearl_cup_id = 2;
 * @return {string}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getPrePearlCupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setPrePearlCupId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional PearlCup pearl_cup = 3;
 * @return {?proto.jp.singcolor.PearlCup}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getPearlCup = function () {
    return /** @type{?proto.jp.singcolor.PearlCup} */ (jspb.Message.getWrapperField(this, users_entity_pb.PearlCup, 3));
};
/**
 * @param {?proto.jp.singcolor.PearlCup|undefined} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setPearlCup = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearPearlCup = function () {
    return this.setPearlCup(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.hasPearlCup = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * repeated PearlCupDivision divisions = 4;
 * @return {!Array<!proto.jp.singcolor.PearlCupDivision>}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getDivisionsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.PearlCupDivision>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.PearlCupDivision, 4));
};
/**
 * @param {!Array<!proto.jp.singcolor.PearlCupDivision>} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setDivisionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.jp.singcolor.PearlCupDivision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.PearlCupDivision}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.addDivisions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jp.singcolor.PearlCupDivision, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearDivisionsList = function () {
    return this.setDivisionsList([]);
};
/**
 * optional PearlCupDivisionWithPrize target_division = 5;
 * @return {?proto.jp.singcolor.PearlCupDivisionWithPrize}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getTargetDivision = function () {
    return /** @type{?proto.jp.singcolor.PearlCupDivisionWithPrize} */ (jspb.Message.getWrapperField(this, users_entity_pb.PearlCupDivisionWithPrize, 5));
};
/**
 * @param {?proto.jp.singcolor.PearlCupDivisionWithPrize|undefined} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setTargetDivision = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearTargetDivision = function () {
    return this.setTargetDivision(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.hasTargetDivision = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * repeated RankingLiver platinum_livers = 6;
 * @return {!Array<!proto.jp.singcolor.RankingLiver>}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getPlatinumLiversList = function () {
    return /** @type{!Array<!proto.jp.singcolor.RankingLiver>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.RankingLiver, 6));
};
/**
 * @param {!Array<!proto.jp.singcolor.RankingLiver>} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setPlatinumLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 6, value);
};
/**
 * @param {!proto.jp.singcolor.RankingLiver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RankingLiver}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.addPlatinumLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.jp.singcolor.RankingLiver, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearPlatinumLiversList = function () {
    return this.setPlatinumLiversList([]);
};
/**
 * repeated RankingLiver gold_livers = 7;
 * @return {!Array<!proto.jp.singcolor.RankingLiver>}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getGoldLiversList = function () {
    return /** @type{!Array<!proto.jp.singcolor.RankingLiver>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.RankingLiver, 7));
};
/**
 * @param {!Array<!proto.jp.singcolor.RankingLiver>} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setGoldLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 7, value);
};
/**
 * @param {!proto.jp.singcolor.RankingLiver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RankingLiver}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.addGoldLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.jp.singcolor.RankingLiver, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearGoldLiversList = function () {
    return this.setGoldLiversList([]);
};
/**
 * repeated RankingLiver silver_livers = 8;
 * @return {!Array<!proto.jp.singcolor.RankingLiver>}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getSilverLiversList = function () {
    return /** @type{!Array<!proto.jp.singcolor.RankingLiver>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.RankingLiver, 8));
};
/**
 * @param {!Array<!proto.jp.singcolor.RankingLiver>} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setSilverLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 8, value);
};
/**
 * @param {!proto.jp.singcolor.RankingLiver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RankingLiver}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.addSilverLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.jp.singcolor.RankingLiver, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearSilverLiversList = function () {
    return this.setSilverLiversList([]);
};
/**
 * repeated RankingLiver bronze_livers = 9;
 * @return {!Array<!proto.jp.singcolor.RankingLiver>}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getBronzeLiversList = function () {
    return /** @type{!Array<!proto.jp.singcolor.RankingLiver>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.RankingLiver, 9));
};
/**
 * @param {!Array<!proto.jp.singcolor.RankingLiver>} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setBronzeLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 9, value);
};
/**
 * @param {!proto.jp.singcolor.RankingLiver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RankingLiver}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.addBronzeLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.jp.singcolor.RankingLiver, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearBronzeLiversList = function () {
    return this.setBronzeLiversList([]);
};
/**
 * repeated RankingLiver spirit_livers = 10;
 * @return {!Array<!proto.jp.singcolor.RankingLiver>}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getSpiritLiversList = function () {
    return /** @type{!Array<!proto.jp.singcolor.RankingLiver>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.RankingLiver, 10));
};
/**
 * @param {!Array<!proto.jp.singcolor.RankingLiver>} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setSpiritLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 10, value);
};
/**
 * @param {!proto.jp.singcolor.RankingLiver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RankingLiver}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.addSpiritLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.jp.singcolor.RankingLiver, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearSpiritLiversList = function () {
    return this.setSpiritLiversList([]);
};
/**
 * repeated RankingLiver other_livers = 11;
 * @return {!Array<!proto.jp.singcolor.RankingLiver>}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getOtherLiversList = function () {
    return /** @type{!Array<!proto.jp.singcolor.RankingLiver>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.RankingLiver, 11));
};
/**
 * @param {!Array<!proto.jp.singcolor.RankingLiver>} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setOtherLiversList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 11, value);
};
/**
 * @param {!proto.jp.singcolor.RankingLiver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RankingLiver}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.addOtherLivers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.jp.singcolor.RankingLiver, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.clearOtherLiversList = function () {
    return this.setOtherLiversList([]);
};
/**
 * optional string message = 12;
 * @return {string}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 12, value);
};
/**
 * optional int64 min_live_score_platinum = 13;
 * @return {number}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getMinLiveScorePlatinum = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setMinLiveScorePlatinum = function (value) {
    return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * optional int64 min_live_score_gold = 14;
 * @return {number}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getMinLiveScoreGold = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setMinLiveScoreGold = function (value) {
    return jspb.Message.setProto3IntField(this, 14, value);
};
/**
 * optional int64 min_live_score_silver = 15;
 * @return {number}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getMinLiveScoreSilver = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setMinLiveScoreSilver = function (value) {
    return jspb.Message.setProto3IntField(this, 15, value);
};
/**
 * optional int64 min_live_score_bronze = 16;
 * @return {number}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getMinLiveScoreBronze = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setMinLiveScoreBronze = function (value) {
    return jspb.Message.setProto3IntField(this, 16, value);
};
/**
 * optional int64 min_live_score_spirit = 17;
 * @return {number}
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.getMinLiveScoreSpirit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListPearlCupRankingV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupRankingV1Response.prototype.setMinLiveScoreSpirit = function (value) {
    return jspb.Message.setProto3IntField(this, 17, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Request}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetPearlPrizeItemDreamV1Request;
    return proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Request}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Request} returns this
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            pearlCupPrizeItemDream: (f = msg.getPearlCupPrizeItemDream()) && users_entity_pb.PearlCupPrizeItem.toObject(includeInstance, f),
            pearlAmount: (f = msg.getPearlAmount()) && pearls_entity_pb.PearlAmount.toObject(includeInstance, f),
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetPearlPrizeItemDreamV1Response;
    return proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.PearlCupPrizeItem;
                reader.readMessage(value, users_entity_pb.PearlCupPrizeItem.deserializeBinaryFromReader);
                msg.setPearlCupPrizeItemDream(value);
                break;
            case 2:
                var value = new pearls_entity_pb.PearlAmount;
                reader.readMessage(value, pearls_entity_pb.PearlAmount.deserializeBinaryFromReader);
                msg.setPearlAmount(value);
                break;
            case 3:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPearlCupPrizeItemDream();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.PearlCupPrizeItem.serializeBinaryToWriter);
    }
    f = message.getPearlAmount();
    if (f != null) {
        writer.writeMessage(2, f, pearls_entity_pb.PearlAmount.serializeBinaryToWriter);
    }
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
};
/**
 * optional PearlCupPrizeItem pearl_cup_prize_item_dream = 1;
 * @return {?proto.jp.singcolor.PearlCupPrizeItem}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.getPearlCupPrizeItemDream = function () {
    return /** @type{?proto.jp.singcolor.PearlCupPrizeItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.PearlCupPrizeItem, 1));
};
/**
 * @param {?proto.jp.singcolor.PearlCupPrizeItem|undefined} value
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} returns this
*/
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.setPearlCupPrizeItemDream = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} returns this
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.clearPearlCupPrizeItemDream = function () {
    return this.setPearlCupPrizeItemDream(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.hasPearlCupPrizeItemDream = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional PearlAmount pearl_amount = 2;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.getPearlAmount = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, pearls_entity_pb.PearlAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} returns this
*/
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.setPearlAmount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} returns this
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.clearPearlAmount = function () {
    return this.setPearlAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.hasPearlAmount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserItem user_item = 3;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.getUserItem = function () {
    return /** @type{?proto.jp.singcolor.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 3));
};
/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} returns this
*/
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetPearlPrizeItemDreamV1Response} returns this
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetPearlPrizeItemDreamV1Response.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Request}
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPearlCupPrizeItemsV1Request;
    return proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Request}
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            prizeItemsList: jspb.Message.toObjectList(msg.getPrizeItemsList(), users_entity_pb.PearlCupPrizeItem.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Response}
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPearlCupPrizeItemsV1Response;
    return proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Response}
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.PearlCupPrizeItem;
                reader.readMessage(value, users_entity_pb.PearlCupPrizeItem.deserializeBinaryFromReader);
                msg.addPrizeItems(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPrizeItemsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.PearlCupPrizeItem.serializeBinaryToWriter);
    }
};
/**
 * repeated PearlCupPrizeItem prize_items = 1;
 * @return {!Array<!proto.jp.singcolor.PearlCupPrizeItem>}
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.prototype.getPrizeItemsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.PearlCupPrizeItem>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.PearlCupPrizeItem, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.PearlCupPrizeItem>} value
 * @return {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Response} returns this
*/
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.prototype.setPrizeItemsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.PearlCupPrizeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.PearlCupPrizeItem}
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.prototype.addPrizeItems = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.PearlCupPrizeItem, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlCupPrizeItemsV1Response} returns this
 */
proto.jp.singcolor.ListPearlCupPrizeItemsV1Response.prototype.clearPrizeItemsList = function () {
    return this.setPrizeItemsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pearlCupPrizeItemId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request;
    return proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPearlCupPrizeItemId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPearlCupPrizeItemId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string pearl_cup_prize_item_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.prototype.getPearlCupPrizeItemId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request} returns this
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Request.prototype.setPearlCupPrizeItemId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            pearlCupPrizeItemDream: (f = msg.getPearlCupPrizeItemDream()) && users_entity_pb.PearlCupPrizeItem.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response;
    return proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.PearlCupPrizeItem;
                reader.readMessage(value, users_entity_pb.PearlCupPrizeItem.deserializeBinaryFromReader);
                msg.setPearlCupPrizeItemDream(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPearlCupPrizeItemDream();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.PearlCupPrizeItem.serializeBinaryToWriter);
    }
};
/**
 * optional PearlCupPrizeItem pearl_cup_prize_item_dream = 1;
 * @return {?proto.jp.singcolor.PearlCupPrizeItem}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.prototype.getPearlCupPrizeItemDream = function () {
    return /** @type{?proto.jp.singcolor.PearlCupPrizeItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.PearlCupPrizeItem, 1));
};
/**
 * @param {?proto.jp.singcolor.PearlCupPrizeItem|undefined} value
 * @return {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response} returns this
*/
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.prototype.setPearlCupPrizeItemDream = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response} returns this
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.prototype.clearPearlCupPrizeItemDream = function () {
    return this.setPearlCupPrizeItemDream(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdatePearlPrizeItemDreamV1Response.prototype.hasPearlCupPrizeItemDream = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetListenerMembershipInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetListenerMembershipInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetListenerMembershipInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetListenerMembershipInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenerId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetListenerMembershipInfoV1Request}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetListenerMembershipInfoV1Request;
    return proto.jp.singcolor.GetListenerMembershipInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetListenerMembershipInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetListenerMembershipInfoV1Request}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetListenerMembershipInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetListenerMembershipInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetListenerMembershipInfoV1Request} returns this
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string listener_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetListenerMembershipInfoV1Request} returns this
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetListenerMembershipInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetListenerMembershipInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetListenerMembershipInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetListenerMembershipInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetListenerMembershipInfoV1Response}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetListenerMembershipInfoV1Response;
    return proto.jp.singcolor.GetListenerMembershipInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetListenerMembershipInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetListenerMembershipInfoV1Response}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetListenerMembershipInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetListenerMembershipInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 1;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Response.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 1));
};
/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.GetListenerMembershipInfoV1Response} returns this
*/
proto.jp.singcolor.GetListenerMembershipInfoV1Response.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetListenerMembershipInfoV1Response} returns this
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Response.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetListenerMembershipInfoV1Response.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiverMembershipInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiverMembershipInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiverMembershipInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiverMembershipInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Request}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiverMembershipInfoV1Request;
    return proto.jp.singcolor.GetLiverMembershipInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiverMembershipInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Request}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiverMembershipInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiverMembershipInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Request} returns this
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiverMembershipInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiverMembershipInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiverMembershipInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            membershipListenersCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
            gospelCoinAmount: (f = msg.getGospelCoinAmount()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            gospelCoinRecoveryAmountPerDay: (f = msg.getGospelCoinRecoveryAmountPerDay()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Response}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiverMembershipInfoV1Response;
    return proto.jp.singcolor.GetLiverMembershipInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiverMembershipInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Response}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMembershipListenersCount(value);
                break;
            case 2:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setGospelCoinAmount(value);
                break;
            case 3:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setGospelCoinRecoveryAmountPerDay(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiverMembershipInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiverMembershipInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMembershipListenersCount();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getGospelCoinAmount();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getGospelCoinRecoveryAmountPerDay();
    if (f != null) {
        writer.writeMessage(3, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
};
/**
 * optional int64 membership_listeners_count = 1;
 * @return {number}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.getMembershipListenersCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.setMembershipListenersCount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional CoinAmount gospel_coin_amount = 2;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.getGospelCoinAmount = function () {
    return /** @type{?proto.jp.singcolor.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.setGospelCoinAmount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.clearGospelCoinAmount = function () {
    return this.setGospelCoinAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.hasGospelCoinAmount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional CoinAmount gospel_coin_recovery_amount_per_day = 3;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.getGospelCoinRecoveryAmountPerDay = function () {
    return /** @type{?proto.jp.singcolor.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 3));
};
/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.setGospelCoinRecoveryAmountPerDay = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLiverMembershipInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.clearGospelCoinRecoveryAmountPerDay = function () {
    return this.setGospelCoinRecoveryAmountPerDay(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLiverMembershipInfoV1Response.prototype.hasGospelCoinRecoveryAmountPerDay = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request}
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request;
    return proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request}
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response}
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response;
    return proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response}
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addUserIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};
/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.prototype.getUserIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response} returns this
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.prototype.setUserIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response} returns this
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.prototype.addUserIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response} returns this
 */
proto.jp.singcolor.ListBlockingAndBlockedUserIDsV1Response.prototype.clearUserIdsList = function () {
    return this.setUserIdsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Request}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLivePermissionReviewDialogV1Request;
    return proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Request}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            showDialog: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            dialogTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
            dialogMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Response}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLivePermissionReviewDialogV1Response;
    return proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Response}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setShowDialog(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setDialogTitle(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDialogMessage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getShowDialog();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getDialogTitle();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getDialogMessage();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional bool show_dialog = 1;
 * @return {boolean}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.prototype.getShowDialog = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Response} returns this
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.prototype.setShowDialog = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional string dialog_title = 2;
 * @return {string}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.prototype.getDialogTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Response} returns this
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.prototype.setDialogTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string dialog_message = 3;
 * @return {string}
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.prototype.getDialogMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLivePermissionReviewDialogV1Response} returns this
 */
proto.jp.singcolor.GetLivePermissionReviewDialogV1Response.prototype.setDialogMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateModeratorV1Request}
 */
proto.jp.singcolor.CreateModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateModeratorV1Request;
    return proto.jp.singcolor.CreateModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateModeratorV1Request}
 */
proto.jp.singcolor.CreateModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateModeratorV1Request.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateModeratorV1Request} returns this
 */
proto.jp.singcolor.CreateModeratorV1Request.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            moderator: (f = msg.getModerator()) && users_entity_pb.Moderator.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateModeratorV1Response}
 */
proto.jp.singcolor.CreateModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateModeratorV1Response;
    return proto.jp.singcolor.CreateModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateModeratorV1Response}
 */
proto.jp.singcolor.CreateModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Moderator;
                reader.readMessage(value, users_entity_pb.Moderator.deserializeBinaryFromReader);
                msg.setModerator(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getModerator();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.Moderator.serializeBinaryToWriter);
    }
};
/**
 * optional Moderator moderator = 1;
 * @return {?proto.jp.singcolor.Moderator}
 */
proto.jp.singcolor.CreateModeratorV1Response.prototype.getModerator = function () {
    return /** @type{?proto.jp.singcolor.Moderator} */ (jspb.Message.getWrapperField(this, users_entity_pb.Moderator, 1));
};
/**
 * @param {?proto.jp.singcolor.Moderator|undefined} value
 * @return {!proto.jp.singcolor.CreateModeratorV1Response} returns this
*/
proto.jp.singcolor.CreateModeratorV1Response.prototype.setModerator = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateModeratorV1Response} returns this
 */
proto.jp.singcolor.CreateModeratorV1Response.prototype.clearModerator = function () {
    return this.setModerator(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateModeratorV1Response.prototype.hasModerator = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListModeratorsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListModeratorsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListModeratorsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListModeratorsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListModeratorsV1Request}
 */
proto.jp.singcolor.ListModeratorsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListModeratorsV1Request;
    return proto.jp.singcolor.ListModeratorsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListModeratorsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListModeratorsV1Request}
 */
proto.jp.singcolor.ListModeratorsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListModeratorsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListModeratorsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListModeratorsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListModeratorsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListModeratorsV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListModeratorsV1Request} returns this
 */
proto.jp.singcolor.ListModeratorsV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListModeratorsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListModeratorsV1Request} returns this
 */
proto.jp.singcolor.ListModeratorsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListModeratorsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListModeratorsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListModeratorsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListModeratorsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListModeratorsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            moderatorsList: jspb.Message.toObjectList(msg.getModeratorsList(), users_entity_pb.Moderator.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListModeratorsV1Response}
 */
proto.jp.singcolor.ListModeratorsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListModeratorsV1Response;
    return proto.jp.singcolor.ListModeratorsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListModeratorsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListModeratorsV1Response}
 */
proto.jp.singcolor.ListModeratorsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Moderator;
                reader.readMessage(value, users_entity_pb.Moderator.deserializeBinaryFromReader);
                msg.addModerators(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListModeratorsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListModeratorsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListModeratorsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListModeratorsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getModeratorsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.Moderator.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated Moderator moderators = 1;
 * @return {!Array<!proto.jp.singcolor.Moderator>}
 */
proto.jp.singcolor.ListModeratorsV1Response.prototype.getModeratorsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Moderator>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.Moderator, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Moderator>} value
 * @return {!proto.jp.singcolor.ListModeratorsV1Response} returns this
*/
proto.jp.singcolor.ListModeratorsV1Response.prototype.setModeratorsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Moderator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Moderator}
 */
proto.jp.singcolor.ListModeratorsV1Response.prototype.addModerators = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Moderator, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListModeratorsV1Response} returns this
 */
proto.jp.singcolor.ListModeratorsV1Response.prototype.clearModeratorsList = function () {
    return this.setModeratorsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListModeratorsV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListModeratorsV1Response} returns this
 */
proto.jp.singcolor.ListModeratorsV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListModeratorsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListModeratorsV1Response} returns this
 */
proto.jp.singcolor.ListModeratorsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.TerminateModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.TerminateModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.TerminateModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.TerminateModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.TerminateModeratorV1Request}
 */
proto.jp.singcolor.TerminateModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.TerminateModeratorV1Request;
    return proto.jp.singcolor.TerminateModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.TerminateModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.TerminateModeratorV1Request}
 */
proto.jp.singcolor.TerminateModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.TerminateModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.TerminateModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.TerminateModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.TerminateModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.TerminateModeratorV1Request.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.TerminateModeratorV1Request} returns this
 */
proto.jp.singcolor.TerminateModeratorV1Request.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.TerminateModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.TerminateModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.TerminateModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.TerminateModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.TerminateModeratorV1Response}
 */
proto.jp.singcolor.TerminateModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.TerminateModeratorV1Response;
    return proto.jp.singcolor.TerminateModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.TerminateModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.TerminateModeratorV1Response}
 */
proto.jp.singcolor.TerminateModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.TerminateModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.TerminateModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.TerminateModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.TerminateModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetModeratorV1Request}
 */
proto.jp.singcolor.GetModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetModeratorV1Request;
    return proto.jp.singcolor.GetModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetModeratorV1Request}
 */
proto.jp.singcolor.GetModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetModeratorV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetModeratorV1Request} returns this
 */
proto.jp.singcolor.GetModeratorV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetModeratorV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetModeratorV1Request} returns this
 */
proto.jp.singcolor.GetModeratorV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            moderator: (f = msg.getModerator()) && users_entity_pb.Moderator.toObject(includeInstance, f),
            endAtWhenBecome: jspb.Message.getFieldWithDefault(msg, 2, 0),
            canBeModerator: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetModeratorV1Response}
 */
proto.jp.singcolor.GetModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetModeratorV1Response;
    return proto.jp.singcolor.GetModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetModeratorV1Response}
 */
proto.jp.singcolor.GetModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.Moderator;
                reader.readMessage(value, users_entity_pb.Moderator.deserializeBinaryFromReader);
                msg.setModerator(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEndAtWhenBecome(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanBeModerator(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getModerator();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.Moderator.serializeBinaryToWriter);
    }
    f = message.getEndAtWhenBecome();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getCanBeModerator();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional Moderator moderator = 1;
 * @return {?proto.jp.singcolor.Moderator}
 */
proto.jp.singcolor.GetModeratorV1Response.prototype.getModerator = function () {
    return /** @type{?proto.jp.singcolor.Moderator} */ (jspb.Message.getWrapperField(this, users_entity_pb.Moderator, 1));
};
/**
 * @param {?proto.jp.singcolor.Moderator|undefined} value
 * @return {!proto.jp.singcolor.GetModeratorV1Response} returns this
*/
proto.jp.singcolor.GetModeratorV1Response.prototype.setModerator = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetModeratorV1Response} returns this
 */
proto.jp.singcolor.GetModeratorV1Response.prototype.clearModerator = function () {
    return this.setModerator(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetModeratorV1Response.prototype.hasModerator = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 end_at_when_become = 2;
 * @return {number}
 */
proto.jp.singcolor.GetModeratorV1Response.prototype.getEndAtWhenBecome = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetModeratorV1Response} returns this
 */
proto.jp.singcolor.GetModeratorV1Response.prototype.setEndAtWhenBecome = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional bool can_be_moderator = 3;
 * @return {boolean}
 */
proto.jp.singcolor.GetModeratorV1Response.prototype.getCanBeModerator = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetModeratorV1Response} returns this
 */
proto.jp.singcolor.GetModeratorV1Response.prototype.setCanBeModerator = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DoBlockByModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DoBlockByModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DoBlockByModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DoBlockByModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DoBlockByModeratorV1Request}
 */
proto.jp.singcolor.DoBlockByModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DoBlockByModeratorV1Request;
    return proto.jp.singcolor.DoBlockByModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DoBlockByModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DoBlockByModeratorV1Request}
 */
proto.jp.singcolor.DoBlockByModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DoBlockByModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DoBlockByModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DoBlockByModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DoBlockByModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DoBlockByModeratorV1Request.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DoBlockByModeratorV1Request} returns this
 */
proto.jp.singcolor.DoBlockByModeratorV1Request.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.DoBlockByModeratorV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DoBlockByModeratorV1Request} returns this
 */
proto.jp.singcolor.DoBlockByModeratorV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DoBlockByModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DoBlockByModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DoBlockByModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DoBlockByModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBlock: (f = msg.getUserBlock()) && users_entity_pb.UserBlock.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DoBlockByModeratorV1Response}
 */
proto.jp.singcolor.DoBlockByModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DoBlockByModeratorV1Response;
    return proto.jp.singcolor.DoBlockByModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DoBlockByModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DoBlockByModeratorV1Response}
 */
proto.jp.singcolor.DoBlockByModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserBlock;
                reader.readMessage(value, users_entity_pb.UserBlock.deserializeBinaryFromReader);
                msg.setUserBlock(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DoBlockByModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DoBlockByModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DoBlockByModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DoBlockByModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBlock();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserBlock.serializeBinaryToWriter);
    }
};
/**
 * optional UserBlock user_block = 1;
 * @return {?proto.jp.singcolor.UserBlock}
 */
proto.jp.singcolor.DoBlockByModeratorV1Response.prototype.getUserBlock = function () {
    return /** @type{?proto.jp.singcolor.UserBlock} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBlock, 1));
};
/**
 * @param {?proto.jp.singcolor.UserBlock|undefined} value
 * @return {!proto.jp.singcolor.DoBlockByModeratorV1Response} returns this
*/
proto.jp.singcolor.DoBlockByModeratorV1Response.prototype.setUserBlock = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DoBlockByModeratorV1Response} returns this
 */
proto.jp.singcolor.DoBlockByModeratorV1Response.prototype.clearUserBlock = function () {
    return this.setUserBlock(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DoBlockByModeratorV1Response.prototype.hasUserBlock = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnblockByModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnblockByModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnblockByModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnblockByModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnblockByModeratorV1Request}
 */
proto.jp.singcolor.UnblockByModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnblockByModeratorV1Request;
    return proto.jp.singcolor.UnblockByModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnblockByModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnblockByModeratorV1Request}
 */
proto.jp.singcolor.UnblockByModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnblockByModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnblockByModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnblockByModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnblockByModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string target_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UnblockByModeratorV1Request.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnblockByModeratorV1Request} returns this
 */
proto.jp.singcolor.UnblockByModeratorV1Request.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UnblockByModeratorV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnblockByModeratorV1Request} returns this
 */
proto.jp.singcolor.UnblockByModeratorV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnblockByModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnblockByModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnblockByModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnblockByModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBlock: (f = msg.getUserBlock()) && users_entity_pb.UserBlock.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnblockByModeratorV1Response}
 */
proto.jp.singcolor.UnblockByModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnblockByModeratorV1Response;
    return proto.jp.singcolor.UnblockByModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnblockByModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnblockByModeratorV1Response}
 */
proto.jp.singcolor.UnblockByModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserBlock;
                reader.readMessage(value, users_entity_pb.UserBlock.deserializeBinaryFromReader);
                msg.setUserBlock(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnblockByModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnblockByModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnblockByModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnblockByModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBlock();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserBlock.serializeBinaryToWriter);
    }
};
/**
 * optional UserBlock user_block = 1;
 * @return {?proto.jp.singcolor.UserBlock}
 */
proto.jp.singcolor.UnblockByModeratorV1Response.prototype.getUserBlock = function () {
    return /** @type{?proto.jp.singcolor.UserBlock} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBlock, 1));
};
/**
 * @param {?proto.jp.singcolor.UserBlock|undefined} value
 * @return {!proto.jp.singcolor.UnblockByModeratorV1Response} returns this
*/
proto.jp.singcolor.UnblockByModeratorV1Response.prototype.setUserBlock = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UnblockByModeratorV1Response} returns this
 */
proto.jp.singcolor.UnblockByModeratorV1Response.prototype.clearUserBlock = function () {
    return this.setUserBlock(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UnblockByModeratorV1Response.prototype.hasUserBlock = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateGenderV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateGenderV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateGenderV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateGenderV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            gender: jspb.Message.getFieldWithDefault(msg, 1, 0),
            isGenderHidden: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateGenderV1Request}
 */
proto.jp.singcolor.UpdateGenderV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateGenderV1Request;
    return proto.jp.singcolor.UpdateGenderV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateGenderV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateGenderV1Request}
 */
proto.jp.singcolor.UpdateGenderV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setGender(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsGenderHidden(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateGenderV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateGenderV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateGenderV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateGenderV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGender();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getIsGenderHidden();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional Gender gender = 1;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.UpdateGenderV1Request.prototype.getGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.UpdateGenderV1Request} returns this
 */
proto.jp.singcolor.UpdateGenderV1Request.prototype.setGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional bool is_gender_hidden = 2;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateGenderV1Request.prototype.getIsGenderHidden = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateGenderV1Request} returns this
 */
proto.jp.singcolor.UpdateGenderV1Request.prototype.setIsGenderHidden = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateGenderV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateGenderV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateGenderV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateGenderV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userProfile: (f = msg.getUserProfile()) && users_entity_pb.UserProfile.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateGenderV1Response}
 */
proto.jp.singcolor.UpdateGenderV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateGenderV1Response;
    return proto.jp.singcolor.UpdateGenderV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateGenderV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateGenderV1Response}
 */
proto.jp.singcolor.UpdateGenderV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserProfile;
                reader.readMessage(value, users_entity_pb.UserProfile.deserializeBinaryFromReader);
                msg.setUserProfile(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateGenderV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateGenderV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateGenderV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateGenderV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserProfile();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserProfile.serializeBinaryToWriter);
    }
};
/**
 * optional UserProfile user_profile = 1;
 * @return {?proto.jp.singcolor.UserProfile}
 */
proto.jp.singcolor.UpdateGenderV1Response.prototype.getUserProfile = function () {
    return /** @type{?proto.jp.singcolor.UserProfile} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserProfile, 1));
};
/**
 * @param {?proto.jp.singcolor.UserProfile|undefined} value
 * @return {!proto.jp.singcolor.UpdateGenderV1Response} returns this
*/
proto.jp.singcolor.UpdateGenderV1Response.prototype.setUserProfile = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateGenderV1Response} returns this
 */
proto.jp.singcolor.UpdateGenderV1Response.prototype.clearUserProfile = function () {
    return this.setUserProfile(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateGenderV1Response.prototype.hasUserProfile = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenerId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request;
    return proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request} returns this
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string listener_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request} returns this
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            isBlocking: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response;
    return proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsBlocking(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIsBlocking();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool is_blocking = 1;
 * @return {boolean}
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.prototype.getIsBlocking = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response} returns this
 */
proto.jp.singcolor.GetIsLiverBlockingListenerForModeratorV1Response.prototype.setIsBlocking = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            imagePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
            isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request;
    return proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setImagePath(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsEnabled(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getImagePath();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getIsEnabled();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string image_path = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.prototype.getImagePath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.prototype.setImagePath = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool is_enabled = 3;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.prototype.getIsEnabled = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Request.prototype.setIsEnabled = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response;
    return proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerSongFaveThumbnailV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request;
    return proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request} returns this
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerSongFaveThumbnail: (f = msg.getListenerSongFaveThumbnail()) && users_entity_pb.ListenerSongFaveThumbnail.toObject(includeInstance, f),
            isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            canUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response;
    return proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.ListenerSongFaveThumbnail;
                reader.readMessage(value, users_entity_pb.ListenerSongFaveThumbnail.deserializeBinaryFromReader);
                msg.setListenerSongFaveThumbnail(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsEnabled(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanUpdate(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerSongFaveThumbnail();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.ListenerSongFaveThumbnail.serializeBinaryToWriter);
    }
    f = message.getIsEnabled();
    if (f) {
        writer.writeBool(2, f);
    }
    f = message.getCanUpdate();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional ListenerSongFaveThumbnail listener_song_fave_thumbnail = 1;
 * @return {?proto.jp.singcolor.ListenerSongFaveThumbnail}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.getListenerSongFaveThumbnail = function () {
    return /** @type{?proto.jp.singcolor.ListenerSongFaveThumbnail} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerSongFaveThumbnail, 1));
};
/**
 * @param {?proto.jp.singcolor.ListenerSongFaveThumbnail|undefined} value
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response} returns this
*/
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.setListenerSongFaveThumbnail = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response} returns this
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.clearListenerSongFaveThumbnail = function () {
    return this.setListenerSongFaveThumbnail(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.hasListenerSongFaveThumbnail = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.getIsEnabled = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response} returns this
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.setIsEnabled = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
/**
 * optional bool can_update = 3;
 * @return {boolean}
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.getCanUpdate = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response} returns this
 */
proto.jp.singcolor.GetListenerSongFaveThumbnailV1Response.prototype.setCanUpdate = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request;
    return proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.repeatedFields_ = [1, 2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            availableOptionsList: jspb.Message.toObjectList(msg.getAvailableOptionsList(), users_entity_pb.LiverEvaluationCriterionImportance.toObject, includeInstance),
            selectedOptionsList: jspb.Message.toObjectList(msg.getSelectedOptionsList(), users_entity_pb.LiverEvaluationCriterionImportance.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response;
    return proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.LiverEvaluationCriterionImportance;
                reader.readMessage(value, users_entity_pb.LiverEvaluationCriterionImportance.deserializeBinaryFromReader);
                msg.addAvailableOptions(value);
                break;
            case 2:
                var value = new users_entity_pb.LiverEvaluationCriterionImportance;
                reader.readMessage(value, users_entity_pb.LiverEvaluationCriterionImportance.deserializeBinaryFromReader);
                msg.addSelectedOptions(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAvailableOptionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.LiverEvaluationCriterionImportance.serializeBinaryToWriter);
    }
    f = message.getSelectedOptionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, users_entity_pb.LiverEvaluationCriterionImportance.serializeBinaryToWriter);
    }
};
/**
 * repeated LiverEvaluationCriterionImportance available_options = 1;
 * @return {!Array<!proto.jp.singcolor.LiverEvaluationCriterionImportance>}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.getAvailableOptionsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiverEvaluationCriterionImportance>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.LiverEvaluationCriterionImportance, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiverEvaluationCriterionImportance>} value
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response} returns this
*/
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.setAvailableOptionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiverEvaluationCriterionImportance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiverEvaluationCriterionImportance}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.addAvailableOptions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiverEvaluationCriterionImportance, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response} returns this
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.clearAvailableOptionsList = function () {
    return this.setAvailableOptionsList([]);
};
/**
 * repeated LiverEvaluationCriterionImportance selected_options = 2;
 * @return {!Array<!proto.jp.singcolor.LiverEvaluationCriterionImportance>}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.getSelectedOptionsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiverEvaluationCriterionImportance>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.LiverEvaluationCriterionImportance, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiverEvaluationCriterionImportance>} value
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response} returns this
*/
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.setSelectedOptionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.LiverEvaluationCriterionImportance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiverEvaluationCriterionImportance}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.addSelectedOptions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.LiverEvaluationCriterionImportance, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response} returns this
 */
proto.jp.singcolor.ListLiverEvaluationCriterionImportanceV1Response.prototype.clearSelectedOptionsList = function () {
    return this.setSelectedOptionsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverEvaluationCriterionImportanceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request;
    return proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addLiverEvaluationCriterionImportanceIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverEvaluationCriterionImportanceIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};
/**
 * repeated string liver_evaluation_criterion_importance_ids = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.prototype.getLiverEvaluationCriterionImportanceIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request} returns this
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.prototype.setLiverEvaluationCriterionImportanceIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request} returns this
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.prototype.addLiverEvaluationCriterionImportanceIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request} returns this
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Request.prototype.clearLiverEvaluationCriterionImportanceIdsList = function () {
    return this.setLiverEvaluationCriterionImportanceIdsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response;
    return proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionImportanceV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request;
    return proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.repeatedFields_ = [1, 2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            availableOptionsList: jspb.Message.toObjectList(msg.getAvailableOptionsList(), users_entity_pb.LiverEvaluationCriterionAvoidance.toObject, includeInstance),
            selectedOptionsList: jspb.Message.toObjectList(msg.getSelectedOptionsList(), users_entity_pb.LiverEvaluationCriterionAvoidanceLevel.toObject, includeInstance),
            willingToViewOnlyFaves: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response;
    return proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.LiverEvaluationCriterionAvoidance;
                reader.readMessage(value, users_entity_pb.LiverEvaluationCriterionAvoidance.deserializeBinaryFromReader);
                msg.addAvailableOptions(value);
                break;
            case 2:
                var value = new users_entity_pb.LiverEvaluationCriterionAvoidanceLevel;
                reader.readMessage(value, users_entity_pb.LiverEvaluationCriterionAvoidanceLevel.deserializeBinaryFromReader);
                msg.addSelectedOptions(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setWillingToViewOnlyFaves(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAvailableOptionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.LiverEvaluationCriterionAvoidance.serializeBinaryToWriter);
    }
    f = message.getSelectedOptionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, users_entity_pb.LiverEvaluationCriterionAvoidanceLevel.serializeBinaryToWriter);
    }
    f = message.getWillingToViewOnlyFaves();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated LiverEvaluationCriterionAvoidance available_options = 1;
 * @return {!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidance>}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.getAvailableOptionsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidance>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.LiverEvaluationCriterionAvoidance, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidance>} value
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response} returns this
*/
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.setAvailableOptionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiverEvaluationCriterionAvoidance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiverEvaluationCriterionAvoidance}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.addAvailableOptions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiverEvaluationCriterionAvoidance, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response} returns this
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.clearAvailableOptionsList = function () {
    return this.setAvailableOptionsList([]);
};
/**
 * repeated LiverEvaluationCriterionAvoidanceLevel selected_options = 2;
 * @return {!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel>}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.getSelectedOptionsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.LiverEvaluationCriterionAvoidanceLevel, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel>} value
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response} returns this
*/
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.setSelectedOptionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.addSelectedOptions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response} returns this
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.clearSelectedOptionsList = function () {
    return this.setSelectedOptionsList([]);
};
/**
 * optional bool willing_to_view_only_faves = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.getWillingToViewOnlyFaves = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response} returns this
 */
proto.jp.singcolor.ListLiverEvaluationCriterionAvoidancesV1Response.prototype.setWillingToViewOnlyFaves = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            selectedOptionsList: jspb.Message.toObjectList(msg.getSelectedOptionsList(), users_entity_pb.LiverEvaluationCriterionAvoidanceLevel.toObject, includeInstance),
            willingToViewOnlyFaves: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request;
    return proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.LiverEvaluationCriterionAvoidanceLevel;
                reader.readMessage(value, users_entity_pb.LiverEvaluationCriterionAvoidanceLevel.deserializeBinaryFromReader);
                msg.addSelectedOptions(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setWillingToViewOnlyFaves(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSelectedOptionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.LiverEvaluationCriterionAvoidanceLevel.serializeBinaryToWriter);
    }
    f = message.getWillingToViewOnlyFaves();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * repeated LiverEvaluationCriterionAvoidanceLevel selected_options = 1;
 * @return {!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel>}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.prototype.getSelectedOptionsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.LiverEvaluationCriterionAvoidanceLevel, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel>} value
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request} returns this
*/
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.prototype.setSelectedOptionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.prototype.addSelectedOptions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiverEvaluationCriterionAvoidanceLevel, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request} returns this
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.prototype.clearSelectedOptionsList = function () {
    return this.setSelectedOptionsList([]);
};
/**
 * optional bool willing_to_view_only_faves = 2;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.prototype.getWillingToViewOnlyFaves = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request} returns this
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Request.prototype.setWillingToViewOnlyFaves = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response;
    return proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLiverEvaluationCriterionAvoidancesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request}
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request;
    return proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request}
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            shouldUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response}
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response;
    return proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response}
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setShouldUpdate(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getShouldUpdate();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool should_update = 1;
 * @return {boolean}
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.prototype.getShouldUpdate = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response} returns this
 */
proto.jp.singcolor.ShouldUpdateLiverEvaluationCriterionV1Response.prototype.setShouldUpdate = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
goog.object.extend(exports, proto.jp.singcolor);
