"use strict";
// source: organization_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var lives_entity_pb = require('./lives_entity_pb.js');
goog.object.extend(proto, lives_entity_pb);
var photo_entity_pb = require('./photo_entity_pb.js');
goog.object.extend(proto, photo_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
goog.exportSymbol('proto.jp.singcolor.ApplicationStatus', null, global);
goog.exportSymbol('proto.jp.singcolor.Organization', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationEditableParam', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiver', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiverApplication', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiverDetail', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiverGroup', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiverGroupLiver', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiverLeaveHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiverLeaveType', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiverLivePermission', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationMonthlyDiamondHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationRedashExportHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationStaff', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationStaffApplication', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationStaffRole', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationStaffRoleUpdateHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.OrganizationUpdateHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.RedashDashboard', null, global);
goog.exportSymbol('proto.jp.singcolor.SupportPlan', null, global);
goog.exportSymbol('proto.jp.singcolor.SupportPlanCashOutMethod', null, global);
goog.exportSymbol('proto.jp.singcolor.SupportPlanCreationHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.SupportPlanEditableParam', null, global);
goog.exportSymbol('proto.jp.singcolor.SupportPlanStatus', null, global);
goog.exportSymbol('proto.jp.singcolor.SupportPlanTag', null, global);
goog.exportSymbol('proto.jp.singcolor.SupportPlanUpdateHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.SupportPlanWithApplicationStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Organization = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.Organization.displayName = 'proto.jp.singcolor.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SupportPlan = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.SupportPlan.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.SupportPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SupportPlan.displayName = 'proto.jp.singcolor.SupportPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SupportPlanCreationHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SupportPlanCreationHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SupportPlanCreationHistory.displayName = 'proto.jp.singcolor.SupportPlanCreationHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SupportPlanUpdateHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SupportPlanUpdateHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SupportPlanUpdateHistory.displayName = 'proto.jp.singcolor.SupportPlanUpdateHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SupportPlanTag = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SupportPlanTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SupportPlanTag.displayName = 'proto.jp.singcolor.SupportPlanTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationStaff = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationStaff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationStaff.displayName = 'proto.jp.singcolor.OrganizationStaff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationStaffApplication = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationStaffApplication, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationStaffApplication.displayName = 'proto.jp.singcolor.OrganizationStaffApplication';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationLiver = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationLiver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationLiver.displayName = 'proto.jp.singcolor.OrganizationLiver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationLiverApplication = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationLiverApplication, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationLiverApplication.displayName = 'proto.jp.singcolor.OrganizationLiverApplication';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SupportPlanWithApplicationStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SupportPlanWithApplicationStatus.displayName = 'proto.jp.singcolor.SupportPlanWithApplicationStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RedashDashboard = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RedashDashboard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RedashDashboard.displayName = 'proto.jp.singcolor.RedashDashboard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationRedashExportHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationRedashExportHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationRedashExportHistory.displayName = 'proto.jp.singcolor.OrganizationRedashExportHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationUpdateHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationUpdateHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationUpdateHistory.displayName = 'proto.jp.singcolor.OrganizationUpdateHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationStaffRoleUpdateHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.displayName = 'proto.jp.singcolor.OrganizationStaffRoleUpdateHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationLiverLeaveHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationLiverLeaveHistory.displayName = 'proto.jp.singcolor.OrganizationLiverLeaveHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationLiverDetail = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationLiverDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationLiverDetail.displayName = 'proto.jp.singcolor.OrganizationLiverDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationLiverGroup = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationLiverGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationLiverGroup.displayName = 'proto.jp.singcolor.OrganizationLiverGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationLiverGroupLiver = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationLiverGroupLiver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationLiverGroupLiver.displayName = 'proto.jp.singcolor.OrganizationLiverGroupLiver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationMonthlyDiamondHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationMonthlyDiamondHistory.displayName = 'proto.jp.singcolor.OrganizationMonthlyDiamondHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.displayName = 'proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OrganizationLiverLivePermission = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OrganizationLiverLivePermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OrganizationLiverLivePermission.displayName = 'proto.jp.singcolor.OrganizationLiverLivePermission';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.Organization.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.Organization.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.Organization} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.Organization.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            code: jspb.Message.getFieldWithDefault(msg, 2, ""),
            name: jspb.Message.getFieldWithDefault(msg, 3, ""),
            description: jspb.Message.getFieldWithDefault(msg, 4, ""),
            url: jspb.Message.getFieldWithDefault(msg, 5, ""),
            thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
            twitterScreenName: jspb.Message.getFieldWithDefault(msg, 7, ""),
            createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
            thumbnailUrls: (f = msg.getThumbnailUrls()) && photo_entity_pb.ImageURL.toObject(includeInstance, f),
            diamondRewardValidityStartAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
            diamondRewardValidityEndAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
            diamondRewardRate10000x: jspb.Message.getFieldWithDefault(msg, 13, 0),
            contactUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
            contactButtonTitle: jspb.Message.getFieldWithDefault(msg, 15, ""),
            hideStaffList: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Organization}
 */
proto.jp.singcolor.Organization.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.Organization;
    return proto.jp.singcolor.Organization.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Organization}
 */
proto.jp.singcolor.Organization.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCode(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setUrl(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setThumbnailUrl(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setTwitterScreenName(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            case 10:
                var value = new photo_entity_pb.ImageURL;
                reader.readMessage(value, photo_entity_pb.ImageURL.deserializeBinaryFromReader);
                msg.setThumbnailUrls(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondRewardValidityStartAt(value);
                break;
            case 12:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondRewardValidityEndAt(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondRewardRate10000x(value);
                break;
            case 14:
                var value = /** @type {string} */ (reader.readString());
                msg.setContactUrl(value);
                break;
            case 15:
                var value = /** @type {string} */ (reader.readString());
                msg.setContactButtonTitle(value);
                break;
            case 16:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHideStaffList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Organization.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.Organization.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Organization.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCode();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getThumbnailUrl();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getTwitterScreenName();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getThumbnailUrls();
    if (f != null) {
        writer.writeMessage(10, f, photo_entity_pb.ImageURL.serializeBinaryToWriter);
    }
    f = message.getDiamondRewardValidityStartAt();
    if (f !== 0) {
        writer.writeInt64(11, f);
    }
    f = message.getDiamondRewardValidityEndAt();
    if (f !== 0) {
        writer.writeInt64(12, f);
    }
    f = message.getDiamondRewardRate10000x();
    if (f !== 0) {
        writer.writeInt64(13, f);
    }
    f = message.getContactUrl();
    if (f.length > 0) {
        writer.writeString(14, f);
    }
    f = message.getContactButtonTitle();
    if (f.length > 0) {
        writer.writeString(15, f);
    }
    f = message.getHideStaffList();
    if (f) {
        writer.writeBool(16, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string code = 2;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setCode = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string name = 3;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string description = 4;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string url = 5;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string thumbnail_url = 6;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getThumbnailUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setThumbnailUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string twitter_screen_name = 7;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getTwitterScreenName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setTwitterScreenName = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.jp.singcolor.Organization.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.jp.singcolor.Organization.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional ImageURL thumbnail_urls = 10;
 * @return {?proto.jp.singcolor.ImageURL}
 */
proto.jp.singcolor.Organization.prototype.getThumbnailUrls = function () {
    return /** @type{?proto.jp.singcolor.ImageURL} */ (jspb.Message.getWrapperField(this, photo_entity_pb.ImageURL, 10));
};
/**
 * @param {?proto.jp.singcolor.ImageURL|undefined} value
 * @return {!proto.jp.singcolor.Organization} returns this
*/
proto.jp.singcolor.Organization.prototype.setThumbnailUrls = function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.clearThumbnailUrls = function () {
    return this.setThumbnailUrls(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Organization.prototype.hasThumbnailUrls = function () {
    return jspb.Message.getField(this, 10) != null;
};
/**
 * optional int64 diamond_reward_validity_start_at = 11;
 * @return {number}
 */
proto.jp.singcolor.Organization.prototype.getDiamondRewardValidityStartAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setDiamondRewardValidityStartAt = function (value) {
    return jspb.Message.setProto3IntField(this, 11, value);
};
/**
 * optional int64 diamond_reward_validity_end_at = 12;
 * @return {number}
 */
proto.jp.singcolor.Organization.prototype.getDiamondRewardValidityEndAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setDiamondRewardValidityEndAt = function (value) {
    return jspb.Message.setProto3IntField(this, 12, value);
};
/**
 * optional int64 diamond_reward_rate_10000x = 13;
 * @return {number}
 */
proto.jp.singcolor.Organization.prototype.getDiamondRewardRate10000x = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setDiamondRewardRate10000x = function (value) {
    return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * optional string contact_url = 14;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getContactUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setContactUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 14, value);
};
/**
 * optional string contact_button_title = 15;
 * @return {string}
 */
proto.jp.singcolor.Organization.prototype.getContactButtonTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setContactButtonTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 15, value);
};
/**
 * optional bool hide_staff_list = 16;
 * @return {boolean}
 */
proto.jp.singcolor.Organization.prototype.getHideStaffList = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Organization} returns this
 */
proto.jp.singcolor.Organization.prototype.setHideStaffList = function (value) {
    return jspb.Message.setProto3BooleanField(this, 16, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.SupportPlan.repeatedFields_ = [8];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SupportPlan.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SupportPlan.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SupportPlan} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SupportPlan.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            name: jspb.Message.getFieldWithDefault(msg, 3, ""),
            description: jspb.Message.getFieldWithDefault(msg, 4, ""),
            status: jspb.Message.getFieldWithDefault(msg, 5, 0),
            cashOutMethod: jspb.Message.getFieldWithDefault(msg, 6, 0),
            organizerCommissionRate10000x: jspb.Message.getFieldWithDefault(msg, 7, 0),
            supportPlanTagsList: jspb.Message.toObjectList(msg.getSupportPlanTagsList(), proto.jp.singcolor.SupportPlanTag.toObject, includeInstance),
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.Organization.toObject(includeInstance, f),
            createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 11, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SupportPlan}
 */
proto.jp.singcolor.SupportPlan.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SupportPlan;
    return proto.jp.singcolor.SupportPlan.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SupportPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SupportPlan}
 */
proto.jp.singcolor.SupportPlan.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.SupportPlanStatus} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 6:
                var value = /** @type {!proto.jp.singcolor.SupportPlanCashOutMethod} */ (reader.readEnum());
                msg.setCashOutMethod(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOrganizerCommissionRate10000x(value);
                break;
            case 8:
                var value = new proto.jp.singcolor.SupportPlanTag;
                reader.readMessage(value, proto.jp.singcolor.SupportPlanTag.deserializeBinaryFromReader);
                msg.addSupportPlanTags(value);
                break;
            case 9:
                var value = new proto.jp.singcolor.Organization;
                reader.readMessage(value, proto.jp.singcolor.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SupportPlan.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SupportPlan.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SupportPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SupportPlan.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getCashOutMethod();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
    f = message.getOrganizerCommissionRate10000x();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getSupportPlanTagsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(8, f, proto.jp.singcolor.SupportPlanTag.serializeBinaryToWriter);
    }
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(9, f, proto.jp.singcolor.Organization.serializeBinaryToWriter);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(11, f);
    }
};
/**
 * optional string support_plan_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SupportPlan.prototype.getSupportPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setSupportPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.SupportPlan.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string name = 3;
 * @return {string}
 */
proto.jp.singcolor.SupportPlan.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string description = 4;
 * @return {string}
 */
proto.jp.singcolor.SupportPlan.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional SupportPlanStatus status = 5;
 * @return {!proto.jp.singcolor.SupportPlanStatus}
 */
proto.jp.singcolor.SupportPlan.prototype.getStatus = function () {
    return /** @type {!proto.jp.singcolor.SupportPlanStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.SupportPlanStatus} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional SupportPlanCashOutMethod cash_out_method = 6;
 * @return {!proto.jp.singcolor.SupportPlanCashOutMethod}
 */
proto.jp.singcolor.SupportPlan.prototype.getCashOutMethod = function () {
    return /** @type {!proto.jp.singcolor.SupportPlanCashOutMethod} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {!proto.jp.singcolor.SupportPlanCashOutMethod} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setCashOutMethod = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional int64 organizer_commission_rate_10000x = 7;
 * @return {number}
 */
proto.jp.singcolor.SupportPlan.prototype.getOrganizerCommissionRate10000x = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setOrganizerCommissionRate10000x = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * repeated SupportPlanTag support_plan_tags = 8;
 * @return {!Array<!proto.jp.singcolor.SupportPlanTag>}
 */
proto.jp.singcolor.SupportPlan.prototype.getSupportPlanTagsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.SupportPlanTag>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.SupportPlanTag, 8));
};
/**
 * @param {!Array<!proto.jp.singcolor.SupportPlanTag>} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
*/
proto.jp.singcolor.SupportPlan.prototype.setSupportPlanTagsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 8, value);
};
/**
 * @param {!proto.jp.singcolor.SupportPlanTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.SupportPlanTag}
 */
proto.jp.singcolor.SupportPlan.prototype.addSupportPlanTags = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.jp.singcolor.SupportPlanTag, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.clearSupportPlanTagsList = function () {
    return this.setSupportPlanTagsList([]);
};
/**
 * optional Organization organization = 9;
 * @return {?proto.jp.singcolor.Organization}
 */
proto.jp.singcolor.SupportPlan.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.Organization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.Organization, 9));
};
/**
 * @param {?proto.jp.singcolor.Organization|undefined} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
*/
proto.jp.singcolor.SupportPlan.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SupportPlan.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 9) != null;
};
/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.jp.singcolor.SupportPlan.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * optional int64 updated_at = 11;
 * @return {number}
 */
proto.jp.singcolor.SupportPlan.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SupportPlan} returns this
 */
proto.jp.singcolor.SupportPlan.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 11, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SupportPlanCreationHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SupportPlanCreationHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SupportPlanCreationHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SupportPlanCreationHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanCreationHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            supportPlan: (f = msg.getSupportPlan()) && proto.jp.singcolor.SupportPlan.toObject(includeInstance, f),
            userName: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SupportPlanCreationHistory}
 */
proto.jp.singcolor.SupportPlanCreationHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SupportPlanCreationHistory;
    return proto.jp.singcolor.SupportPlanCreationHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SupportPlanCreationHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SupportPlanCreationHistory}
 */
proto.jp.singcolor.SupportPlanCreationHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanCreationHistoryId(value);
                break;
            case 2:
                var value = new proto.jp.singcolor.SupportPlan;
                reader.readMessage(value, proto.jp.singcolor.SupportPlan.deserializeBinaryFromReader);
                msg.setSupportPlan(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SupportPlanCreationHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SupportPlanCreationHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SupportPlanCreationHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SupportPlanCreationHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanCreationHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSupportPlan();
    if (f != null) {
        writer.writeMessage(2, f, proto.jp.singcolor.SupportPlan.serializeBinaryToWriter);
    }
    f = message.getUserName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string support_plan_creation_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanCreationHistory.prototype.getSupportPlanCreationHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanCreationHistory} returns this
 */
proto.jp.singcolor.SupportPlanCreationHistory.prototype.setSupportPlanCreationHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional SupportPlan support_plan = 2;
 * @return {?proto.jp.singcolor.SupportPlan}
 */
proto.jp.singcolor.SupportPlanCreationHistory.prototype.getSupportPlan = function () {
    return /** @type{?proto.jp.singcolor.SupportPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.SupportPlan, 2));
};
/**
 * @param {?proto.jp.singcolor.SupportPlan|undefined} value
 * @return {!proto.jp.singcolor.SupportPlanCreationHistory} returns this
*/
proto.jp.singcolor.SupportPlanCreationHistory.prototype.setSupportPlan = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SupportPlanCreationHistory} returns this
 */
proto.jp.singcolor.SupportPlanCreationHistory.prototype.clearSupportPlan = function () {
    return this.setSupportPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SupportPlanCreationHistory.prototype.hasSupportPlan = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string user_name = 3;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanCreationHistory.prototype.getUserName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanCreationHistory} returns this
 */
proto.jp.singcolor.SupportPlanCreationHistory.prototype.setUserName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SupportPlanUpdateHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SupportPlanUpdateHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SupportPlanUpdateHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SupportPlanUpdateHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlanUpdateHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            editedParam: jspb.Message.getFieldWithDefault(msg, 2, 0),
            before: jspb.Message.getFieldWithDefault(msg, 3, ""),
            after: jspb.Message.getFieldWithDefault(msg, 4, ""),
            createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
            userName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            supportPlanName: jspb.Message.getFieldWithDefault(msg, 7, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SupportPlanUpdateHistory;
    return proto.jp.singcolor.SupportPlanUpdateHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SupportPlanUpdateHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanUpdateHistoryId(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.SupportPlanEditableParam} */ (reader.readEnum());
                msg.setEditedParam(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setBefore(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAfter(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserName(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SupportPlanUpdateHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SupportPlanUpdateHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SupportPlanUpdateHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlanUpdateHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getEditedParam();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getBefore();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getAfter();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getUserName();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getSupportPlanName();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};
/**
 * optional string support_plan_update_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.getSupportPlanUpdateHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory} returns this
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.setSupportPlanUpdateHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional SupportPlanEditableParam edited_param = 2;
 * @return {!proto.jp.singcolor.SupportPlanEditableParam}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.getEditedParam = function () {
    return /** @type {!proto.jp.singcolor.SupportPlanEditableParam} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.SupportPlanEditableParam} value
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory} returns this
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.setEditedParam = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string before = 3;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.getBefore = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory} returns this
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.setBefore = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string after = 4;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.getAfter = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory} returns this
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.setAfter = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory} returns this
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional string user_name = 6;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.getUserName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory} returns this
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.setUserName = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string support_plan_name = 7;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.getSupportPlanName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanUpdateHistory} returns this
 */
proto.jp.singcolor.SupportPlanUpdateHistory.prototype.setSupportPlanName = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SupportPlanTag.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SupportPlanTag.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SupportPlanTag} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SupportPlanTag.toObject = function (includeInstance, msg) {
        var f, obj = {
            tagId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SupportPlanTag}
 */
proto.jp.singcolor.SupportPlanTag.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SupportPlanTag;
    return proto.jp.singcolor.SupportPlanTag.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SupportPlanTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SupportPlanTag}
 */
proto.jp.singcolor.SupportPlanTag.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTagId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SupportPlanTag.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SupportPlanTag.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SupportPlanTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SupportPlanTag.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTagId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string tag_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanTag.prototype.getTagId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanTag} returns this
 */
proto.jp.singcolor.SupportPlanTag.prototype.setTagId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.SupportPlanTag.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SupportPlanTag} returns this
 */
proto.jp.singcolor.SupportPlanTag.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationStaff.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationStaff.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationStaff} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationStaff.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            role: jspb.Message.getFieldWithDefault(msg, 3, 0),
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.Organization.toObject(includeInstance, f),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationStaff}
 */
proto.jp.singcolor.OrganizationStaff.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationStaff;
    return proto.jp.singcolor.OrganizationStaff.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationStaff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationStaff}
 */
proto.jp.singcolor.OrganizationStaff.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.OrganizationStaffRole} */ (reader.readEnum());
                msg.setRole(value);
                break;
            case 4:
                var value = new proto.jp.singcolor.Organization;
                reader.readMessage(value, proto.jp.singcolor.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            case 5:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationStaff.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationStaff.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationStaff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationStaff.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getRole();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(4, f, proto.jp.singcolor.Organization.serializeBinaryToWriter);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaff.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
 */
proto.jp.singcolor.OrganizationStaff.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaff.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
 */
proto.jp.singcolor.OrganizationStaff.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional OrganizationStaffRole role = 3;
 * @return {!proto.jp.singcolor.OrganizationStaffRole}
 */
proto.jp.singcolor.OrganizationStaff.prototype.getRole = function () {
    return /** @type {!proto.jp.singcolor.OrganizationStaffRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.OrganizationStaffRole} value
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
 */
proto.jp.singcolor.OrganizationStaff.prototype.setRole = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional Organization organization = 4;
 * @return {?proto.jp.singcolor.Organization}
 */
proto.jp.singcolor.OrganizationStaff.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.Organization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.Organization, 4));
};
/**
 * @param {?proto.jp.singcolor.Organization|undefined} value
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
*/
proto.jp.singcolor.OrganizationStaff.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
 */
proto.jp.singcolor.OrganizationStaff.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationStaff.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional User user = 5;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationStaff.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 5));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
*/
proto.jp.singcolor.OrganizationStaff.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
 */
proto.jp.singcolor.OrganizationStaff.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationStaff.prototype.hasUser = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.jp.singcolor.OrganizationStaff.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
 */
proto.jp.singcolor.OrganizationStaff.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.jp.singcolor.OrganizationStaff.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationStaff} returns this
 */
proto.jp.singcolor.OrganizationStaff.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationStaffApplication.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationStaffApplication.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationStaffApplication} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationStaffApplication.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffApplicationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            approvedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
            rejectedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.Organization.toObject(includeInstance, f),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
            examinatedUser: (f = msg.getExaminatedUser()) && users_entity_pb.User.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationStaffApplication}
 */
proto.jp.singcolor.OrganizationStaffApplication.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationStaffApplication;
    return proto.jp.singcolor.OrganizationStaffApplication.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationStaffApplication} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationStaffApplication}
 */
proto.jp.singcolor.OrganizationStaffApplication.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationStaffApplicationId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setApprovedAt(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRejectedAt(value);
                break;
            case 6:
                var value = new proto.jp.singcolor.Organization;
                reader.readMessage(value, proto.jp.singcolor.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            case 7:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            case 10:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setExaminatedUser(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationStaffApplication.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationStaffApplication} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationStaffApplication.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffApplicationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getApprovedAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getRejectedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(6, f, proto.jp.singcolor.Organization.serializeBinaryToWriter);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(7, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getExaminatedUser();
    if (f != null) {
        writer.writeMessage(10, f, users_entity_pb.User.serializeBinaryToWriter);
    }
};
/**
 * optional string organization_staff_application_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getOrganizationStaffApplicationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.setOrganizationStaffApplicationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 approved_at = 4;
 * @return {number}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getApprovedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.setApprovedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 rejected_at = 5;
 * @return {number}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getRejectedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.setRejectedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional Organization organization = 6;
 * @return {?proto.jp.singcolor.Organization}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.Organization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.Organization, 6));
};
/**
 * @param {?proto.jp.singcolor.Organization|undefined} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
*/
proto.jp.singcolor.OrganizationStaffApplication.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional User user = 7;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 7));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
*/
proto.jp.singcolor.OrganizationStaffApplication.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.hasUser = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional User examinated_user = 10;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.getExaminatedUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 10));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
*/
proto.jp.singcolor.OrganizationStaffApplication.prototype.setExaminatedUser = function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationStaffApplication} returns this
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.clearExaminatedUser = function () {
    return this.setExaminatedUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationStaffApplication.prototype.hasExaminatedUser = function () {
    return jspb.Message.getField(this, 10) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationLiver.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationLiver.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationLiver} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationLiver.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            supportPlanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.Organization.toObject(includeInstance, f),
            supportPlan: (f = msg.getSupportPlan()) && proto.jp.singcolor.SupportPlan.toObject(includeInstance, f),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationLiver}
 */
proto.jp.singcolor.OrganizationLiver.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationLiver;
    return proto.jp.singcolor.OrganizationLiver.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationLiver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationLiver}
 */
proto.jp.singcolor.OrganizationLiver.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = new proto.jp.singcolor.Organization;
                reader.readMessage(value, proto.jp.singcolor.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            case 5:
                var value = new proto.jp.singcolor.SupportPlan;
                reader.readMessage(value, proto.jp.singcolor.SupportPlan.deserializeBinaryFromReader);
                msg.setSupportPlan(value);
                break;
            case 6:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationLiver.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationLiver.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationLiver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationLiver.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSupportPlanId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(4, f, proto.jp.singcolor.Organization.serializeBinaryToWriter);
    }
    f = message.getSupportPlan();
    if (f != null) {
        writer.writeMessage(5, f, proto.jp.singcolor.SupportPlan.serializeBinaryToWriter);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(6, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiver.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
 */
proto.jp.singcolor.OrganizationLiver.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string support_plan_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiver.prototype.getSupportPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
 */
proto.jp.singcolor.OrganizationLiver.prototype.setSupportPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiver.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
 */
proto.jp.singcolor.OrganizationLiver.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional Organization organization = 4;
 * @return {?proto.jp.singcolor.Organization}
 */
proto.jp.singcolor.OrganizationLiver.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.Organization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.Organization, 4));
};
/**
 * @param {?proto.jp.singcolor.Organization|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
*/
proto.jp.singcolor.OrganizationLiver.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
 */
proto.jp.singcolor.OrganizationLiver.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiver.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional SupportPlan support_plan = 5;
 * @return {?proto.jp.singcolor.SupportPlan}
 */
proto.jp.singcolor.OrganizationLiver.prototype.getSupportPlan = function () {
    return /** @type{?proto.jp.singcolor.SupportPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.SupportPlan, 5));
};
/**
 * @param {?proto.jp.singcolor.SupportPlan|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
*/
proto.jp.singcolor.OrganizationLiver.prototype.setSupportPlan = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
 */
proto.jp.singcolor.OrganizationLiver.prototype.clearSupportPlan = function () {
    return this.setSupportPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiver.prototype.hasSupportPlan = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional User user = 6;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationLiver.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 6));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
*/
proto.jp.singcolor.OrganizationLiver.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
 */
proto.jp.singcolor.OrganizationLiver.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiver.prototype.hasUser = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiver.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
 */
proto.jp.singcolor.OrganizationLiver.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 updated_at = 8;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiver.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiver} returns this
 */
proto.jp.singcolor.OrganizationLiver.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationLiverApplication.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationLiverApplication.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationLiverApplication} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationLiverApplication.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverApplicationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            supportPlanId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            approvedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
            rejectedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.Organization.toObject(includeInstance, f),
            supportPlan: (f = msg.getSupportPlan()) && proto.jp.singcolor.SupportPlan.toObject(includeInstance, f),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
            examinatedUser: (f = msg.getExaminatedUser()) && users_entity_pb.User.toObject(includeInstance, f),
            hasDiamondBalance: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationLiverApplication}
 */
proto.jp.singcolor.OrganizationLiverApplication.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationLiverApplication;
    return proto.jp.singcolor.OrganizationLiverApplication.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationLiverApplication} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationLiverApplication}
 */
proto.jp.singcolor.OrganizationLiverApplication.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverApplicationId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setSupportPlanId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setApprovedAt(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRejectedAt(value);
                break;
            case 7:
                var value = new proto.jp.singcolor.Organization;
                reader.readMessage(value, proto.jp.singcolor.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            case 8:
                var value = new proto.jp.singcolor.SupportPlan;
                reader.readMessage(value, proto.jp.singcolor.SupportPlan.deserializeBinaryFromReader);
                msg.setSupportPlan(value);
                break;
            case 9:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            case 12:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setExaminatedUser(value);
                break;
            case 13:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasDiamondBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationLiverApplication.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationLiverApplication} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationLiverApplication.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverApplicationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getSupportPlanId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getApprovedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getRejectedAt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(7, f, proto.jp.singcolor.Organization.serializeBinaryToWriter);
    }
    f = message.getSupportPlan();
    if (f != null) {
        writer.writeMessage(8, f, proto.jp.singcolor.SupportPlan.serializeBinaryToWriter);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(9, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(11, f);
    }
    f = message.getExaminatedUser();
    if (f != null) {
        writer.writeMessage(12, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getHasDiamondBalance();
    if (f) {
        writer.writeBool(13, f);
    }
};
/**
 * optional string organization_liver_application_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getOrganizationLiverApplicationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setOrganizationLiverApplicationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string support_plan_id = 3;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getSupportPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setSupportPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional int64 approved_at = 5;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getApprovedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setApprovedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 rejected_at = 6;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getRejectedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setRejectedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional Organization organization = 7;
 * @return {?proto.jp.singcolor.Organization}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.Organization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.Organization, 7));
};
/**
 * @param {?proto.jp.singcolor.Organization|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
*/
proto.jp.singcolor.OrganizationLiverApplication.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional SupportPlan support_plan = 8;
 * @return {?proto.jp.singcolor.SupportPlan}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getSupportPlan = function () {
    return /** @type{?proto.jp.singcolor.SupportPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.SupportPlan, 8));
};
/**
 * @param {?proto.jp.singcolor.SupportPlan|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
*/
proto.jp.singcolor.OrganizationLiverApplication.prototype.setSupportPlan = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.clearSupportPlan = function () {
    return this.setSupportPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.hasSupportPlan = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional User user = 9;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 9));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
*/
proto.jp.singcolor.OrganizationLiverApplication.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.hasUser = function () {
    return jspb.Message.getField(this, 9) != null;
};
/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * optional int64 updated_at = 11;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 11, value);
};
/**
 * optional User examinated_user = 12;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getExaminatedUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 12));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
*/
proto.jp.singcolor.OrganizationLiverApplication.prototype.setExaminatedUser = function (value) {
    return jspb.Message.setWrapperField(this, 12, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.clearExaminatedUser = function () {
    return this.setExaminatedUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.hasExaminatedUser = function () {
    return jspb.Message.getField(this, 12) != null;
};
/**
 * optional bool has_diamond_balance = 13;
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.getHasDiamondBalance = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.OrganizationLiverApplication} returns this
 */
proto.jp.singcolor.OrganizationLiverApplication.prototype.setHasDiamondBalance = function (value) {
    return jspb.Message.setProto3BooleanField(this, 13, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SupportPlanWithApplicationStatus.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SupportPlanWithApplicationStatus.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SupportPlanWithApplicationStatus} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SupportPlanWithApplicationStatus.toObject = function (includeInstance, msg) {
        var f, obj = {
            supportPlan: (f = msg.getSupportPlan()) && proto.jp.singcolor.SupportPlan.toObject(includeInstance, f),
            applicationStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SupportPlanWithApplicationStatus}
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SupportPlanWithApplicationStatus;
    return proto.jp.singcolor.SupportPlanWithApplicationStatus.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SupportPlanWithApplicationStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SupportPlanWithApplicationStatus}
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.SupportPlan;
                reader.readMessage(value, proto.jp.singcolor.SupportPlan.deserializeBinaryFromReader);
                msg.setSupportPlan(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.ApplicationStatus} */ (reader.readEnum());
                msg.setApplicationStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SupportPlanWithApplicationStatus.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SupportPlanWithApplicationStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSupportPlan();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.SupportPlan.serializeBinaryToWriter);
    }
    f = message.getApplicationStatus();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
};
/**
 * optional SupportPlan support_plan = 1;
 * @return {?proto.jp.singcolor.SupportPlan}
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.prototype.getSupportPlan = function () {
    return /** @type{?proto.jp.singcolor.SupportPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.SupportPlan, 1));
};
/**
 * @param {?proto.jp.singcolor.SupportPlan|undefined} value
 * @return {!proto.jp.singcolor.SupportPlanWithApplicationStatus} returns this
*/
proto.jp.singcolor.SupportPlanWithApplicationStatus.prototype.setSupportPlan = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SupportPlanWithApplicationStatus} returns this
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.prototype.clearSupportPlan = function () {
    return this.setSupportPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.prototype.hasSupportPlan = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ApplicationStatus application_status = 2;
 * @return {!proto.jp.singcolor.ApplicationStatus}
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.prototype.getApplicationStatus = function () {
    return /** @type {!proto.jp.singcolor.ApplicationStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.ApplicationStatus} value
 * @return {!proto.jp.singcolor.SupportPlanWithApplicationStatus} returns this
 */
proto.jp.singcolor.SupportPlanWithApplicationStatus.prototype.setApplicationStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RedashDashboard.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RedashDashboard.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RedashDashboard} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RedashDashboard.toObject = function (includeInstance, msg) {
        var f, obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            url: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RedashDashboard}
 */
proto.jp.singcolor.RedashDashboard.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RedashDashboard;
    return proto.jp.singcolor.RedashDashboard.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RedashDashboard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RedashDashboard}
 */
proto.jp.singcolor.RedashDashboard.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RedashDashboard.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RedashDashboard.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RedashDashboard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RedashDashboard.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string id = 1;
 * @return {string}
 */
proto.jp.singcolor.RedashDashboard.prototype.getId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RedashDashboard} returns this
 */
proto.jp.singcolor.RedashDashboard.prototype.setId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.RedashDashboard.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RedashDashboard} returns this
 */
proto.jp.singcolor.RedashDashboard.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string url = 3;
 * @return {string}
 */
proto.jp.singcolor.RedashDashboard.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RedashDashboard} returns this
 */
proto.jp.singcolor.RedashDashboard.prototype.setUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationRedashExportHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationRedashExportHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationRedashExportHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationRedashExportHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationRedashExportHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            queryType: jspb.Message.getFieldWithDefault(msg, 3, ""),
            generatedDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
            createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationRedashExportHistory}
 */
proto.jp.singcolor.OrganizationRedashExportHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationRedashExportHistory;
    return proto.jp.singcolor.OrganizationRedashExportHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationRedashExportHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationRedashExportHistory}
 */
proto.jp.singcolor.OrganizationRedashExportHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationRedashExportHistoryId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setQueryType(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setGeneratedDate(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationRedashExportHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationRedashExportHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationRedashExportHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationRedashExportHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getQueryType();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getGeneratedDate();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string organization_redash_export_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.getOrganizationRedashExportHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationRedashExportHistory} returns this
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.setOrganizationRedashExportHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationRedashExportHistory} returns this
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string query_type = 3;
 * @return {string}
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.getQueryType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationRedashExportHistory} returns this
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.setQueryType = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string generated_date = 4;
 * @return {string}
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.getGeneratedDate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationRedashExportHistory} returns this
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.setGeneratedDate = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationRedashExportHistory} returns this
 */
proto.jp.singcolor.OrganizationRedashExportHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationUpdateHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationUpdateHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationUpdateHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationUpdateHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationUpdateHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            userName: jspb.Message.getFieldWithDefault(msg, 4, ""),
            editedParam: jspb.Message.getFieldWithDefault(msg, 5, 0),
            before: jspb.Message.getFieldWithDefault(msg, 6, ""),
            after: jspb.Message.getFieldWithDefault(msg, 7, ""),
            createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory}
 */
proto.jp.singcolor.OrganizationUpdateHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationUpdateHistory;
    return proto.jp.singcolor.OrganizationUpdateHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationUpdateHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory}
 */
proto.jp.singcolor.OrganizationUpdateHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationUpdateHistoryId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserName(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.OrganizationEditableParam} */ (reader.readEnum());
                msg.setEditedParam(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setBefore(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setAfter(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationUpdateHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationUpdateHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationUpdateHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationUpdateHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getUserName();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getEditedParam();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getBefore();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getAfter();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
};
/**
 * optional string organization_update_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.getOrganizationUpdateHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.setOrganizationUpdateHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string user_name = 4;
 * @return {string}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.getUserName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.setUserName = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional OrganizationEditableParam edited_param = 5;
 * @return {!proto.jp.singcolor.OrganizationEditableParam}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.getEditedParam = function () {
    return /** @type {!proto.jp.singcolor.OrganizationEditableParam} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.OrganizationEditableParam} value
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.setEditedParam = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional string before = 6;
 * @return {string}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.getBefore = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.setBefore = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string after = 7;
 * @return {string}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.getAfter = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.setAfter = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationUpdateHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationStaffRoleUpdateHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            userName: jspb.Message.getFieldWithDefault(msg, 4, ""),
            targetUserId: jspb.Message.getFieldWithDefault(msg, 5, ""),
            targetUserName: jspb.Message.getFieldWithDefault(msg, 6, ""),
            before: jspb.Message.getFieldWithDefault(msg, 7, 0),
            after: jspb.Message.getFieldWithDefault(msg, 8, 0),
            createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationStaffRoleUpdateHistory;
    return proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationStaffRoleUpdateHistoryId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserName(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserName(value);
                break;
            case 7:
                var value = /** @type {!proto.jp.singcolor.OrganizationStaffRole} */ (reader.readEnum());
                msg.setBefore(value);
                break;
            case 8:
                var value = /** @type {!proto.jp.singcolor.OrganizationStaffRole} */ (reader.readEnum());
                msg.setAfter(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationStaffRoleUpdateHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getUserName();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getTargetUserName();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getBefore();
    if (f !== 0.0) {
        writer.writeEnum(7, f);
    }
    f = message.getAfter();
    if (f !== 0.0) {
        writer.writeEnum(8, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
};
/**
 * optional string organization_staff_role_update_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getOrganizationStaffRoleUpdateHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setOrganizationStaffRoleUpdateHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string user_name = 4;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getUserName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setUserName = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string target_user_id = 5;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string target_user_name = 6;
 * @return {string}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getTargetUserName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setTargetUserName = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional OrganizationStaffRole before = 7;
 * @return {!proto.jp.singcolor.OrganizationStaffRole}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getBefore = function () {
    return /** @type {!proto.jp.singcolor.OrganizationStaffRole} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {!proto.jp.singcolor.OrganizationStaffRole} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setBefore = function (value) {
    return jspb.Message.setProto3EnumField(this, 7, value);
};
/**
 * optional OrganizationStaffRole after = 8;
 * @return {!proto.jp.singcolor.OrganizationStaffRole}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getAfter = function () {
    return /** @type {!proto.jp.singcolor.OrganizationStaffRole} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {!proto.jp.singcolor.OrganizationStaffRole} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setAfter = function (value) {
    return jspb.Message.setProto3EnumField(this, 8, value);
};
/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationStaffRoleUpdateHistory} returns this
 */
proto.jp.singcolor.OrganizationStaffRoleUpdateHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationLiverLeaveHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationLiverLeaveHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationLiverLeaveHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverLeaveHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.Organization.toObject(includeInstance, f),
            supportPlan: (f = msg.getSupportPlan()) && proto.jp.singcolor.SupportPlan.toObject(includeInstance, f),
            liverUser: (f = msg.getLiverUser()) && users_entity_pb.User.toObject(includeInstance, f),
            leaveType: jspb.Message.getFieldWithDefault(msg, 5, 0),
            forcedRemovalByUser: (f = msg.getForcedRemovalByUser()) && users_entity_pb.User.toObject(includeInstance, f),
            joinedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
            leftAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationLiverLeaveHistory;
    return proto.jp.singcolor.OrganizationLiverLeaveHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationLiverLeaveHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverLeaveHistoryId(value);
                break;
            case 2:
                var value = new proto.jp.singcolor.Organization;
                reader.readMessage(value, proto.jp.singcolor.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            case 3:
                var value = new proto.jp.singcolor.SupportPlan;
                reader.readMessage(value, proto.jp.singcolor.SupportPlan.deserializeBinaryFromReader);
                msg.setSupportPlan(value);
                break;
            case 4:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setLiverUser(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.OrganizationLiverLeaveType} */ (reader.readEnum());
                msg.setLeaveType(value);
                break;
            case 6:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setForcedRemovalByUser(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setJoinedAt(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLeftAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationLiverLeaveHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationLiverLeaveHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverLeaveHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(2, f, proto.jp.singcolor.Organization.serializeBinaryToWriter);
    }
    f = message.getSupportPlan();
    if (f != null) {
        writer.writeMessage(3, f, proto.jp.singcolor.SupportPlan.serializeBinaryToWriter);
    }
    f = message.getLiverUser();
    if (f != null) {
        writer.writeMessage(4, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getLeaveType();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getForcedRemovalByUser();
    if (f != null) {
        writer.writeMessage(6, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getJoinedAt();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getLeftAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
};
/**
 * optional string organization_liver_leave_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.getOrganizationLiverLeaveHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.setOrganizationLiverLeaveHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional Organization organization = 2;
 * @return {?proto.jp.singcolor.Organization}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.Organization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.Organization, 2));
};
/**
 * @param {?proto.jp.singcolor.Organization|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
*/
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional SupportPlan support_plan = 3;
 * @return {?proto.jp.singcolor.SupportPlan}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.getSupportPlan = function () {
    return /** @type{?proto.jp.singcolor.SupportPlan} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.SupportPlan, 3));
};
/**
 * @param {?proto.jp.singcolor.SupportPlan|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
*/
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.setSupportPlan = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.clearSupportPlan = function () {
    return this.setSupportPlan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.hasSupportPlan = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional User liver_user = 4;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.getLiverUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 4));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
*/
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.setLiverUser = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.clearLiverUser = function () {
    return this.setLiverUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.hasLiverUser = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional OrganizationLiverLeaveType leave_type = 5;
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveType}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.getLeaveType = function () {
    return /** @type {!proto.jp.singcolor.OrganizationLiverLeaveType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.OrganizationLiverLeaveType} value
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.setLeaveType = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional User forced_removal_by_user = 6;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.getForcedRemovalByUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 6));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
*/
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.setForcedRemovalByUser = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.clearForcedRemovalByUser = function () {
    return this.setForcedRemovalByUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.hasForcedRemovalByUser = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional int64 joined_at = 7;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.getJoinedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.setJoinedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 left_at = 8;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.getLeftAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverLeaveHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverLeaveHistory.prototype.setLeftAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationLiverDetail.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationLiverDetail.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationLiverDetail} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationLiverDetail.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiver: (f = msg.getOrganizationLiver()) && proto.jp.singcolor.OrganizationLiver.toObject(includeInstance, f),
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationLiverDetail}
 */
proto.jp.singcolor.OrganizationLiverDetail.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationLiverDetail;
    return proto.jp.singcolor.OrganizationLiverDetail.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationLiverDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationLiverDetail}
 */
proto.jp.singcolor.OrganizationLiverDetail.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = new proto.jp.singcolor.OrganizationLiver;
                reader.readMessage(value, proto.jp.singcolor.OrganizationLiver.deserializeBinaryFromReader);
                msg.setOrganizationLiver(value);
                break;
            case 3:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationLiverDetail.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationLiverDetail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationLiverDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationLiverDetail.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiver();
    if (f != null) {
        writer.writeMessage(2, f, proto.jp.singcolor.OrganizationLiver.serializeBinaryToWriter);
    }
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
};
/**
 * optional OrganizationLiver organization_liver = 2;
 * @return {?proto.jp.singcolor.OrganizationLiver}
 */
proto.jp.singcolor.OrganizationLiverDetail.prototype.getOrganizationLiver = function () {
    return /** @type{?proto.jp.singcolor.OrganizationLiver} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.OrganizationLiver, 2));
};
/**
 * @param {?proto.jp.singcolor.OrganizationLiver|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverDetail} returns this
*/
proto.jp.singcolor.OrganizationLiverDetail.prototype.setOrganizationLiver = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverDetail} returns this
 */
proto.jp.singcolor.OrganizationLiverDetail.prototype.clearOrganizationLiver = function () {
    return this.setOrganizationLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverDetail.prototype.hasOrganizationLiver = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserItem user_item = 3;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.OrganizationLiverDetail.prototype.getUserItem = function () {
    return /** @type{?proto.jp.singcolor.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 3));
};
/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverDetail} returns this
*/
proto.jp.singcolor.OrganizationLiverDetail.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverDetail} returns this
 */
proto.jp.singcolor.OrganizationLiverDetail.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverDetail.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationLiverGroup.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationLiverGroup.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationLiverGroup} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationLiverGroup.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            name: jspb.Message.getFieldWithDefault(msg, 3, ""),
            createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationLiverGroup}
 */
proto.jp.singcolor.OrganizationLiverGroup.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationLiverGroup;
    return proto.jp.singcolor.OrganizationLiverGroup.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationLiverGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationLiverGroup}
 */
proto.jp.singcolor.OrganizationLiverGroup.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationLiverGroup.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationLiverGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationLiverGroup.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string organization_liver_group_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.getOrganizationLiverGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverGroup} returns this
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.setOrganizationLiverGroupId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverGroup} returns this
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string name = 3;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverGroup} returns this
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverGroup} returns this
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverGroup} returns this
 */
proto.jp.singcolor.OrganizationLiverGroup.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationLiverGroupLiver.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationLiverGroupLiver} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationLiverGroupLiver.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverGroupLiverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationLiverGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            organizationLiver: (f = msg.getOrganizationLiver()) && proto.jp.singcolor.OrganizationLiver.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationLiverGroupLiver}
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationLiverGroupLiver;
    return proto.jp.singcolor.OrganizationLiverGroupLiver.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationLiverGroupLiver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationLiverGroupLiver}
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverGroupId(value);
                break;
            case 3:
                var value = new proto.jp.singcolor.OrganizationLiver;
                reader.readMessage(value, proto.jp.singcolor.OrganizationLiver.deserializeBinaryFromReader);
                msg.setOrganizationLiver(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationLiverGroupLiver.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationLiverGroupLiver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverGroupLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationLiverGroupId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getOrganizationLiver();
    if (f != null) {
        writer.writeMessage(3, f, proto.jp.singcolor.OrganizationLiver.serializeBinaryToWriter);
    }
};
/**
 * optional string organization_liver_group_liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.getOrganizationLiverGroupLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverGroupLiver} returns this
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.setOrganizationLiverGroupLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_liver_group_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.getOrganizationLiverGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverGroupLiver} returns this
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.setOrganizationLiverGroupId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional OrganizationLiver organization_liver = 3;
 * @return {?proto.jp.singcolor.OrganizationLiver}
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.getOrganizationLiver = function () {
    return /** @type{?proto.jp.singcolor.OrganizationLiver} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.OrganizationLiver, 3));
};
/**
 * @param {?proto.jp.singcolor.OrganizationLiver|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverGroupLiver} returns this
*/
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.setOrganizationLiver = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverGroupLiver} returns this
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.clearOrganizationLiver = function () {
    return this.setOrganizationLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverGroupLiver.prototype.hasOrganizationLiver = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationMonthlyDiamondHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationMonthlyDiamondHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationMonthlyDiamondHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            year: jspb.Message.getFieldWithDefault(msg, 3, 0),
            month: jspb.Message.getFieldWithDefault(msg, 4, 0),
            cashPaymentSubtractedDiamondAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
            organizationRewardDiamondAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
            specialRewardDiamondAmount: jspb.Message.getFieldWithDefault(msg, 7, 0),
            createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationMonthlyDiamondHistory;
    return proto.jp.singcolor.OrganizationMonthlyDiamondHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationMonthlyDiamondHistoryId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCashPaymentSubtractedDiamondAmount(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOrganizationRewardDiamondAmount(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSpecialRewardDiamondAmount(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationMonthlyDiamondHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationMonthlyDiamondHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getCashPaymentSubtractedDiamondAmount();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getOrganizationRewardDiamondAmount();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getSpecialRewardDiamondAmount();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
};
/**
 * optional string organization_monthly_diamond_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getOrganizationMonthlyDiamondHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setOrganizationMonthlyDiamondHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 year = 3;
 * @return {number}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 month = 4;
 * @return {number}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 cash_payment_subtracted_diamond_amount = 5;
 * @return {number}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getCashPaymentSubtractedDiamondAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setCashPaymentSubtractedDiamondAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 organization_reward_diamond_amount = 6;
 * @return {number}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getOrganizationRewardDiamondAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setOrganizationRewardDiamondAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 special_reward_diamond_amount = 7;
 * @return {number}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getSpecialRewardDiamondAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setSpecialRewardDiamondAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationMonthlyDiamondHistory.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationLiverMonthlyDiamondHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            year: jspb.Message.getFieldWithDefault(msg, 4, 0),
            month: jspb.Message.getFieldWithDefault(msg, 5, 0),
            subtractedDiamondAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
            preJoinNormalDiamondAmount: jspb.Message.getFieldWithDefault(msg, 7, 0),
            preJoinUnexchangeableToCoinDiamondAmount: jspb.Message.getFieldWithDefault(msg, 8, 0),
            createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 10, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory;
    return proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationLiverMonthlyDiamondHistoryId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 3:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSubtractedDiamondAmount(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPreJoinNormalDiamondAmount(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPreJoinUnexchangeableToCoinDiamondAmount(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationLiverMonthlyDiamondHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getSubtractedDiamondAmount();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getPreJoinNormalDiamondAmount();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getPreJoinUnexchangeableToCoinDiamondAmount();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
};
/**
 * optional string organization_liver_monthly_diamond_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getOrganizationLiverMonthlyDiamondHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setOrganizationLiverMonthlyDiamondHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional User user = 3;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 3));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
*/
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.hasUser = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional int64 year = 4;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 month = 5;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 subtracted_diamond_amount = 6;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getSubtractedDiamondAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setSubtractedDiamondAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 pre_join_normal_diamond_amount = 7;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getPreJoinNormalDiamondAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setPreJoinNormalDiamondAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 pre_join_unexchangeable_to_coin_diamond_amount = 8;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getPreJoinUnexchangeableToCoinDiamondAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setPreJoinUnexchangeableToCoinDiamondAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional int64 updated_at = 10;
 * @return {number}
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory} returns this
 */
proto.jp.singcolor.OrganizationLiverMonthlyDiamondHistory.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OrganizationLiverLivePermission.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OrganizationLiverLivePermission.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OrganizationLiverLivePermission} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OrganizationLiverLivePermission.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            livePermission: (f = msg.getLivePermission()) && lives_entity_pb.LivePermission.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OrganizationLiverLivePermission}
 */
proto.jp.singcolor.OrganizationLiverLivePermission.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OrganizationLiverLivePermission;
    return proto.jp.singcolor.OrganizationLiverLivePermission.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OrganizationLiverLivePermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OrganizationLiverLivePermission}
 */
proto.jp.singcolor.OrganizationLiverLivePermission.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = new lives_entity_pb.LivePermission;
                reader.readMessage(value, lives_entity_pb.LivePermission.deserializeBinaryFromReader);
                msg.setLivePermission(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OrganizationLiverLivePermission.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OrganizationLiverLivePermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OrganizationLiverLivePermission.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getLivePermission();
    if (f != null) {
        writer.writeMessage(2, f, lives_entity_pb.LivePermission.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.getUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverLivePermission} returns this
*/
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverLivePermission} returns this
 */
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional LivePermission live_permission = 2;
 * @return {?proto.jp.singcolor.LivePermission}
 */
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.getLivePermission = function () {
    return /** @type{?proto.jp.singcolor.LivePermission} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LivePermission, 2));
};
/**
 * @param {?proto.jp.singcolor.LivePermission|undefined} value
 * @return {!proto.jp.singcolor.OrganizationLiverLivePermission} returns this
*/
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.setLivePermission = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OrganizationLiverLivePermission} returns this
 */
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.clearLivePermission = function () {
    return this.setLivePermission(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OrganizationLiverLivePermission.prototype.hasLivePermission = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * @enum {number}
 */
proto.jp.singcolor.SupportPlanStatus = {
    UNKNOWN_SUPPORT_PLAN_STATUS: 0,
    RECRUITING_APPLICATIONS: 1,
    SUSPENDING_APPLICATIONS: 2
};
/**
 * @enum {number}
 */
proto.jp.singcolor.SupportPlanCashOutMethod = {
    UNKNOWN_CASH_OUT_METHOD: 0,
    DIAMOND_EXCHANGE: 1,
    CASH_PAYMENT: 2
};
/**
 * @enum {number}
 */
proto.jp.singcolor.SupportPlanEditableParam = {
    SUPPORT_PLAN_EDITABLE_PARAM_UNKNOWN: 0,
    SUPPORT_PLAN_EDITABLE_PARAM_NAME: 1,
    SUPPORT_PLAN_EDITABLE_PARAM_DESCRIPTION: 2,
    SUPPORT_PLAN_EDITABLE_PARAM_STATUS: 3,
    SUPPORT_PLAN_EDITABLE_PARAM_CASHOUT_METHOD: 4,
    SUPPORT_PLAN_EDITABLE_PARAM_ORGANIZATION_COMMISSION_RATE: 5,
    SUPPORT_PLAN_EDITABLE_PARAM_TAG: 6
};
/**
 * @enum {number}
 */
proto.jp.singcolor.OrganizationStaffRole = {
    UNKNOWN_ORGANIZATION_STAFF_ROLE: 0,
    OWNER: 1,
    APPROVAL_STAFF: 2,
    NORMAL_STAFF: 3
};
/**
 * @enum {number}
 */
proto.jp.singcolor.ApplicationStatus = {
    APPLICATION_STATUS_UNKNOWN: 0,
    APPLICATION_STATUS_NOT_APPLIED: 1,
    APPLICATION_STATUS_APPLIED: 2,
    APPLICATION_STATUS_APPROVED: 3
};
/**
 * @enum {number}
 */
proto.jp.singcolor.OrganizationEditableParam = {
    ORGANIZATION_EDITABLE_PARAM_UNKNOWN: 0,
    ORGANIZATION_EDITABLE_PARAM_NAME: 1,
    ORGANIZATION_EDITABLE_PARAM_DESCRIPTION: 2,
    ORGANIZATION_EDITABLE_PARAM_CODE: 3,
    ORGANIZATION_EDITABLE_PARAM_THUMBNAIL_URL: 4,
    ORGANIZATION_EDITABLE_PARAM_TWITTER_SCREEN_NAME: 5,
    ORGANIZATION_EDITABLE_PARAM_WEBSITE_URL: 6,
    ORGANIZATION_EDITABLE_PARAM_CONTACT_URL: 7,
    ORGANIZATION_EDITABLE_PARAM_CONTACT_BUTTON_TITLE: 8,
    ORGANIZATION_EDITABLE_PARAM_HIDE_STAFF_LIST: 9
};
/**
 * @enum {number}
 */
proto.jp.singcolor.OrganizationLiverLeaveType = {
    ORGANIZATION_LIVER_LEAVE_TYPE_UNKNOWN: 0,
    ORGANIZATION_LIVER_LEAVE_TYPE_SELF_LEAVE: 1,
    ORGANIZATION_LIVER_LEAVE_TYPE_FORCED_REMOVAL: 2
};
goog.object.extend(exports, proto.jp.singcolor);
