var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { UsersV1Client } from '../singcolor-proto/ts/Users_v1ServiceClientPb';
import { GetMonthlyStatisticsV1Request, GetStatisticsCurrentDiamondV1Request, AppleLoginV1Request, GoogleLoginV1Request, LineLoginByCodeV1Request, GetLineLoginStateV1Request, GetUserV1Request, ListWebPageV1Request, GetUserProfileV2Request, GetCoinBalanceV1Request, GetWithdrawV1Request, GetUserBanV1Request, ListAvailableWebFeaturesV1Request } from '../singcolor-proto/ts/users_v1_pb';
import { GetLiverPromotionMeterV1Request } from '../singcolor-proto/ts/users_v1_pb';
import { MonthlyStatisticsPageKey } from '../singcolor-proto/ts/users_entity_pb';
var UsersGrpcClient = /** @class */ (function () {
    function UsersGrpcClient(metadataBuilder) {
        this.metadataBuilder = metadataBuilder;
        this.client = new UsersV1Client(process.env.NEXT_PUBLIC_API_URL, null, null);
    }
    UsersGrpcClient.prototype.callGrpc = function (invoke) {
        return __awaiter(this, void 0, void 0, function () {
            var metadata, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataBuilder()];
                    case 1:
                        metadata = _a.sent();
                        console.log({ metadata: metadata });
                        return [4 /*yield*/, invoke({ metadata: metadata })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.toObject()];
                }
            });
        });
    };
    UsersGrpcClient.prototype.appleLogin = function (_a) {
        var idToken = _a.idToken;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new AppleLoginV1Request();
                request.setIdToken(idToken);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.appleLogin(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.googleLogin = function (_a) {
        var idToken = _a.idToken;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GoogleLoginV1Request();
                request.setIdToken(idToken);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.googleLogin(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.lineLoginByCode = function (_a) {
        var code = _a.code, redirectUri = _a.redirectUri;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new LineLoginByCodeV1Request();
                request.setCode(code);
                if (redirectUri) {
                    request.setRedirectUri(redirectUri);
                }
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.lineLoginByCode(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getLineLoginState = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetLineLoginStateV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getLineLoginState(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getUser = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetUserV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUser(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getUserProfileV2 = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetUserProfileV2Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUserProfileV2(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getLiverPromotinMeter = function (_a) {
        var liverId = _a.liverId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetLiverPromotionMeterV1Request();
                request.setLiverId(liverId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getLiverPromotionMeter(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getStatisticsCurrentDiamond = function (_a) {
        var organizationLiverId = _a.organizationLiverId, organizationId = _a.organizationId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetStatisticsCurrentDiamondV1Request();
                request.setOrganizationLiverId(organizationLiverId);
                request.setOrganizationId(organizationId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getStatisticsCurrentDiamond(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getMonthlyStatistics = function (_a, _b) {
        var organizationLiverId = _a.organizationLiverId, organizationId = _a.organizationId;
        var year = _b.year, month = _b.month;
        return __awaiter(this, void 0, void 0, function () {
            var request, pageKey;
            var _this = this;
            return __generator(this, function (_c) {
                request = new GetMonthlyStatisticsV1Request();
                request.setOrganizationLiverId(organizationLiverId);
                request.setOrganizationId(organizationId);
                pageKey = new MonthlyStatisticsPageKey();
                pageKey.setYear(year);
                pageKey.setMonth(month);
                request.setPageKey(pageKey);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getMonthlyStatistics(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.listWebPage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListWebPageV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listWebPageV1(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getCoinBalance = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetCoinBalanceV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getCoinBalance(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getWithdraw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetWithdrawV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getWithdrawStatusV1(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.getUserBan = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetUserBanV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUserBanV1(request, metadata);
                    })];
            });
        });
    };
    UsersGrpcClient.prototype.listAvailableWebFeatures = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListAvailableWebFeaturesV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listAvailableWebFeatures(request, metadata);
                    })];
            });
        });
    };
    return UsersGrpcClient;
}());
export { UsersGrpcClient };
